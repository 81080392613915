<template>
  <div class="page-offshop__list">
    <h2>线下店列表</h2>
    <el-button @click="openAddEditDialog" type="primary">新建线下店</el-button>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
    <add-edit-dialog ref="addEditDialog" @refresh="loadTableData" />

    <el-dialog :title="curShopName" :visible.sync="showQr">
<!--      <vue-qr :text="shopUrl" :size="300"></vue-qr>-->
      <el-image :src="shopUrl"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { offshopListByPage } from "@/api/offshop";
import AddEditDialog from "../addEditDialog/addEditDialog";
//vue/no-unused-components
// import vueQr from "vue-qr";
const logo = require("../../../../assets/logo.png");

export default {
  name: "orgList",
  components: {
    AddEditDialog,
    // vueQr,
  },
  data() {
    return {
      showQr: false,
      curShopName: "",
      shopUrl: "https://prod-4gg97ttc904fef5b-1310787822.ap-shanghai.app.tcloudbase.com/offOrder/",
      imageUrl: logo,
      table: {
        data: [],
        cols: [
          {
            type: "expand",
            render: ({ row }) => {
              console.log(row);
              return (
                <el-form labelPosition="left" inline class="table-expand">
                  <el-form-item label="公司名称">
                    <span>{row.shop_name}</span>
                  </el-form-item>
                  <el-form-item label="纳税识别号">
                    <span>{row.tax_id}</span>
                  </el-form-item>
                  <el-form-item label="头像">
                    <span>
                      <img class="avatar" src={row.avatar} />
                    </span>
                  </el-form-item>
                  <el-form-item label="简介">
                    <span>{row.introduction}</span>
                  </el-form-item>
                  <el-form-item label="地址">
                    <span>{row.address}</span>
                  </el-form-item>
                  <el-form-item label="开户行">
                    <span>{row.bank}</span>
                  </el-form-item>
                  <el-form-item label="对公账号">
                    <span>{row.public_account}</span>
                  </el-form-item>
                  <el-form-item label="电话">
                    <span>{row.phone}</span>
                  </el-form-item>
                  <el-form-item label="联系人">
                    <span>{row.contact}</span>
                  </el-form-item>
                  <el-form-item label="联系人电话">
                    <span>{row.contact_phone}</span>
                  </el-form-item>
                  <el-form-item label="联系人邮箱">
                    <span>{row.contact_mail}</span>
                  </el-form-item>
                </el-form>
              );
            },
          },
          {
            label: "ID",
            prop: "id",
            width: 50,
          },
          {
            prop: "shop_name",
            label: "店铺名称",
          },
          {
            label: "联系人",
            prop: "contact",
          },
          {
            label: "联系人电话",
            prop: "contact_phone",
          },
          {
            label: "联系人邮件",
            prop: "contact_mail",
          },
          {
            label: "操作",
            width: 350,
            render: ({ row }) => {
              return (
                <div class="operation">
                  <el-button
                    type="primary"
                    size="small"
                    onClick={() => this.editOrg(row)}
                  >
                    编辑
                  </el-button>
                  <el-button
                    type="primary"
                    size="small"
                    onClick={() => this.showQrcode(row)}
                  >
                    查看二维码
                  </el-button>
                </div>
              );
            },
          },
        ],
        pagination: {
          total: 0,
          pageSize: 20,
          currentPage: 1,
          pageSizes: [20, 40, 60, 80],
          layout: "total, sizes, prev, pager, next, jumper",
          on: {
            "current-change": (currentPage) => {
              this.table.pagination.currentPage = currentPage;
              this.loadTableData();
            },
            "size-change": (size) => {
              this.table.pagination.pageSize = size;
              this.loadTableData();
            },
          },
        },
      },
    };
  },
  mounted() {
    this.loadTableData();
  },
  methods: {
    showQrcode(data) {
      this.shopUrl = "";
      this.curShopName = data.shop_name;
      // this.shopUrl = "https://prod-4gg97ttc904fef5b-1310787822.ap-shanghai.app.tcloudbase.com/offOrder/" + data.id;
      this.shopUrl = data.urlLink
      this.showQr = true;
    },
    async loadTableData() {
      const { currentPage: page, pageSize: size } = this.table.pagination;
      const res = await offshopListByPage({ page, size });
      console.log("res", res);
      this.table.data = res.data;
      this.table.pagination.total = res.count;
    },
    openAddEditDialog() {
      this.$refs.addEditDialog.open();
    },
    editOrg(row) {
      this.$refs.addEditDialog.open(row);
    },
  },
};
</script>

<style lang="scss">
.page-offshop__list {
  .demo-table-expand {
    display: flex;
  }
  .avatar {
    width: 50px;
    height: 50px;
  }
  .el-dialog__body {
    text-align: center;
  }
}
</style>
