import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import cart from './cart'
import user from './user'


Vue.use(Vuex)

const persistedPaths = ['cart', 'user']
const store = new Vuex.Store({
  plugins: [createPersistedState({ paths: persistedPaths })],
  modules: {
    cart,
    user
  }
})

export default store
