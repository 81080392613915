<template>
  <el-tabs v-model="activeTabIndex" type="border-card">
    <el-tab-pane
      :label="item.label"
      v-for="(item, index) in tabs"
      :key="index">
    </el-tab-pane>
    <router-view></router-view>
  </el-tabs>
</template>

<script>
  export default {
    name: 'index',
    data() {
      return {
        activeTabIndex: '0',
        tabs: [
          {
            label: '新建订单',
            routeName: 'jdOrderNewList',
            path: '/jd_order/index/new'
          },
          {
            label: '妥投订单',
            routeName: 'jdOrderDeliveredList',
            path: '/jd_order/index/delivered'
          },
          {
            label: '拒收订单',
            routeName: 'jdOrderRejectList',
            path: '/jd_order/index/reject'
          }
        ]
      }
    },
    watch: {
      activeTabIndex(nv, ov) {
        console.log('pppp', nv, ov)
        const activeTab = this.tabs[nv];
        this.$router.replace({
          name: activeTab.routeName
        })
      }
    },
    created() {
      this.activeTabIndex = this.tabs.findIndex(tab => tab.path === this.$route.path) + ''
    }
  }
</script>

<style scoped>

</style>
