<template>
  <div class="page-catalog__detail">
    <h2>{{ name }}（{{ catId }}）</h2>
    <OpTable :data="table.data" :cols="table.cols" />
  </div>
</template>

<script>
  import OpTable from '@/components/table'
  import { getCatalogList } from '@/api/catalog'
  import { syncCatSku } from '@/api/page_num'

  export default {
    name: 'goods',
    components: {
      OpTable
    },
    data() {
      return {
        catId: '',
        name: '',
        syncCatSkuLoading: {},
        table: {
          data: [],
          cols: [
            {
              prop: 'catId',
              label: '分类 ID'
            },
            {
              prop: 'name',
              label: '分类名称'
            },
            {
              prop: 'parentId',
              label: '父分类 ID'
            },
            {
              label: '分类等级',
              render: ({ row }) => {
                const map = {
                  [0]: '一级',
                  [1]: '二级',
                  [2]: '三级'
                }
                return (
                  <div>{map[row.catClass]}</div>
                )
              }
            },
            {
              label: '状态',
              render: ({ row }) => {
                return <div>{ row.state === 1 ? '已上线' : '已下线' }</div>
              }
            },
            {
              prop: 'sortOrder',
              label: '排列顺序'
            },
            {
              label: '操作',
              width: 250,
              render: ({ row }) => {
                return (
                  <div class="operation">
                    {
                      row.catClass === 2 && <el-button loading={this.syncCatSkuLoading[row.catId]} type="primary" size="small" onClick={() => this.syncCatSku(row)}>同步商品SKU</el-button>
                    }
                    <router-link to={{ name: 'catalogDetail', params: { catId: row.catId, name: row.name } }}>
                      <el-button type="primary" size="small">详情</el-button>
                    </router-link>
                  </div>
                )
              }
            }
          ]
        }
      }
    },
    watch: {
      '$route.params.catId'() {
        this.reload()
      }
    },
    created() {
      this.reload()
    },
    methods: {
      reload() {
        this.catId = this.$route.params.catId
        this.name = this.$route.params.name
        this.loadTableData()
      },
      async loadTableData() {
        const { data } = await getCatalogList({ parentId: this.catId })
        this.table.data = data
      },
      async syncCatSku({ catId }) {
        try {
          this.$set(this.syncCatSkuLoading, catId, true)
          const result = await syncCatSku({ catId })
          console.log('result', result)
          this.$message.success('同步成功')
        } catch (e) {
          this.$message.error('同步失败')
        } finally {
          this.$set(this.syncCatSkuLoading, catId, false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-catalog__detail {

  }
</style>
