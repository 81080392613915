<template>
  <div class="page-admin__role_list">
    <h2>后台角色列表</h2>
    <el-button type="primary" @click="add">新增角色</el-button>
    <el-table
            :data="roleListData"
            style="width: 100%">
      <el-table-column
              label="角色id"
              prop="id"
              width="100"
      >
      </el-table-column>
      <el-table-column
              label="角色名称"
              prop="role_name">
      </el-table-column>
      <el-table-column
              label="操作"
              prop="handle">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" @click="editMenu(row)">菜单</el-button>
          <el-button type="primary" size="small" @click="openPermissionDialog(row)">权限</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog
            title="提示"
            class="page-admin__role__add"
            :visible.sync="dialogVisible"
            width="30%">
      <el-form :model="formData" ref="form" label-width="80px">
        <el-form-item
                label="角色名称"
                prop="role_name"
                :rules="[{
                        required: true,
                        message: '不能为空'
                      }
                    ]"
        >
          <el-input v-model="formData.role_name"></el-input
          >
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <menu-tree ref="menuTreeDialog"/>
    <permission ref="Permission"/>
  </div>
</template>

<script>

  import {adminRoleList, addAdminRole} from '@/api/admin'
  import MenuTree from './menuTree/menuTree'
  import Permission from './permission/permission'

  export default {
    name: "role_list",
    components: {
      MenuTree,
      Permission
    },
    data() {
      return {
        dialogVisible: false,
        roleListData: [],
        formData: {
          role_name: ''
        },
      }
    },
    mounted() {
      this.adminRoleListApi();
    },
    methods: {
      async adminRoleListApi() {
        const res = await adminRoleList();
        this.roleListData = res;
        console.log(res);
      },
      editMenu(row) {
        this.$refs.menuTreeDialog.open(row.id)
      },
      openPermissionDialog(row) {
        this.$refs.Permission.open(row.id)
      },
      add() {
        this.dialogVisible = true;
      },
      save() {
        // console.log(this.formData)
        const {role_name} = this.formData;
        addAdminRole({role_name});
        this.dialogVisible = false;
      },
      close() {
        this.dialogVisible = false;
      }
    }
  }
</script>

<style scoped>

</style>
