// 消息类型分三大类，goods、order、other
const OrderType = {
  value: 'order',
  label: '订单'
}
const GoodsType = {
  value: 'goods',
  label: '商品'
}
const OtherType = {
  value: 'other',
  label: '其它'
}

export default {
  // 订单分拆
  ORDER_SPLIT: {
    value: 1,
    label: '订单分拆',
    type: OrderType
  },
  // 商品价格变更
  GOODS_PRICE_CHANGE: {
    value: 2,
    label: '商品价格变更',
    type: GoodsType
  },
  // 商品上下架变更
  GOODS_ON_STATE_CHANGE: {
    value: 4,
    label: '商品上下架变更',
    type: GoodsType
  },
  // 订单妥投
  ORDER_DELIVERED: {
    value: 5,
    label: '订单妥投',
    type: OrderType
  },
  // 商品池内商品添加、删除
  GOODS_ADD_OR_DELETE: {
    value: 6,
    label: '商品池内商品添加、删除',
    type: GoodsType
  },
  // 订单取消
  ORDER_CANCEL: {
    value: 10,
    label: '订单取消',
    type: OrderType
  },
  // 发票开票进度
  INVOICE_STATE: {
    value: 11,
    label: '发票开票进度',
    type: OtherType
  },
  // 配送单生成成功
  DELIVERED_ORDER_GENERATE_SUCCESS: {
    value: 12,
    label: '配送单生成成功',
    type: OrderType
  },
  // 换新订单生成成功
  NEW_ORDER_GENERATE_SUCCESS: {
    value: 13,
    label: '换新订单生成成功',
    type: OrderType
  },
  // 支付失败
  PAY_FAIL: {
    value: 14,
    label: '支付失败',
    type: OtherType
  },
  // 7天未支付取消/未确认取消
  SEVEN_NO_PAY_CANCEL_OR_NO_CONFIRM_CANCEL: {
    value: 15,
    label: '7天未支付取消/未确认取消',
    type: OtherType
  },
  // 商品介绍及规格参数变更
  GOODS_INTRO_OR_SKU_CHANGE: {
    value: 16,
    label: '商品介绍及规格参数变更',
    type: GoodsType
  },
  // 赠品促销变更
  GIFT_PROMOTE_CHANGE: {
    value: 17,
    label: '赠品促销变更',
    type: GoodsType
  },
  // 订单等待确认收货
  ORDER_WAIT_CONFIRM: {
    value: 18,
    label: '订单等待确认收货',
    type: OrderType
  },
  // 订单配送退货
  ORDER_DELIVERED_RETURN: {
    value: 23,
    label: '订单配送退货',
    type: OrderType
  },
  // 新订单
  NEW_ORDER: {
    value: 25,
    label: '新订单',
    type: OrderType
  },
  // 预定订单
  RESERVE_ORDER: {
    value: 26,
    label: '预定订单',
    type: OrderType
  },
  // 售后服务单进度
  AFTER_SALE_SERVICE_STATE: {
    value: 28,
    label: '售后服务单进度',
    type: OrderType
  },
  // 订单完成
  ORDER_FINISHED: {
    value: 31,
    label: '订单完成',
    type: OrderType
  },
  // 商品池添加、删除
  PAGE_NUM_ADD_OR_DELETE: {
    value: 48,
    label: '商品池添加、删除',
    type: GoodsType
  },
  // 折扣率变更
  DISCOUNT_RATE_CHANGE: {
    value: 49,
    label: '折扣率变更',
    type: GoodsType
  },
  // 京东地址变更
  ADDRESS_CHANGE: {
    value: 50,
    label: '京东地址变更',
    type: OtherType
  },
  // 商品税率变更
  GOODS_TAX_CHANGE: {
    value: 100,
    label: '商品税率变更',
    type: GoodsType
  },
  // 专票资质审核进度
  TICKET_QUALIFICATION_REVIEW_STATE: {
    value: 102,
    label: '专票资质审核进度',
    type: OtherType
  },
}
