import axios from './base'

export const getPageNum = ({ page, size, pageNum } = {}) => {
  return axios.get('page_num/pageNum', {
    params: {
      page, size, pageNum
    }
  })
}

export const getJdPageNum = ({ page, size, pageNum } = {}) => {
  return axios.get('page_num/jdPageNum', {
    params: {
      page, size, pageNum
    }
  })
}

export const downloadJdPageNum = () => {
  return axios.post('page_num/downloadJdPageNum', {}, {
    responseType: 'blob'
  })
}

export const asyncPageNum = ({ name, pageNum }) => {
  return axios.post('page_num/asyncPageNum', {
    name,
    pageNum
  })
}

export const getSkuList = ({ pageNum, sku, pageNo, page, size } = {}) => {
  return axios.get('page_num/skuList', {
    params: {
      pageNum, sku, pageNo, page, size
    }
  })
}

export const syncSku = ({ sku }) => {
  return axios.post('page_num/syncSku', {
    sku
  })
}

export const syncCatSku = ({ catId }) => {
  return axios.get('page_num/syncCatSku', {
    params: { catId }
  })
}
