<template>
    <div class="page-order__list">
        <h2>线下店订单列表</h2>
        <el-form :inline="true" :model="formData" class="demo-form-inline" label-width="100px">

            <template v-if="adminRoleId !== 4">
                <el-form-item label="线下店铺">
                    <el-select v-model="formData.offShopId" placeholder="请选择" @change="loadTableData" clearable>
                        <el-option
                                v-for="(item, index) in offShopList"
                                :key="index"
                                :label="item.shop_name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--  <el-form-item label="京东订单号">
                    <el-input v-model="formData.jdOrderId" placeholder="请输入" clearable />
                  </el-form-item>-->
                <el-form-item label="公司">
                    <el-select v-model="formData.userCompany" placeholder="请选择" @change="loadTableData" clearable>
                        <el-option
                                v-for="(item, index) in companyList"
                                :key="index"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </template>

            <el-form-item label="第三方订单号">
                <el-input v-model="formData.orderSn" placeholder="请输入" clearable />
            </el-form-item>
            <!-- <el-form-item label="订单状态">
               <el-select v-model="formData.status" placeholder="请选择" @change="loadTableData" clearable>
                 <el-option
                   v-for="(item, index) in statusOptions"
                   :key="index"
                   :label="item.label"
                   :value="item.value">
                 </el-option>
               </el-select>
             </el-form-item>-->
            <!--      <el-form-item label="用户 ID">-->
            <!--        <el-input v-model="formData.userId" placeholder="用户 ID" clearable />-->
            <!--      </el-form-item>-->
            <el-form-item label="用户手机">
                <el-input v-model="formData.userMobile" placeholder="下单用户手机" clearable />
            </el-form-item>
            <!-- <el-form-item label="收货人手机">
               <el-input v-model="formData.mobile" placeholder="收货人手机" clearable />
             </el-form-item>-->

            <el-form-item label="查询日期" style="width: 500px;">
                <el-date-picker
                        style="width: 380px;"
                        v-model="formData.date"
                        type="daterange"
                        :clearable="true"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
                <el-button type="danger" @click="downloadOffOrderList" :loading="isDownloading">下载</el-button>
            </el-form-item>
        </el-form>
        <op-table
                size="small"
                :data="table.data"
                :cols="table.cols"
                :pagination="table.pagination"
        />
        <goods-list-dialog ref="GoodsListDialog"></goods-list-dialog>
        <jd-order-dialog ref="JdOrderDialog"></jd-order-dialog>
        <express-dialog ref="expressDialog" @refresh="loadTableData"></express-dialog>
    </div>
</template>

<script>
    import dayjs from 'dayjs'
    import GoodsListDialog from '../goods/goods'
    import expressDialog from '../express/express'
    import JdOrderDialog from '@/components/jd_order_dialog/jd_order_dialog'
    import { getOffOrderList, downloadOffOrderList } from '@/api/order'
    import { offshopList } from '@/api/offshop'
    import { getCompanyList } from '@/api/company'
    import OrderStatusEnum from '@/enums/order_status';
    import store from '@/store'

    export default {
        name: 'list',
        components: {
            GoodsListDialog,
            JdOrderDialog,
            expressDialog
        },
        data() {
            return {
                isDownloading: false,
                formData: {
                    //jdOrderId: '',
                    orderSn: '',
                    status: '',
                    userId: '',
                    mobile: '',
                    userMobile: '',
                    userCompany: '',
                    offShopId: '',
                    date: []
                },
                offShopList:[],
                companyList:[],
                adminRoleId: store.state.user.userInfo.admin_role_id,
                statusOptions: OrderStatusEnum.getList(),
                table: {
                    data: [],
                    cols: [
                        {
                            label: '第三方订单号',
                            render: ({ row }) => {
                                return (
                                    <div>
                                    <div>{ row.order_sn }</div>
                                { row.account_code && <div>成本中心：{row.account_code}</div>}
                                </div>
                                )
                            }
                        },
                        {
                            label: '店铺',
                            render: ({ row }) => {
                                return (
                                    <div>{ row.shop_name }</div>
                            )
                            }
                        },
                        /*{
                          label: '供应商',
                          // width: 100,
                          prop: 'org_name',
                          render: ({ row }) => {
                            return (
                              <div>
                                { row.org_name }
                                {
                                  row.jdOrderId > 0 &&
                                  <div>
                                    <a class="jd-order-id" onClick={() => this.openJdOrderDialog(row.jdOrderId)}>{row.jdOrderId}</a>
                                    <el-tooltip className="item" effect="dark" placement="top-start">
                                      <div slot="content">
                                        <div>
                                          京东订单金额：{this.$options.filters.currency(row.jdOrderPrice)}
                                        </div>
                                      </div>
                                      <i style="color: red;" class="el-icon-warning"/>
                                    </el-tooltip>
                                  </div>
                                }
                              </div>
                            )
                          }
                        },*/
                        {
                            label: '下单用户',
                            render: ({ row }) => {
                                return (
                                    <div>
                                    <div>{ row.username }</div>
                                <div>{ row.user_mobile }</div>
                                <div>{row.company_name}</div>
                                </div>
                            )
                            }
                        },
                        /*{
                          label: '收货人',
                          render: ({ row }) => {
                            return (
                              <div>
                                { row.consignee }
                                <div>{row.mobile}</div>
                              </div>
                            )
                          }
                        },*/
                        {
                            label: '地址',
                            width: 150,
                            prop: 'shop_addr'
                        },
                        {
                            label: '实付金额',
                            width: 90,
                            render: ({ row }) => {
                                return (
                                    <el-tooltip className="item" effect="dark" placement="top-start">
                                    <div slot="content">
                                    <div>
                                    订单金额：{ this.$options.filters.currency(row.order_price) }
                            </div>
                                <div>
                                商品金额：{ this.$options.filters.currency(row.goods_price) }
                            </div>
                                <div>
                                运费：{ this.$options.filters.currency(row.freight_price) }
                            </div>
                                <div>
                                提货券金额：{ this.$options.filters.currency(row.pickup_price) }
                            </div>
                                </div>
                                <div style="color: red; font-weight: bold;">
                                    { this.$options.filters.currency(row.actual_price) } <i class="el-icon-warning" />
                                    </div>
                                    </el-tooltip>
                            )
                            }
                        },
                        {
                            label: '订单状态',
                            width: 70,
                            prop: 'order_status_text'
                        },
                        {
                            label: '创建时间',
                            prop: 'create_time'
                        }/*,
            {
              label: '操作',
              // width: 160,
              render: ({ row }) => {
                return (
                  <div class="operation">
                    <el-button size="small" type="primary" onClick={() => this.openGoodsListDialog(row.id)}>商品</el-button>
                    <el-button size="small" type="primary" onClick={() => this.openExpressDialog({expressId:row.express_id, expressType:row.express_type, orderId: row.id})}>物流</el-button>
                </div>
                )
              }
            }*/
                    ],
                    pagination: {
                        total: 0,
                        pageSize: 20,
                        currentPage: 1,
                        pageSizes: [20, 40, 60, 80],
                        layout: 'total, sizes, prev, pager, next, jumper',
                        on: {
                            'current-change': (currentPage) => {
                                this.table.pagination.currentPage = currentPage
                                this.loadTableData()
                            },
                            'size-change': (size) => {
                                this.table.pagination.pageSize = size
                                this.loadTableData()
                            }
                        }
                    }
                }
            }
        },
        created() {
            /* if(this.adminRoleId === 4) {
                 this.formData.orgId = store.state.user.userInfo.info_id;
             }*/
            this.search();
            this.orgListApi();
            this.getCompanyList();
        },
        methods: {
            search() {
                this.table.pagination.currentPage = 1
                this.loadTableData()
            },
            async loadTableData() {
                const { currentPage: page, pageSize: size } = this.table.pagination
                const {
                    // jdOrderId,
                    orderSn,
                    status,
                    userId,
                    mobile,
                    userMobile,
                    userCompany,
                    // orgId,
                    offShopId,
                    date
                } = this.formData
                const { data, count } = await getOffOrderList({
                    page,
                    size,
                    // jdOrderId,
                    orderSn,
                    status,
                    userId,
                    mobile,
                    userMobile,
                    userCompany,
                    // orgId,
                    offShopId,
                    startDate: date && date[0] ? dayjs(date[0]).format('YYYY-MM-DD') : '',
                    endDate: date && date[1] ? dayjs(date[1]).add(1, 'day').format('YYYY-MM-DD') : ''
                })
                this.table.data = data
                this.table.pagination.total = count
            },
            async orgListApi(){
                this.offShopList = await offshopList();
            },
            async getCompanyList() {
                const { data } = await getCompanyList({ page: 1, size: 5000 });
                this.companyList = data;
            },
            openGoodsListDialog(orderId) {
                this.$refs.GoodsListDialog.open({ orderId })
            },
            openJdOrderDialog(jdOrderId) {
                this.$refs.JdOrderDialog.open(jdOrderId)
            },
            async downloadOffOrderList() {
                const {
                    // jdOrderId,
                    orderSn,
                    status,
                    userId,
                    mobile,
                    userMobile,
                    userCompany,
                    // orgId,
                    offShopId,
                    date
                } = this.formData
                if (!date.length) {
                    return this.$message.error('请选择日期')
                }
                if (dayjs(date[0]).add(30, 'day').isBefore(date[1])) {
                    return this.$message.error('日期间隔需小于等于31天')
                }
                this.isDownloading = true
                const data = await downloadOffOrderList({
                    // jdOrderId,
                    orderSn,
                    status,
                    userId,
                    mobile,
                    userMobile,
                    userCompany,
                    // orgId,
                    offShopId,
                    startDate: date && date[0] ? dayjs(date[0]).format('YYYY-MM-DD') : '',
                    endDate: date && date[1] ? dayjs(date[1]).add(1, 'day').format('YYYY-MM-DD') : ''
                })
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', `线下店订单数据-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
                document.body.appendChild(link)
                link.click()
                this.isDownloading = false
            },
            openExpressDialog({expressId, expressType, orderId}) {
                console.log('expressId',expressId)
                this.$refs.expressDialog.open({expressId, expressType, orderId})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .page-order__list {
        .el-form-item {
            width: 25%;
            margin-right: 0;
        }
        .jd-order-id {
            text-decoration: underline;
            color: #00a0e9;
            cursor: pointer;
        }
    }
</style>
