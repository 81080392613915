<template>
  <el-dialog
    :title="`${text}账号`"
    :visible.sync="dialogVisible"
    width="50%">
    <div class="page-company-user__add">
      <el-form :model="formData" ref="form" label-width="100px">
        <el-form-item label="角色" prop="role"
          :rules="[{
            required: true,
            message: '不能为空'
          }]">
          <el-select v-model="formData.role" placeholder="请选择">
            <el-option
              v-for="(item, index) in [
              {
                label: '二级账号',
                value: 'normal'
              },
              {
                label: '一级账号',
                value: 'admin'
              }
            ]"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="公司"
          prop="company_id"
          :rules="[{
            required: true,
            message: '不能为空'
          }]">
          <el-select v-model="formData.company_id" placeholder="请选择">
            <el-option
                    v-for="(item, index) in companyList"
                    :key="index"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="账号"
          prop="username"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input v-model="formData.username" :disabled="isEditing"></el-input
          ></el-form-item>

        <el-form-item
          label="昵称"
          prop="nickname"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input v-model="formData.nickname"></el-input
          ></el-form-item>

        <template v-if="!isEditing">
          <el-form-item
            label="密码"
            prop="password"
            :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
          >
            <el-input type="password" v-model="formData.password"></el-input
            ></el-form-item>

          <el-form-item
            label="密码确认"
            prop="confirm_password"
            :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
          >
            <el-input type="password" v-model="formData.confirm_password"></el-input
            ></el-form-item>
        </template>

        <el-form-item
          label="手机号"
          prop="mobile"
          :rules="[
          {
            required: true,
            message: '不能为空'
          },
          {
            min: 11,
            max: 11,
            message: '不是有效的电话号码'
          }
        ]"
        >
          <el-input v-model="formData.mobile"></el-input
          ></el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { storeUserInfo } from '@/api/user'

export default {
  name: 'upsert',

  data() {
    return {
      isEditing: false,
      dialogVisible: false,
      formData: {
        company_id: '',
        role: 'normal',
        username: '',
        password: '',
        confirm_password: '',
        mobile: '',
        nickname: ''
      },
      companyList: [],
    };
  },
  computed: {
    text() {
      return this.isEditing ? '修改' : '新建'
    }
  },
  methods: {
    open(userInfo, companyList) {
      this.dialogVisible = true
      this.isEditing = !!userInfo.id
      this.companyList = companyList
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      if (!this.isEditing) {
        this.formData = {
          company_id: '',
          role: 'normal',
          username: '',
          password: '',
          confirm_password: '',
          mobile: '',
          nickname: ''
        }
        return
      }
      this.formData = userInfo
    },
    close() {
      this.dialogVisible = false
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const {
        id,
        role,
        username,
        company_id,
        password,
        confirm_password,
        mobile,
        nickname
      } = this.formData;
      if (!this.isEditing && password !== confirm_password) {
        this.$message.error('两次密码不一致');
        return;
      }
      try {
        await storeUserInfo({
          id,
          company_id,
          role,
          username,
          password,
          mobile,
          nickname
        });
        this.$message.success(`${this.text}成功`);
        this.dialogVisible = false
        this.$emit('refresh');
      } catch (e) {
        this.$message.error(`${this.text}失败：${e}`);
      }
    },
    cancel() {
      this.dialogVisible = false
    }
  }
};
</script>

<style lang="scss">
.page-company-user__add {
  .el-form {
    max-width: 660px;
    .el-select .el-input {
      max-width: 360px;
    }
    .line {
      text-align: center;
    }
    .el-radio {
      margin-bottom: 20px;
    }
  }
}
</style>
