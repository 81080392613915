<template>
    <el-dialog
            :title="`设置公司分类`"
            :visible.sync="dialogVisible"
            @close="close"
            class="page-company__category-tree"
            width="50%">
        <el-tree
                ref="categoryTree"
                :data="tree.data"
                show-checkbox
                node-key="id"
                :default-expanded-keys="tree.defaultExpandedKeys"
                :default-checked-keys="tree.defaultCheckedKeys"
                :props="tree.defaultProps"
                :check-strictly="false">
        </el-tree>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">保存</el-button>
            <el-button type="primary" @click="saveall">同步至其他同类公司</el-button>
          </span>
    </el-dialog>
</template>

<script>
    import { allCatalogTree } from '@/api/catalog'
    import { submitCompanyCategory, getCompanyCategoryChecked,submitallCompanyCategory } from '@/api/company'

    export default {
        name: 'list',
        data() {
            return {
                dialogVisible: false,
                companyId: null,
                tree: {
                    data: [],
                    defaultExpandedKeys: [],
                    defaultCheckedKeys: [],
                    defaultProps: {
                        children: 'children',
                        label: 'name'
                    }
                }
            };
        },
        computed: {},
        watch: {},
        methods: {
            async open(id) {
                this.companyId = id
                this.dialogVisible = true
                this.tree.data = await allCatalogTree();
                const checkecd = await getCompanyCategoryChecked({ companyId: this.companyId })
                this.tree.defaultCheckedKeys = checkecd
            },
            close() {
                this.dialogVisible = false
                this.clean()
            },
            clean() {
                this.tree.data = []
                this.tree.defaultExpandedKeys = []
                this.tree.defaultCheckedKeys = []
                this.companyId = null
            },
            async save() {
                const categoryIds = this.$refs.categoryTree.getCheckedKeys().concat(this.$refs.categoryTree.getHalfCheckedKeys())
                try {
                    await submitCompanyCategory({ companyId: this.companyId, categoryIds })
                    this.$message({ message: '设置成功', type: 'success' });
                } catch (e) {
                    console.log(e)
                    this.$message({ message: '设置失败', type: 'error' });
                }
                this.close()
            },
            async saveall() {
                const categoryIds = this.$refs.categoryTree.getCheckedKeys().concat(this.$refs.categoryTree.getHalfCheckedKeys())
                try {
                    await submitallCompanyCategory({ companyId: this.companyId, categoryIds })
                    this.$message({ message: '设置成功', type: 'success' });
                } catch (e) {
                    console.log(e)
                    this.$message({ message: '设置失败', type: 'error' });
                }
                this.close()
            },
        },
    }
</script>

<style lang="scss">
    .page-company__category-tree {

    }
</style>
