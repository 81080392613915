import { BaseEnum } from '../base';
const ExpressTypeEnum = new BaseEnum({
  SF: {
    value: 'SF',
    label: '顺丰速运'
  },
  HTKY: {
    value: 'HTKY',
    label: '百世快递'
  },
  ZTO: {
    value: 'ZTO',
    label: '中通快递'
  },
  STO: {
    value: 'STO',
    label: '申通快递'
  },
  YTO: {
    value: 'YTO',
    label: '圆通快递'
  },
  YD: {
    value: 'YD',
    label: '韵达速递'
  },
  YZPY: {
    value: 'YZPY',
    label: '邮政快递包裹'
  },
  EMS: {
    value: 'EMS',
    label: 'EMS'
  },
  JD: {
    value: 'JD',
    label: '京东快递'
  }
});

export default ExpressTypeEnum;
