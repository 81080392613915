<template>
  <div class="page-account">
    <h2>账号名: {{accountData.pin}}</h2>
    <h2>余额: {{accountData.remainLimit}} 元</h2>

    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination" />
  </div>
</template>

<script>
  import { getAccountBalance, getAccountDetailList } from '@/api/account'

  export default {
    name: 'account',
    data() {
      return {
        accountData: {},
        table: {
          data: [],
          cols: [
            {
              prop: 'id',
              label: '明细 ID',
              width: 100
            },
            {
              prop: 'orderId',
              label: '订单号',
              width: 100
            },
            {
              // 业务号，一般由余额系统，在每一次操作成功后自动生成，也可以由前端业务系统传入
              prop: 'tradeNo',
              label: '业务号'
            },
            {
              // 账户类型  1：可用余额 2：锁定余额
              label: '账户类型',
              width: 80,
              render: ({ row }) => {
                const map = {
                  1: '可用余额',
                  2: '锁定余额'
                }
                return (
                  <div>{map[row.accountType]}</div>
                )
              }
            },
            {
              label: '业务类型',
              width: 100,
              render: ({ row }) => {
                return (
                  <div>{`${row.tradeTypeName} (${row.tradeType})`}</div>
                )
              }
            },
            {
              label: '金额',
              width: 80,
              render: ({ row }) => {
                return (
                  <div style="color: red; font-weight: bold;">
                    {row.amount && this.$options.filters.currency(row.amount)}
                  </div>
                )
              }
            },
            {
              prop: 'pin',
              label: 'pin'
            },
            {
              prop: 'createdDate',
              width: 100,
              label: '余额变动日期'
            },
            {
              prop: 'notePub',
              width: 200,
              label: '备注信息'
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    async created() {
      const { balance } = await getAccountBalance({ type: 1 })
      this.accountData = balance
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { data, total } = await getAccountDetailList({ page, size })
        this.table.data = data
        this.table.pagination.total = total
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-account {

  }
</style>
