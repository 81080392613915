<template>
  <div class="page-bill__list">
    <h2>账单列表</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline" label-width="100px">
      <el-form-item label="第三方订单号">
        <el-input v-model="formData.orderSn" placeholder="请输入" clearable/>
      </el-form-item>
      <el-form-item label="支付状态">
        <el-select v-model="formData.payStatus" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
            v-for="(item, index) in payStatusOptions"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户 ID">
        <el-input v-model="formData.userId" placeholder="用户 ID" clearable/>
      </el-form-item>
      <el-form-item v-if="adminRoleId !== 4" label="供应商 ID">
        <el-select v-model="formData.orgId" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
            v-for="(item, index) in orgList"
            :key="index"
            :label="item.org_name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间" style="width: 50%">
        <el-date-picker
          v-model="formData.createdDate"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="loadTableData"
          clearable
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="支付时间" style="width: 50%">
        <el-date-picker
          v-model="formData.payTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="loadTableData"
          clearable
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
        <el-button type="danger" @click="expertTable">下载</el-button>
      </el-form-item>
    </el-form>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
      @sort-change="tableSortChange"
    />
    <order-dialog ref="OrderDialog"></order-dialog>
  </div>
</template>

<script>
  import PayStatusEnum from '@/enums/pay_status';
  import { getBillList, downloadBill } from '@/api/bill'
  import { orgList } from '@/api/org'
  import dayjs from 'dayjs'
  import store from '@/store'
  import OrderDialog from '../order/order'

  export default {
    name: 'billList',
    components: {
      OrderDialog
    },
    data() {
      return {
        adminRoleId: store.state.user.userInfo.admin_role_id,
        formData: {
          orderSn: '',
          payStatus: '',
          userId: '',
          createdDate: [],
          payTime: [],
          sortProp: 'id',
          sortType: 'DESC'
        },
        orgList: [],
        payStatusOptions: PayStatusEnum.getList(),
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        table: {
          data: [],
          cols: [
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              label: '第三方订单号',
              width: 180,
              render: ({ row }) => {
                return (
                  <div class="clicked" onClick={() => this.openOrderDialog(row)}>{row.order_sn}</div>
                )
              }
            },
            {
              label: '供应商',
              // prop: 'org_name',
              // width: 150,
              render: ({ row }) => {
                return (
                  <div>
                    { row.org_name }
                    {
                      row.jdOrderId > 0 &&
                      <div>
                        <span>{row.jdOrderId}</span>
                        <el-tooltip className="item" effect="dark" placement="top-start">
                          <div slot="content">
                            <div>
                              京东订单金额：{this.$options.filters.currency(row.jdOrderPrice)}
                            </div>
                          </div>
                          <i style="color: red;" class="el-icon-warning"/>
                        </el-tooltip>
                      </div>
                    }
                  </div>
                )
              }
            },
            {
              label: '支付方式',
              prop: 'pay_method_text',
              width: 100
            },
            {
              label: '账单金额（元)',
              render: ({row}) => {
                return (
                  <div> {this.$options.filters.currency(row.pay_amount)} </div>
                )
              }
            },
            {
              label: '操作用户',
              width: 200,
              render: ({ row }) => {
                return (
                  <div>
                    <div>{ row.user_id }</div>
                    <div>{ row.username }</div>
                    <div>{ row.company_name }</div>
                  </div>
                )
              }
            },
            {
              label: '支付状态',
              prop: 'pay_status_text',
            },
            {
              label: '支付时间',
              prop: 'pay_time'
            },
            {
              label: '创建时间',
              prop: 'create_time'
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        },
      }
    },
    created() {
      this.loadTableData()
      this.orgListApi();

    },
    methods: {
      async loadTableData() {
        const {currentPage: page, pageSize: size} = this.table.pagination
        const {
          orderSn,
          payStatus,
          userId,
          orgId,
          createdDate,
          sortProp,
          sortType,
          payTime
        } = this.formData
        const {data, count} = await getBillList({
          page, size, orderSn, payStatus, userId, orgId, sortProp, sortType,
          startCreatedDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD 00:00:00'),
          endCreatedDate: createdDate && createdDate[1] && dayjs(createdDate[1]).format('YYYY-MM-DD 00:00:00'),
          startPayTime: payTime && payTime[0] && dayjs(payTime[0]).format('YYYY-MM-DD 00:00:00'),
          endPayTime: payTime && payTime[1] && dayjs(payTime[1]).format('YYYY-MM-DD 00:00:00'),
        })
        this.table.data = data
        this.table.pagination.total = count
      },
      async expertTable(){
        const {
          orderSn,
          payStatus,
          userId,
          orgId,
          createdDate,
          sortProp,
          sortType,
          payTime
        } = this.formData
        const data = await downloadBill({
          orderSn, payStatus, userId, orgId, sortProp, sortType,
          startCreatedDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD 00:00:00'),
          endCreatedDate: createdDate && createdDate[1] && dayjs(createdDate[1]).format('YYYY-MM-DD 00:00:00'),
          startPayTime: payTime && payTime[0] && dayjs(payTime[0]).format('YYYY-MM-DD 00:00:00'),
          endPayTime: payTime && payTime[1] && dayjs(payTime[1]).format('YYYY-MM-DD 00:00:00'),
        })
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `账单数据-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      },
      openOrderDialog({ order_sn: orderSn }) {
        this.$refs.OrderDialog.open({ orderSn })
      },
      async orgListApi() {
        this.orgList = await orgList();
      },
      tableSortChange({ prop, order}) {
        this.formData.sortProp = prop;
        this.formData.sortType = order === 'descending' ? 'desc' : 'asc';
        this.loadTableData();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-bill__list {
    .el-form-item {
      width: 25%;
      margin-right: 0
    }

    .el-form-item__content {
      padding: 0
    }
  }
</style>
