<template>
  <el-dialog
          width="80%"
          title="订单详情"
          :visible.sync="dialogVisible" >
    <op-table
            size="small"
            :default-expand-all="true"
            :data="table.data"
            :cols="table.cols" />
  </el-dialog>
</template>

<script>
  import { getOrderList } from '@/api/order'

  export default {
    name: 'order',
    data() {
      return {
        dialogVisible: false,
        expandTableCols: [
          {
            label: '商品 sku',
            prop: 'product_id'
          },
          {
            label: '商品名称',
            prop: 'goods_name'
          },
          {
            label: '商品图片',
            width: 120,
            render: ({ row }) => {
              return (
                <el-image style="width: 100px;"
                          src={row.list_pic_url} />
              );
            }
          },
          {
            label: '数量',
            prop: 'number'
          },
          {
            label: '售卖价 (元)',
            width: 80,
            render: ({ row }) => {
              return (
                <div style="color: blue; font-weight: bold;">
                  {row.retail_price && this.$options.filters.currency(row.retail_price)}
                </div>
              )
            }
          },
          {
            label: '京东价 (元)',
            width: 80,
            render: ({ row }) => {
              return (
                <div>
                  {row.market_price && this.$options.filters.currency(row.market_price)}
                </div>
              )
            }
          },
        ],
        table: {
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({ row }) => {
                return (
                  <op-table
                    border
                    style="margin-top: 20px;"
                    size="small"
                    data={row.goodsList}
                    cols={this.expandTableCols} />
                )
              }
            },
            {
              label: '第三方订单号',
              render: ({ row }) => {
                return (
                  <div>
                    <div>{ row.order_sn }</div>
                    { row.account_code && <div>成本中心：{row.account_code}</div>}
                  </div>
                )
              }
            },
            {
              label: '供应商',
              // width: 100,
              prop: 'org_name',
              render: ({ row }) => {
                return (
                  <div>
                    { row.org_name }
                    {
                      row.jdOrderId > 0 &&
                      <div>
                        { row.jdOrderId }
                        {
                          // <a className="jd-order-id"
                          //    onClick={() => this.openJdOrderDialog(row.jdOrderId)}>{row.jdOrderId}</a>
                          // <el-tooltip className="item" effect="dark" placement="top-start">
                          //   <div slot="content">
                          //     <div>
                          //       京东订单金额：{this.$options.filters.currency(row.jdOrderPrice)}
                          //     </div>
                          //   </div>
                          //   <i style="color: red;" className="el-icon-warning"/>
                          // </el-tooltip>
                        }
                      </div>
                    }
                  </div>
                )
              }
            },
            {
              label: '下单用户',
              render: ({ row }) => {
                return (
                  <div>
                    <div>{ row.username }</div>
                    <div>{ row.user_mobile }</div>
                    <div>{row.company_name}</div>
                  </div>
                )
              }
            },
            {
              label: '收货人',
              render: ({ row }) => {
                return (
                  <div>
                    { row.consignee }
                    <div>{row.mobile}</div>
                  </div>
                )
              }
            },
            {
              label: '地址',
              width: 150,
              prop: 'address'
            },
            {
              label: '实付金额',
              width: 90,
              render: ({ row }) => {
                return (
                  <el-tooltip className="item" effect="dark" placement="top-start">
                    <div slot="content">
                      <div>
                        订单金额：{ this.$options.filters.currency(row.order_price) }
                      </div>
                      <div>
                        商品金额：{ this.$options.filters.currency(row.goods_price) }
                      </div>
                      <div>
                        运费：{ this.$options.filters.currency(row.freight_price) }
                      </div>
                      <div>
                        提货券金额：{ this.$options.filters.currency(row.pickup_price) }
                      </div>
                    </div>
                    <div style="color: red; font-weight: bold;">
                      { this.$options.filters.currency(row.actual_price) } <i class="el-icon-warning" />
                    </div>
                  </el-tooltip>
                )
              }
            },
            {
              label: '订单状态',
              width: 70,
              prop: 'order_status_text'
            },
            {
              label: '创建时间',
              prop: 'create_time'
            }
          ]
        }
      }
    },
    methods: {
      async open({ orderSn }) {
        this.dialogVisible = true
        const { data } = await getOrderList({ orderSn })
        this.table.data = data
      }
    }
  }
</script>

<style scoped>

</style>
