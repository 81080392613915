import axios from './base'

export const getActivityList = ({ page = 1, size = 20 } = {}) => {
  return axios.get('activity/list', {
    params: { page, size }
  })
}

export const getActivityDetail = ({ id }) => {
  return axios.get('activity/detail', {
    params: { id }
  })
}

export const saveActivity = ({
                                 id,
                                 name,
                                 skuIds,
                                 state,
                                 image,
                                 type,
                                 desc
}) => {
  return axios.post('activity/save', {
    id,
    name,
    skuIds,
    state,
    image,
    type,
    desc
  })
}

export const changeActivityState = ({ id, state }) => {
  return axios.post('activity/changeState', {
    id,
    state
  })
}

export const checkActivitySku = ({ activityId, skuIds, isChecked }) => {
  return axios.post('activity/checkSku', {
    activityId,
    skuIds,
    isChecked
  })
}

export const setNoticeActivity = ({ activityId }) => {
  return axios.post('activity/setNoticeActivity', {
    activityId
  })
}

export const getNoticeActivity = () => {
  return axios.get('activity/getNoticeActivity')
}
