import { BaseEnum } from '../base';

/** 订单类别
 * 1.普通商品
 2.大家电
 3.实物礼品卡
 4.售后换新单
 5.厂家直送订单
 6.FBP订单
 7.生鲜
 20.电子卡
 21.机票
 22.酒店
 23.合约机号卡
 24.火车票[@文祥：更新新订单类型；父单子单的订单类型形成规则。特殊说明虚拟订单，虚拟订单通常有专门的查询接口]
 * @type {BaseEnum}
 */
const JdOrderTypeEnum = new BaseEnum({
  Normal: {
    value: 1,
    label: '普通商品'
  },
  Appliances: {
    value: 2,
    label: '大家电'
  },
  GiftCard: {
    value: 3,
    label: '实物礼品卡'
  },
  PostSale: {
    value: 4,
    label: '售后换新单'
  },
  FactoryDelivery: {
    value: 5,
    label: '厂家直送订单'
  },
  FBP: {
    value: 6,
    label: 'FBP订单'
  },
  Fresh: {
    value: 7,
    label: '生鲜'
  },
  ECard: {
    value: 20,
    label: '电子卡'
  },
  AirTicket: {
    value: 21,
    label: '机票'
  },
  Hotel: {
    value: 22,
    label: '酒店'
  },
  ContractNumberCard: {
    value: 23,
    label: '合约机号卡'
  },
  TrainTicket: {
    value: 24,
    label: '火车票'
  }
});

export default JdOrderTypeEnum;
