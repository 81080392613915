var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":((_vm.isEditing ? '修改' : '新建') + "公司信息"),"visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.close}},[_c('div',{staticClass:"page-company__upsert"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"size":"small","label-width":"150px"}},[_c('el-form-item',{attrs:{"label":"公司名称","prop":"name","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('el-form-item',{attrs:{"label":"纳税识别号","prop":"tax_id","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入纳税识别号"},model:{value:(_vm.formData.tax_id),callback:function ($$v) {_vm.$set(_vm.formData, "tax_id", $$v)},expression:"formData.tax_id"}})],1),_c('el-form-item',{attrs:{"label":"地址","prop":"address","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司地址"},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}})],1),_c('el-form-item',{attrs:{"label":"开户行","prop":"bank","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司开户行"},model:{value:(_vm.formData.bank),callback:function ($$v) {_vm.$set(_vm.formData, "bank", $$v)},expression:"formData.bank"}})],1),_c('el-form-item',{attrs:{"label":"对公账号","prop":"public_account","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入对公账号"},model:{value:(_vm.formData.public_account),callback:function ($$v) {_vm.$set(_vm.formData, "public_account", $$v)},expression:"formData.public_account"}})],1),_c('el-form-item',{attrs:{"label":"公司电话","prop":"phone","rules":[
        {
          required: true,
          message: '不能为空'
        },
        {
          min: 11,
          max: 11,
          message: '不是有效的电话号码'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入公司电话"},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1),_c('el-form-item',{attrs:{"label":"联系人","prop":"contact","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入联系人"},model:{value:(_vm.formData.contact),callback:function ($$v) {_vm.$set(_vm.formData, "contact", $$v)},expression:"formData.contact"}})],1),_c('el-form-item',{attrs:{"label":"业务类型","prop":"type","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-select',{attrs:{"clearable":"","placeholder":"请选择公司类型"},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}},_vm._l((_vm.typeOp),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"联系人电话","prop":"contact_phone","rules":[
        {
          required: true,
          message: '不能为空'
        },
        {
          min: 11,
          max: 11,
          message: '不是有效的电话号码'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入联系人电话"},model:{value:(_vm.formData.contact_phone),callback:function ($$v) {_vm.$set(_vm.formData, "contact_phone", $$v)},expression:"formData.contact_phone"}})],1),_c('el-form-item',{attrs:{"label":"联系人邮箱","prop":"contact_mail","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入联系人邮箱"},model:{value:(_vm.formData.contact_mail),callback:function ($$v) {_vm.$set(_vm.formData, "contact_mail", $$v)},expression:"formData.contact_mail"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }