var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"page-admin__add",attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色","prop":"role"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.adminRoleId),callback:function ($$v) {_vm.$set(_vm.formData, "adminRoleId", $$v)},expression:"formData.adminRoleId"}},_vm._l((_vm.adminRoleData),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"账号","prop":"username","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})],1),_c('el-form-item',{attrs:{"label":"昵称","prop":"nickname","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"placeholder":"请输入昵称"},model:{value:(_vm.formData.nickname),callback:function ($$v) {_vm.$set(_vm.formData, "nickname", $$v)},expression:"formData.nickname"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"type":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c('el-form-item',{attrs:{"label":"密码确认","prop":"confirm_password","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"type":"password"},model:{value:(_vm.formData.confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"formData.confirm_password"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }