<template>
  <div class="page-user__pickup-list">
    <h2>提货卡</h2>
    <el-button type="primary" @click="openAddDialog">生成提货卡</el-button>
    <el-button type="primary" @click="openAddBatchDialog">批量生成提货卡</el-button>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="状态">
        <el-select v-model="formData.state" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
            v-for="(item, index) in stateOptions"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="用户 ID">-->
<!--        <el-input v-model="formData.userId" placeholder="用户 ID" clearable />-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
      </el-form-item>
    </el-form>
    <op-table
      style="margin-top: 20px;"
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination" />
    <add-dialog ref="AddDialog" @refresh="loadTableData" />
    <add-batch-dialog ref="AddBatchDialog" @refresh="loadTableData" />
  </div>
</template>

<script>
  import AddDialog from './add'
  import AddBatchDialog from './add_batch'
  import { getPickupCardList, sendPickupCode } from '@/api/pickup'

  export default {
    name: 'list',
    components: {
      AddDialog,
      AddBatchDialog
    },
    data() {
      return {
        // 提货卡状态，未使用 0，已使用 1，已取消 2
        stateOptions: [
          {
            value: 0,
            label: '未使用'
          },
          {
            value: 1,
            label: '已使用'
          },
          {
            value: 2,
            label: '已取消'
          }
        ],
        formData: {
          state: ''
        },
        table: {
          data: [],
          cols: [
            {
              label: 'ID',
              width: 50,
              prop: 'id'
            },
            {
              label: '提货码',
              width: 140,
              render: ({ row }) => {
                return (
                  <div style="font-weight: bold; font-size: 14px;">{ row.code }</div>
                )
              }
            },
            {
              label: '金额（元）',
              width: 80,
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.price) }</div>
                )
              }
            },
            {
              label: '创建者',
              width: 60,
              prop: 'creator_name'
            },
            {
              label: '创建时间',
              prop: 'create_time'
            },
            {
              label: '被绑定用户',
              prop: 'bind_user_mobile'
            },
            {
              label: '使用用户ID',
              width: 60,
              prop: 'use_user_id'
            },
            {
              label: '使用时间',
              prop: 'use_time'
            },
            {
              label: '状态',
              width: 80,
              render: ({ row }) => {
                const map = {
                  0: '未使用',
                  1: '已使用',
                  2: '已取消'
                }
                return (
                  <div>{ map[row.state] }</div>
                )
              }
            },
            {
              label: '发放状态',
              width: 80,
              render: ({ row }) => {
                const map = {
                  0: '未发放',
                  1: '已短信发放',
                  2: '已文件发放'
                }
                return (
                  <div>{ map[row.send_state] }</div>
                )
              }
            },
            {
              label: '发放时间',
              prop: 'send_time'
            },
            {
              label: '截止时间',
              prop: 'deadline'
            },
            {
              label: '备注',
              prop: 'remark'
            },
            {
              label: '操作',
              fixed: 'right',
              render: ({ row }) => {
                // 未使用、未发放且绑定了用户才能发送
                return (
                  <div>
                    {
                      row.state === 0 && row.send_state === 0 && row.bind_user_mobile &&
                      <el-button type="primary" size="small" onClick={() => this.sendRecharge(row)}>发送</el-button>
                    }
                    {/**
                      row.state === 0 &&
                      <el-button type="primary" size="small" onClick={() => this.cancelRecharge(row)}>取消</el-button>
                    **/}
                  </div>
                )
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    created() {
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { state } = this.formData
        const { data, count } = await getPickupCardList({ page, size, state })
        this.table.data = data
        this.table.pagination.total = count
      },
      async sendRecharge({ id }) {
        await this.$confirm('确认发送该提货码给用户？', '提示', { type: 'warning' })
        try {
          await sendPickupCode({ id })
          this.loadTableData()
          this.$message.success('发送成功')
        } catch (e) {
          this.$message.error('发送失败')
        }
      },
      // async cancelRecharge({ id }) {
      //   await this.$confirm('确认取消该提货卡？', '提示', { type: 'warning' })
      //   try {
      //     await cancelRechargeCode({ id })
      //     this.loadTableData()
      //     this.$message.success('取消成功')
      //   } catch (e) {
      //     this.$message.error('取消失败')
      //   }
      // },
      openAddDialog() {
        this.$refs.AddDialog.open()
      },
      openAddBatchDialog() {
        this.$refs.AddBatchDialog.open()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__pickup-list {
    .demo-form-inline {
      margin-top: 20px;
    }
  }
</style>
