<template>
  <div class="page-jd-order__new-list">
    <h2>京东拒收订单列表</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <!--      <el-form-item label="京东订单号">-->
      <!--        <el-input v-model="formData.jdOrderId" placeholder="请输入" clearable />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="订单状态">-->
      <!--        <el-select v-model="formData.status" placeholder="请选择" @change="loadTableData" clearable>-->
      <!--          <el-option-->
      <!--            v-for="(item, index) in statusOptions"-->
      <!--            :key="index"-->
      <!--            :label="item.label"-->
      <!--            :value="item.value">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->
      <el-form-item label="查询日期">
        <el-date-picker
          style="width: 380px;"
          v-model="formData.date"
          type="daterange"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
      </el-form-item>
    </el-form>
    <op-table
      style="margin-top: 20px;"
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination" />
    <jd-order-dialog ref="JdOrderDialog"></jd-order-dialog>
    <delivery-dialog ref="DeliveryDialog"></delivery-dialog>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import DeliveryDialog from '../../delivery/delivery'
  import JdOrderDialog from '@/components/jd_order_dialog/jd_order_dialog'
  import { getJdRejectOrderList } from '@/api/jd_order'

  export default {
    name: 'reject',
    components: {
      JdOrderDialog,
      DeliveryDialog
    },
    data() {
      const today = dayjs()
      return {
        formData: {
          date: [today.subtract(3, 'month').format('YYYY-MM-DD'), today.format('YYYY-MM-DD')]
        },
        table: {
          data: [],
          cols: [
            {
              label: '京东子订单号',
              width: 110,
              prop: 'jdOrderId'
            },
            {
              label: '订单价 (元)',
              width: 80,
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.orderPrice) }</div>
                )
              }
            },
            {
              label: '订单状态',
              width: 80,
              render: ({ row }) => {
                // 0 是新建  1是妥投   2是拒收
                const map = {
                  0: '新建',
                  1: '妥投',
                  2: '拒收',
                }
                return (
                  <div>{ map[row.state] }</div>
                )
              }
            },
            {
              label: '开票方式',
              width: 80,
              render: ({ row }) => {
                // 1为随货开票，0为订单预借，2为集中开票
                const map = {
                  0: '订单预借',
                  1: '随货开票',
                  2: '集中开票',
                }
                return (
                  <div>{ map[row.invoiceState] }</div>
                )
              }
            },
            {
              label: '是否挂起',
              width: 80,
              render: ({ row }) => {
                // 0为为挂起  1为挂起
                const map = {
                  0: '未挂起',
                  1: '挂起'
                }
                return (
                  <div>{ map[row.hangUpState] }</div>
                )
              }
            },
            {
              label: '创建时间',
              prop: 'time'
            },
            {
              label: '操作',
              // width: 180,
              render: ({ row }) => {
                return (
                  <div>
                    {
                      (
                        <span>
                          <el-button type="primary" size="small" onClick={() => this.openJdOrderDialog(row)}>订单详情</el-button>
                          <el-button type="primary" size="small" onClick={() => this.openDeliveryDialog(row)}>查看物流</el-button>
                        </span>
                      )
                    }
                  </div>
                )
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    created() {
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { date } = this.formData;
        const { orders: data, total: count } = await getJdRejectOrderList({page,
          size,
          date: dayjs(date[0]).format('YYYY-MM-DD'),
          endDate: dayjs(date[1]).add(1, 'day').format('YYYY-MM-DD')
        })
        this.table.data = data
        this.table.pagination.total = count
      },
      openJdOrderDialog({ jdOrderId }) {
        this.$refs.JdOrderDialog.open(jdOrderId)
      },
      openDeliveryDialog({ jdOrderId }) {
        this.$refs.DeliveryDialog.open(jdOrderId)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-jd-order__new-list {

  }
</style>
