import axios from './base'

export const getCatalogList = ({ parentId, catClass, isCount, page, size } = {}) => {
  return axios.get('catalog/list', {
    params: {
      parentId,
      catClass,
      isCount,
      page,
      size
    }
  })
}

export const getAllCatalogList =({parentId=0,catClass=0}) => {
  return axios.get('catalog/listAll',{
    params: {
      parentId,
      catClass
    }
  })
}

export const getTopCatalogList = () => {
  return axios.get('catalog/top')
}

export const getChildrenCatalogList = ({ id }) => {
  return axios.get('catalog/allChildren', {
    params: { id }
  })
}

export const asyncCatalog = ({ parentId, catClass } = {}) => {
  return axios.post('catalog/async', {
    parentId,
    catClass
  })
}

export const addCatalog = ({
                             id,
                             name,
                             catId,
                             catClass,
                             state,
                             parentId,
                             sortOrder,
                             image
} = {}) => {
  return axios.post('catalog/add', {
    id,
    name,
    catId,
    catClass,
    state,
    parentId,
    sortOrder,
    image
  })
}

export const deleteCatalog = ({
                             catId
} = {}) => {
  return axios.post('catalog/delete', {
    catId
  })
}

export const changeCataState = ({ catId, state } = {}) => {
  return axios.post('catalog/changeState', {
    catId,
    state
  })
}

export const allCatalogTree = () => {
  return axios.get('catalog/allTreeNode')
}
export const editSort = ({ catId, sortOrder } = {}) => {
    return axios.post('catalog/editSort', {
        catId,
        sortOrder
    })
}
export const editImage = ({ catId, image } = {}) => {
    return axios.post('catalog/editImage', {
        catId,
        image
    })
}
