<template>
  <div class="page-activity__list">
    <h2>首页 Banner 活动</h2>
    <router-link :to="{ name: 'bannerAdd' ,query:{companyId:companyId}}">
      <el-button type="primary" class="create-btn">新建活动</el-button>
    </router-link>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </div>
</template>

<script>
import { getFloorList, deleteFloor } from "@/api/company";

export default {
  name: "list",
  components: {},
  data() {
    return {
      companyId:0,
      table: {
        data: [],
        cols: [
          {
            prop: "id",
            label: "活动 ID",
            width: 100,
          },
          {
            prop: "catName",
            label: "类目名",
          },
          {
            prop: "companyName",
            label: "公司名",
          },
          {
            label: "图片",
            render: ({ row }) => {
              return (
                <el-image
                  style="width: 100px;"
                  previewSrcList={row.picUrl}
                  src={row.picUrl}
                />
              );
            },
          },
          {
            prop: "type",
            label: "楼层类型",
            width: 120,
            render: ({ row }) => {
              const stateMap = {
                2: "猜你喜欢",
                1: "首页长条广告",
                0: "轮播图",
                3: "首页栏目",
                4: "特别推荐",
              };
              return <div>{stateMap[row.type - 1] || ""}</div>;
            },
          },
          {
            label: "操作",
            width: 150,
            render: ({ row }) => {
              return (
                <div class="operation">
                 {
                    <el-button
                      type="primary"
                      size="small"
                      onClick={() => this.editCatalog({ id: row.id })}
                    >
                      修改
                    </el-button>
                  }
                  {
                    <el-button
                      type="danger"
                      size="small"
                      onClick={() => this.deleteCatalog({ id: row.id })}
                    >
                      删除
                    </el-button>
                  }
                </div>
              );
            },
          },
        ],
        pagination: {
          total: 0,
          pageSize: 20,
          currentPage: 1,
          pageSizes: [20, 40, 60, 80],
          layout: "total, sizes, prev, pager, next, jumper",
          on: {
            "current-change": (currentPage) => {
              this.table.pagination.currentPage = currentPage;
              this.loadTableData();
            },
            "size-change": (size) => {
              this.table.pagination.pageSize = size;
              this.loadTableData();
            },
          },
        },
      },
    };
  },
  created() {
    const { page } = this.$route.query;
    if (page) {
      this.table.pagination.currentPage = parseInt(page);
    }
    this.loadTableData();
  },
  methods: {
    async loadTableData() {

      const { companyId } = this.$route.params;
      this.companyId = companyId
      let data = await getFloorList({ companyId });
      this.table.data = data;
    },
    async deleteCatalog({ id }) {
      await this.$confirm(`确定删除？`, "提示", { type: "warning" });
      try {
        await deleteFloor({ id });
        this.loadTableData();
        this.$message.success("删除成功");
      } catch (e) {
        this.$message.error("删除失败");
      }
    },
    editCatalog({id}) {
      try {
         const { companyId } = this.$route.params;
        this.$router.push({name:'bannerEdit',query:{id,companyId}})
      } catch (e) {
        this.$message.error("修改失败");
        console.error(e)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.page-activity__list {
  .create-btn {
    margin-bottom: 20px;
  }
}
</style>
