import { BaseEnum } from '../base';

/**
 * 京东状态。查询参数中包含queryExts=jdOrderState。参考枚举值如下：
 1.新单
 2.等待支付
 3.等待支付确认
 4.延迟付款确认
 5.订单暂停
 6.店长最终审核
 7.等待打印
 8.等待出库
 9.等待打包
 10.等待发货
 11.自提途中
 12.上门提货
 13.自提退货
 14.确认自提
 16.等待确认收货
 17.配送退货
 18.货到付款确认
 19.已完成
 21.收款确认
 22.锁定
 29.等待三方出库
 30.等待三方发货
 31.等待三方发货完成
 * @type {BaseEnum}
 */
const JdOrderTypeEnum = new BaseEnum({
  New: {
    value: 1,
    label: '新单'
  },
  WaitingPay: {
    value: 2,
    label: '等待支付'
  },
  WaitingPayConfirm: {
    value: 3,
    label: '等待支付确认'
  },
  DelayedPayConfirm: {
    value: 4,
    label: '延迟付款确认'
  },
  Suspend: {
    value: 5,
    label: '订单暂停'
  },
  StoreManagerReview: {
    value: 6,
    label: '店长最终审核'
  },
  WaitingPrint: {
    value: 7,
    label: '等待打印'
  },
  WaitingOutOfStock: {
    value: 8,
    label: '等待出库'
  },
  WaitingPacking: {
    value: 9,
    label: '等待打包'
  },
  WaitingShip: {
    value: 10,
    label: '等待发货'
  },
  OnTheWay: {
    value: 11,
    label: '自提途中'
  },
  CollectGoods: {
    value: 12,
    label: '上门提货'
  },
  SelfRefund: {
    value: 13,
    label: '自提退货'
  },
  SelfConfirm: {
    value: 14,
    label: '确认自提'
  },
  WaitingConfirmReceipt: {
    value: 16,
    label: '等待确认收货'
  },
  DeliveryReFund: {
    value: 17,
    label: '配送退货'
  },
  CashOnDeliveryConfirm: {
    value: 18,
    label: '货到付款确认'
  },
  Finished: {
    value: 19,
    label: '已完成'
  },
  PaymentConfirm: {
    value: 21,
    label: '收款确认'
  },
  Locking: {
    value: 22,
    label: '锁定'
  },
  WaitingThirdPartOutStock: {
    value: 29,
    label: '等待三方出库'
  },
  WaitingThirdPartSendGoods: {
    value: 30,
    label: '等待三方发货'
  },
  WaitingThirdPartSendGoodsConfirm: {
    value: 31,
    label: '等待三方发货完成'
  }
});

export default JdOrderTypeEnum;
