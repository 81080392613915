<template>
  <el-dialog width="85%" title="配置推荐位" :visible.sync="dialogVisible">
    <op-goods-list ref="OpGoodsList">
      <el-table-column
              label="操作"
              width="225">
        <template slot-scope="{ row }">
          <div class="operation" v-if="row.state === 1">
            <el-button type="primary" size="small" @click="setToPosition(row)">推荐</el-button>
          </div>
        </template>
      </el-table-column>
    </op-goods-list>
  </el-dialog>
</template>

<script>
  import { positionSet } from '@/api/position'
  import OpGoodsList from '@/components/goods_list/goods_list'

  export default {
    name: 'addPositionItem',
    data() {
      return {
        dialogVisible: false,
        positionId: ''
      }
    },
    components: {
      OpGoodsList
    },
    methods: {
      open(positionId) {
        this.dialogVisible = true;
        this.positionId = positionId;
      },
      async setToPosition({ sku }) {
        const { userId: user_id } = this.$route.query;
        await positionSet({
          user_id,
          position_id: this.positionId,
          goods_id: sku
        });
        this.$emit('refresh');
      }
    }
  }
</script>

<style scoped>

</style>
