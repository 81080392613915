import axios from './base'

export const getUserList = ({ page, size, companyId, mobile }) => {
  return axios.get('user/list', {
    params: { page, size, companyId, mobile }
  })
}

export const downloadUserList = ({ companyId, mobile }) => {
  return axios.post('user/download', {
    companyId, mobile
  }, {
    responseType: 'blob'
  })
}

export const storeUserInfo = ({
  id,
  company_id,
  role,
  username,
  password,
  mobile,
  nickname
}) => {
  return axios.post('user/store', {
    id,
    company_id,
    role,
    username,
    password,
    mobile,
    nickname
  })
}

export const editUserInfo = ({ companyId, accountAmount, mobile, userId }) => {
  return axios.post('user/account', {
    companyId, accountAmount, mobile, userId
  })
}

export const deleteUser = ({ id }) => {
  return axios.post('user/destroy', {
    id
  })
}

export const changePassword = ({ id, password }) => {
  return axios.post('user/changePassword', {
    id, password
  })
}

export const getUserAccountLogList = ({ page, size, companyId, mobile, userId, createdBeginDate ,createdEndDate}) => {
  return axios.get('user/accountLogList', {
    params: { page, size, companyId, mobile, userId, createdBeginDate, createdEndDate }
  })
}

export const downloadUserAccountLogList = ({ page, size, companyId, mobile, userId,createdBeginDate,createdEndDate }) => {
  return axios.post('user/downloadAccountLogList', {
    page, size, companyId, mobile, userId,createdBeginDate,createdEndDate
  }, {
    responseType: 'blob'
  })
}

export const setUserCategory = ({ userId, categoryIds }) => {
  return axios.post('user/setCategory', {
    userId, categoryIds
  })
}

export const getUserCategoryChecked = ({ userId }) => {
  return axios.get('user/getCategoryChecked', {
    params: {
      userId
    }
  })
}
