import { BaseEnum } from '../base';
const ErrorCodeEnum = new BaseEnum({
  // SKU 价格改动
  SKU_PRICE_CHANGED: {
    value: 10001,
    label: '商品价格变动',
    msg: '商品价格修改了，刷新一下页面吧~'
  }
})

export default ErrorCodeEnum
