import axios from './base'

export const cancelJdInvoiceApply = ({ markId }) => {
  return axios.get('jd_invoice/cancelApply', {
      params: { markId }
  })
}

export const getJdInvoiceConfig = () => {
  return axios.get('jd_invoice/getConfig')
}

export const getJdInvoiceList = ({ markId }) => {
  return axios.get('jd_invoice/list', {
    params: { markId },
  })
}

export const getJdInvoiceApplyList = () => {
  return axios.get('jd_invoice/applyList')
}

export const getJdInvoiceApplyNo = ({ jdOrderId }) => {
  return axios.get('jd_invoice/queryThrApplyNo', {
    params: { jdOrderId },
  })
}

export const applyJdInvoice = ({
    supplierOrder = [],
    markId = '',
    settlementId = '',
    settlementNum = 0,
    settlementNakedPrice = 0,
    settlementTaxPrice = 0,
    invoiceType = 1,
    invoiceOrg = '',
    bizInvoiceContent = 1,
    invoiceDate = '',
    title = '',
    billToParty = '',
    enterpriseTaxpayer = '',
    billToer = '',
    billToContact = '',
    billToProvince = '',
    billToCity = '',
    billToCounty = '',
    billToTown = '',
    billToAddress = '',
    repaymentDate = '',
    invoiceNum = 0,
    invoiceNakedPrice = 0,
    invoiceTaxPrice = 0,
    invoicePrice = 0,
    currentBatch = 0,
    totalBatch = 0,
    totalBatchInvoiceNakedAmount = 0,
    totalBatchInvoiceTaxAmount = 0,
    totalBatchInvoiceAmount = 0,
    billingType = 1,
    isMerge = 1,
    poNo = '',
    enterpriseRegAddress = '',
    enterpriseRegPhone = '',
    enterpriseBankName = '',
    enterpriseBankAccount = ''
                             }) => {
  return axios.post('jd_invoice/apply', {
    supplierOrder,
    markId,
    settlementId,
    settlementNum,
    settlementNakedPrice,
    settlementTaxPrice,
    invoiceType,
    invoiceOrg,
    bizInvoiceContent,
    invoiceDate,
    title,
    billToParty,
    enterpriseTaxpayer,
    billToer,
    billToContact,
    billToProvince,
    billToCity,
    billToCounty,
    billToTown,
    billToAddress,
    repaymentDate,
    invoiceNum,
    invoiceNakedPrice,
    invoiceTaxPrice,
    invoicePrice,
    currentBatch,
    totalBatch,
    totalBatchInvoiceNakedAmount,
    totalBatchInvoiceTaxAmount,
    totalBatchInvoiceAmount,
    billingType,
    isMerge,
    poNo,
    enterpriseRegAddress,
    enterpriseRegPhone,
    enterpriseBankName,
    enterpriseBankAccount,
  })
}
