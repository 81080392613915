import { BaseEnum } from './base';

const OrderStatusEnum = new BaseEnum({
  ALL: {
    key: 'PENDING_PAYMENT',
    value: '',
    label: '全部'
  },
  PENDING_PAYMENT: {
    key: 'PENDING_PAYMENT',
    value: 0,
    label: '待付款',
    handleOptions: {
      cancel: true,
      pay: true
    }
  },
  PENDING_RECEIPT: {
    key: 'PENDING_RECEIPT',
    value: 201,
    label: '待收货',
    handleOptions: {
      receipt: true,
      delivery: true
    }
  },
  FINISHED: {
    key: 'FINISHED',
    value: 101,
    label: '已完成',
    handleOptions: {
      // buy: true
      delivery: true
    }
  },
  RETURN: {
    key: 'RETURN',
    // value: '401,402',
    value: 401,
    label: '退换/售后',
    handleOptions: {
    }
  }
});

export default OrderStatusEnum;
