<template>
  <el-dialog
    title="京东订单物流信息"
    width="80%"
    :visible.sync="dialogVisible" >
    <div class="page-order__delivery">
      <div style="margin-bottom: 30px;" v-for="(item, index) in traceInfos" :key="index">
        <div class="orderinfo flex-row">
          <span>订单编号：</span>
          <span class="flex">{{item.jdOrderId}}</span>
        </div>
        <div class="deliverybox">
          <ul>
            <li v-for="(cItem, cIndex) in item.orderTrack" :key="cIndex">
              <div class="txt">{{cItem.content}}（{{cItem.operator}}）</div>
              <div class="date">{{cItem.msgTime}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import { getJdOrderTrace } from '@/api/jd_order'

  export default {
    name: 'delivery',
    data() {
      return {
        dialogVisible: false,
        jdOrderId: '',
        traceInfos: []
      }
    },
    watch: {
      async jdOrderId(nv) {
        if (!nv) {
          return
        }
        try {
          this.traceInfos = await getJdOrderTrace({ jdOrderId: nv })
        } catch (e) {
          this.$message.error(e)
        }
      }
    },
    async created() {
    },
    methods: {
      async open(jdOrderId) {
        this.dialogVisible = true;
        this.jdOrderId = jdOrderId
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-order__delivery {
    .orderinfo {
      padding: 10px;
      font-size: 16px;
      line-height: 30px;
      background: #fff;

      span {
        color: #666;
      }
      span+span {
        color: #333;
      }
    }

    .deliverybox {
      margin-top: 10px;
      padding: 15px 10px;
      background: #fff;

      .jd-order-id {
        font-size: 14px;
        margin-bottom: 10px;
      }

      li {
        padding-left: 44px;
        color: #333;
        font-size: 14px;
        position: relative;

        &:before {
          position: absolute;
          left: 15px;
          border-left: 1px solid #eee;
          transform: scaleX(.5);
          content: '';
          top: 7px;
          height: 100%;
          z-index: 1;
        }

        &:after {
          width: 20px;
          height: 20px;
          border-radius: 10px;
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAAXNSR0IArs4c6QAAACRQTFRFAAAA8QIU8QIU8QIU8QIU8QIU8QIU8QIU8QIU8QIU8QIU8QIU7iUcwAAAAAt0Uk5TAGZZI/d8z0M2IDqoWmVWAAAAdElEQVQI12NgYGAwVxQqZgCDSYJAoAlicQiCQQOQ2QhhSjAwMAtCgQEDE4ypwGAoKCia4hYoKCjMUCgoGLJ7t6ugoDiDoqBg9u7d2wQFhRiAqrx3794CpJCZSAqQtCEZhmQFksVIzmHggjAXwJ2uDPEG1EMAX7cfMVMvbxYAAAAASUVORK5CYII=);
          background-size: 100%;
          content: '';
          position: absolute;
          left: 5px;
          z-index: 2;
          top: 0;
          color: #fff;
          text-align: center;
        }

        &+li {
          padding-top: 20px;
          color: #aaa;
          &:after {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #ccc;
            top: 35px;
            left: 11px;
            background-image: none;
          }
        }

        .txt {
          line-height: 20px;
        }
        .date {
          margin-top: 10px;
          color: gray;
          font-size: 12px;
        }
      }
    }
  }
</style>
