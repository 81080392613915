<template>
  <div class="page-company__list">
    <h2>公司列表</h2>
    <el-select v-model="table.type" clearable placeholder="请选择">
      <el-option
              v-for="(item, index) in table.typeOp"
              :key="index"
              :label="item.label"
              :value="item.value"
      >
      </el-option>
    </el-select>
    <el-button @click="loadTableData" type="primary">搜索</el-button>
    <el-button @click="openAddEditDialog" type="primary">新建公司</el-button>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
    <add-edit-dialog ref="addEditDialog" @refresh="loadTableData" />
    <category-tree-dialog ref="categoryTreeDialog" />
    <category-position-tree-dialog ref="categoryPositionTreeDialog"/>

    <el-dialog
      title="设置价格同步比例"
      width="30%"
      :visible.sync="isSyncPriceDialogShown" >
      <div>
        <el-form :model="changePriceRow" class="demo-form-inline" label-width="150px">
          <el-form-item label="价格同步比例">
            <el-input v-model="syncPriceRate" type="number" placeholder="售卖价" />
          </el-form-item>
          <div class="price-tip">
            <div>价格同步比例：</div>
            <div>1. 商品的售卖价会根据京东侧的企业购价 * 同步比例（如 1.20）设置。</div>
          </div>
<!--          <el-form-item label="企业购价 (京东价)(元)">-->
<!--            <div>-->
<!--              <span style="font-weight: bold;">-->
<!--               {{changePriceRow.price | currency}}-->
<!--              </span>(<span>{{changePriceRow.jd_price | currency}}</span>)-->
<!--            </div>-->
<!--          </el-form-item>-->
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isSyncPriceDialogShown = false">取 消</el-button>
        <el-button type="primary" @click="saveCompanyPriceRate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import AddEditDialog from '../upsert/upsert'
  import { getCompanyList,saveCompanyPriceRate,getCompanyPriceRate } from '@/api/company'
  import { getRoleAuthChecked } from '@/api/admin'
  import CategoryTreeDialog from '../categoryTree/categoryTree'
  import CategoryPositionTreeDialog from '../categoryPositionTree/categoryPositionTree'

  export default {
    name: 'list',
    components: {
      AddEditDialog,
      CategoryTreeDialog,
      CategoryPositionTreeDialog
    },
    data() {
      return {
        isSyncPriceDialogShown:false,
        syncPriceRate: 1.25,
        companyId:0,
        permission: [],
        table: {
          type:'',
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({ row }) => {
                return (
                  <el-form labelPosition="left" inline class="table-expand">
                    <el-form-item label="公司名称">
                      <span>{row.name}</span>
                    </el-form-item>
                    <el-form-item label="纳税识别号">
                      <span>{row.tax_id}</span>
                    </el-form-item>
                    <el-form-item label="地址">
                      <span>{row.address}</span>
                    </el-form-item>
                    <el-form-item label="开户行">
                      <span>{row.bank}</span>
                    </el-form-item>
                    <el-form-item label="对公账号">
                      <span>{row.public_account}</span>
                    </el-form-item>
                    <el-form-item label="电话">
                      <span>{row.phone}</span>
                    </el-form-item>
                    <el-form-item label="联系人">
                      <span>{row.contact}</span>
                    </el-form-item>
                    <el-form-item label="联系人电话">
                      <span>{row.contact_phone}</span>
                    </el-form-item>
                    <el-form-item label="联系人邮箱">
                      <span>{row.contact_mail}</span>
                    </el-form-item>
                    <el-form-item label="创建时间">
                      <span>{row.create_time}</span>
                    </el-form-item>
                    <el-form-item label="更新时间">
                      <span>{row.update_time}</span>
                    </el-form-item>
                  </el-form>
                );
              }
            },
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              prop: 'name',
              label: '公司名称'
            },
            {
              label: '联系人',
              prop: 'contact'
            },
            {
              label: '联系人电话',
              prop: 'contact_phone'
            },
              {
                  label: '业务类型',
                  render: ({ row }) => {
                      const genderMap = {
                          1: '工会',
                          2: '核电',
                          3: '普通',
                          4:'餐补',
                          5:'供电'
                      }
                      return (
                          <span>{genderMap[row.type]}</span>
                  )
                  }
              },
            {
              label: '联系人邮件',
              prop: 'contact_mail'
            },
            {
              label: '操作',
                width: 350,
                render: ({row}) => {
                return (
                  <div class="operation">
                    <el-button
                      type="primary"
                      size="small"
                      onClick={() =>
                        this.openAddEditDialog(row)
                      }
                    >
                      修改
                    </el-button>
                    {
                      this.isCategoryShown &&
                      <el-button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.openSyncPriceDialog(row)
                        }
                      >
                        价格比例
                      </el-button>
                    }
                    {
                      this.isCategoryShown &&
                      <el-button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.openCategoryTreeDialog(row)
                        }
                      >
                        公司分类
                      </el-button>
                    }
                    {
                      this.isCategoryShown &&
                      <el-button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.openPositionCategoryTreeDialog(row)
                        }
                      >
                        推荐分类
                      </el-button>
                    }
                    {
                      this.isIndexConfigShown &&
                      <el-button
                        type="primary"
                        size="small"
                        onClick={() =>
                          this.openPositionDetail(row)
                        }
                      >
                        首页配置
                      </el-button>
                    }
                  </div>
                );
              }
            }
          ],
            typeOp:[
                {label:'工会',value:1},
                {label:'核电',value:2},
                {label:'普通',value:3},
                {label:'餐补',value:4},
                {label:'供电',value:5}
            ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    computed: {
      isCategoryShown() {
        return this.permission.indexOf('pCompanyCategory') > -1
      },
      isIndexConfigShown() {
        return this.permission.indexOf('pCompanyIndexPosition') > -1
      }
    },
    created() {
      this.loadTableData()
      this.getPermission()
    },
    methods: {
      async getPermission() {
        this.permission = await getRoleAuthChecked({
          type: 1,
          roleId: this.$store.state.user.userInfo.admin_role_id
        })
      },
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
          const { type:type } = this.table;
        console.log(type);
        const { data, count } = await getCompanyList({ page, size,type })
        this.table.data = data
        this.table.pagination.total = count
      },
      openAddEditDialog({ id } = {}) {
        this.$refs.addEditDialog.open(id)
      },
      async openSyncPriceDialog({ id } = {}) {
         this.isSyncPriceDialogShown = true
         this.companyId = id
        // 获取价格同步比例
        let result = await getCompanyPriceRate({companyId:this.companyId})
        this.syncPriceRate = result.rate
      },
      openCategoryTreeDialog({ id } = {}) {
        this.$refs.categoryTreeDialog.open(id)
      },
      openPositionCategoryTreeDialog({ id } = {}) {
        this.$refs.categoryPositionTreeDialog.open(id)
      },
      openPositionDetail(row) {
        this.$router.push({
          name: 'CompanyIndexComponent',
          params: {
            companyId: row.id,
              type:row.type
          }
        });
      },
       async saveCompanyPriceRate() {
        await this.$confirm('这将会修改所有商品的价格，确定修改价格同步比例？', '提示')
        try {
          const { syncPriceRate,companyId } = this
          await saveCompanyPriceRate({
            rate:syncPriceRate,companyId
          })
          this.$message.success('修改价格同步比例成功')
          this.isSyncPriceDialogShown = false
          // this.loadTableData()
        } catch (e) {
          this.$message.error(e)
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .page-company__list {

  }

  .operation {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
</style>
