import { render, staticRenderFns } from "./offlist.vue?vue&type=template&id=55f72731&scoped=true&"
import script from "./offlist.vue?vue&type=script&lang=js&"
export * from "./offlist.vue?vue&type=script&lang=js&"
import style0 from "./offlist.vue?vue&type=style&index=0&id=55f72731&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f72731",
  null
  
)

export default component.exports