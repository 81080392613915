<template>
  <div class="page-activity__list">
    <h2>首页 Banner 活动</h2>
    <router-link :to="{ name: 'activityAdd' }">
      <el-button type="primary" class="create-btn">新建活动</el-button>
    </router-link>
    <el-button type="primary" class="set-btn" @click="openNoticeActivity">设置文字滚动活动</el-button>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination" />
    <notice-dialog ref="NoticeDialog" />
  </div>
</template>

<script>
  import { getActivityList, changeActivityState } from '@/api/activity'
  import NoticeDialog from '../notice/notice'

  export default {
    name: 'list',
    components: {
      NoticeDialog
    },
    data() {
      return {
        table: {
          data: [],
          cols: [
            {
              prop: 'id',
              label: '活动 ID',
              width: 100
            },
            {
              prop: 'name',
              label: '活动名称'
            },
            {
              prop: 'desc',
              label: '活动描述'
            },
            {
              label: '图片',
              prop: 'image'
            },
            {
              prop: 'state',
              label: '状态',
              width: 80,
              render: ({ row }) => {
                const stateMap = {
                  1: '已上架',
                  0: '已下架'
                }
                return <div>{ stateMap[row.state] || '' }</div>
              }
            },
            {
              label: '操作',
              width: 150,
              render: ({ row }) => {
                return (
                  <div class="operation">
                    {
                      row.state === 1 &&
                      <router-link to={{ name: 'activityEdit', query: { id: row.id } }}>
                        <el-button type="primary" size="small">编辑</el-button>
                      </router-link>
                    }
                    {
                      row.state === 1 && <el-button type="danger" size="small" onClick={() => this.changeActivityState(row, 0)}>下架</el-button>
                    }
                    {
                      row.state === 0 && <el-button type="primary" size="small" onClick={() => this.changeActivityState(row, 1)}>上架</el-button>
                    }
                  </div>
                );
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    created() {
      const { page } = this.$route.query
      if (page) {
        this.table.pagination.currentPage = parseInt(page)
      }
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const {data, count} = await getActivityList({
          page,
          size
        })
        // if (page !== parseInt(this.$route.query.page)) {
        //   this.$router.push({query: {page}})
        // }
        this.table.data = data
        this.table.pagination.total = count
      },
      async changeActivityState({ id }, state) {
        try {
          await changeActivityState({ id, state })
          this.$message.success('操作成功')
          this.loadTableData()
        } catch (e) {
          this.$message.error('操作失败')
        }
      },
      openNoticeActivity() {
        this.$refs.NoticeDialog.open(this.table.data)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-activity__list {
    .create-btn {
      margin-bottom: 20px;
    }
  }
</style>
