<template>
    <el-dialog
            title="分类排序"
            :visible.sync="dialogVisible"
            width="40%">
        <div class="catalog_sort">
            <el-form :model="formData" ref="form" label-width="150px">

                <el-form-item label="排列权重" prop="sort"
                              :rules="[{
            required: true,
            message: '不能为空'
          }]">
                    <el-input style="width: 200px;" v-model="formData.sort" placeholder="请输入排列权重" />
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="close">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>
<script>
    import { editSort } from '@/api/catalog'
    export default {
        name: 'sort',
        data() {
            return {
                dialogVisible: false,
                formData: {
                    sort:'',
                    catId:''
                }
            }
        },
        methods: {
            open(row) {
                this.dialogVisible = true
                this.formData.sort = row.sortOrder
                this.formData.catId = row.catId
            },
            async save() {
                if (!(await this.$refs.form.validate())) {
                    return
                }
                try {
                    const {
                        sort,
                        catId
                    } = this.formData
                    await editSort({
                        catId: catId,
                        sortOrder: sort
                    })
                    this.$message.success('修改成功')
                    this.close()
                    this.$emit('refresh')
                } catch (e) {
                    this.$message.error('修改失败：' + e)
                }
            },
            close() {
                this.dialogVisible = false
            }
        }
    }
</script>