<template>
  <div class="page-catalog__list">
    <el-button type="primary" @click="openAddDialog" style="margin-bottom: 20px;">新增目录</el-button>
    <el-tabs v-model="activeTabIndex" type="border-card">
      <el-tab-pane :label="item.label" v-for="(item, index) in tabs" :key="index">
        <el-select v-if="index" v-model="parentId" placeholder="请选择" @change="loadTableData" filterable clearable>
          <el-option
            v-for="cItem in options"
            :key="cItem.catId"
            :label="`${cItem.name}（${cItem.catId}）`"
            :value="cItem.catId">
          </el-option>
        </el-select>
        <op-table :data="item.table.data"
                 :cols="table.cols"
                  v-loading="loading"
                 :pagination="item.table.pagination"/>
      </el-tab-pane>
    </el-tabs>
    <add-dialog ref="addDialog" @refresh="loadTableData" />
    <sort-dialog ref="sortDialog" />
    <img-dialog ref="imgDialog" />
  </div>
</template>

<script>
  import {
    getCatalogList,
    // asyncCatalog,
    changeCataState,
    deleteCatalog
  } from '@/api/catalog'
  import addDialog from '../add/add'
  import sortDialog from '../add/sort'
  import imgDialog from '../add/img'
  // import { syncCatSku } from '@/api/page_num'

  export default {
    name: 'list',
    components: {
      addDialog,
        sortDialog,
        imgDialog
    },
    data() {
      return {
        loading: false,
        parentId: null,
        activeTabIndex: 0,
        asyncCatalogLoading: {},
        syncCatSkuLoading: {},
        options: [],
        table: {
          cols: [
            {
              prop: 'catId',
              label: '分类 ID'
            },
            {
              prop: 'name',
              label: '分类名称'
            },
            {
              prop: 'parentId',
              label: '父分类 ID'
            },
            {
              label: '分类等级',
              render: ({ row }) => {
                const map = {
                  [0]: '一级',
                  [1]: '二级',
                  [2]: '三级'
                }
                return (
                  <div>{map[row.catClass]}</div>
                )
              }
            },
            {
              label: '状态',
              render: ({ row }) => {
                return (
                  <el-switch
                  active-value={1}
                  inactive-value={0}
                  onInput={(v) => this.changeCataState(row, v)}
                  value={row.state} />
                )
              }
            },
            {
              prop: 'sortOrder',
              label: '排列顺序'
            },
            {
              prop: 'goodsCount',
              label: '商品数量'
            },
            {
              prop: 'onStateGoodsCount',
              label: '上架商品数量'
            },
            {
              label: '操作',
              width: 300,
              render: ({ row }) => {
                return (
                  <div class="operation">
                    {
                      // TODO 暂时不按目录同步商品
                      // row.catClass === 2 && <el-button loading={this.syncCatSkuLoading[row.catId]} type="primary" size="small" onClick={() => this.syncCatSku(row)}>同步商品SKU</el-button>
                    }
                      <el-button type="primary" size="small" onClick={() => this.openAddDialog(row)}>修改</el-button>
                      <el-button type="primary" size="small" onClick={() => this.openSortDialog(row)}>排序</el-button>
                  <el-button type="primary" size="small" onClick={() => this.openImgDialog(row)}>上传分类图片</el-button>

                    {
                      row.goodsCount === 0 &&
                      <el-button type="danger" size="small" onClick={() => this.deleteCatalog(row)}>
                        删除
                      </el-button>
                    }
                  </div>
                )
              }
            }
          ]
        },
        tabs: [
          {
            label: '一级目录',
            table: {
              data: [],
              pagination: {
                total: 0,
                pageSize: 20,
                currentPage: 1,
                pageSizes: [20, 40, 60, 80],
                layout: 'total, sizes, prev, pager, next, jumper',
                on: {
                  'current-change': (currentPage) => {
                    this.activeTab.table.pagination.currentPage = currentPage
                    this.loadTableData()
                  },
                  'size-change': (size) => {
                    this.activeTab.table.pagination.pageSize = size
                    this.loadTableData()
                  }
                }
              }
            }
          },
          {
            label: '二级目录',
            table: {
              data: [],
              pagination: {
                total: 0,
                pageSize: 20,
                currentPage: 1,
                pageSizes: [20, 40, 60, 80],
                layout: 'total, sizes, prev, pager, next, jumper',
                on: {
                  'current-change': (currentPage) => {
                    this.activeTab.table.pagination.currentPage = currentPage
                    this.loadTableData()
                  },
                  'size-change': (size) => {
                    this.activeTab.table.pagination.pageSize = size
                    this.loadTableData()
                  }
                }
              }
            }
          },
          {
            label: '三级目录',
            table: {
              data: [],
              pagination: {
                total: 0,
                pageSize: 20,
                currentPage: 1,
                pageSizes: [20, 40, 60, 80],
                layout: 'total, sizes, prev, pager, next, jumper',
                on: {
                  'current-change': (currentPage) => {
                    this.activeTab.table.pagination.currentPage = currentPage
                    this.loadTableData()
                  },
                  'size-change': (size) => {
                    this.activeTab.table.pagination.pageSize = size
                    this.loadTableData()
                  }
                }
              }
            }
          }
        ]
      }
    },
    computed: {
      activeTab() {
        return this.tabs[this.activeTabIndex]
      }
    },
    watch: {
      async activeTabIndex(nv) {
        this.parentId = null
        this.options = nv > 0 ? (await getCatalogList({ catClass: nv - 1, size: 1000 })).data : []
        this.loadTableData()
      }
    },
    methods: {
      async loadTableData() {
        try {
          this.loading = true
          const catClass = this.activeTabIndex
          const { currentPage: page, pageSize: size } = this.activeTab.table.pagination
          const { data, count } = await getCatalogList({
            catClass,
            parentId: this.parentId,
            isCount: true,
            page,
            size
          })
          this.activeTab.table.data = data
          this.activeTab.table.pagination.total = count
          this.loading = false
        } catch (e) {
          this.loading = false
        }
      },
      // async asyncCatalog({ catId, catClass}) {
      //   try {
      //     this.$set(this.asyncCatalogLoading, catId, true)
      //     await asyncCatalog({
      //       parentId: catId,
      //       catClass: catClass + 1
      //     })
      //     this.$message.success('同步成功')
      //   } catch (e) {
      //     this.$message.error('同步失败')
      //   } finally {
      //     this.$set(this.asyncCatalogLoading, catId, false)
      //   }
      // },
      // async syncCatSku({ catId }) {
      //   try {
      //     this.$set(this.syncCatSkuLoading, catId, true)
      //     await syncCatSku({ catId })
      //     this.loadTableData()
      //     this.$message.success('同步成功')
      //   } catch (e) {
      //     this.$message.error('同步失败')
      //   } finally {
      //     this.$set(this.syncCatSkuLoading, catId, false)
      //   }
      // },
      async changeCataState({ catId }, state) {
        await this.$confirm(`确定${ state ? '上线' : '下线' }？`, '提示', { type: 'warning' });
        try {
          await changeCataState({ catId, state })
          this.loadTableData()
          this.$message.success('操作成功')
        } catch (e) {
          this.$message.error('操作失败')
        }
      },
      openAddDialog(row) {
        this.$refs.addDialog.open(row)
      },
        openSortDialog(row) {
            this.$refs.sortDialog.open(row)
        },
        openImgDialog(row) {
            this.$refs.imgDialog.open(row)
        },
      async deleteCatalog({ catId }) {
        await this.$confirm(`确定删除？`, '提示', { type: 'warning' });
        try {
          await deleteCatalog({ catId })
          this.loadTableData()
          this.$message.success('删除成功')
        } catch (e) {
          this.$message.error('删除失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-catalog__list {

  }
</style>
