import axios from './base'

export const getBillList = ({ page, size, orderSn, payStatus, userId, orgId, startCreatedDate, endCreatedDate, sortProp, sortType, startPayTime, endPayTime }) => {
  return axios.get('bill/list', {
    params: { page, size, orderSn, payStatus, userId, orgId, startCreatedDate, endCreatedDate, sortProp, sortType, startPayTime, endPayTime }
  })
}
export const downloadBill =({
  page,
  size,
  orderSn,
  payStatus,
  userId,
  orgId,
  startCreatedDate,
  endCreatedDate,
  sortProp,
  sortType,
  startPayTime,
  endPayTime
}) => {
  return axios.post('bill/download', {
    page, size, orderSn, payStatus, userId, orgId, startCreatedDate, endCreatedDate, sortProp, sortType, startPayTime, endPayTime
  }, {
    responseType: 'blob'
  })
}

