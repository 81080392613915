import axios from './base'

export const positionList = ({company_id} = {}) => {
  return axios.get('position/list', {
    params: {companyId: company_id}
  })
}

export const editPosition = ({ id, title, dec, state, type }) => {
  return axios.post('position/save', {
    id, title, dec, state, type
  })
}

export const positionSet = ({company_id, user_id, position_id, goods_id} = {}) => {
  return axios.post('position/setGoods', {
    company_id,
    user_id,
    position_id,
    goods_id
  })
}

export const positionDelGood = ({id} = {}) => {
  return axios.post('position/delGood', {
    id,
  })
}

export const positionDetailList = ({ company_id, userId } = {}) => {
  return axios.get('position/positionList', {
    params: {
      company_id,
      userId
    }
  })
}
export const aysnPage = ({type,skuIds} = {}) => {
    return axios.post('position/aysnPage', {
        type,
        skuIds
    })
}


