<template>
  <div class="page-user__recharge-list">
    <h2>充值卡</h2>
    <el-button type="primary" @click="openAddDialog">生成充值卡</el-button>
    <el-button type="primary" @click="openAddBatchDialog">批量生成充值卡</el-button>
    <el-button type="primary" @click="openRechargeBatchDialog">批量充值</el-button>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="状态">
        <el-select v-model="formData.state" placeholder="请选择" @change="search" clearable>
          <el-option
                  v-for="(item, index) in stateOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="充值码">
        <el-input v-model="formData.code" placeholder="请输入充值码" clearable />
      </el-form-item>
      <el-form-item label="绑定用户">
        <el-input v-model="formData.bindUserMobile" placeholder="已绑定的用户手机号" clearable />
      </el-form-item>
      <el-form-item label="使用用户">
        <el-input v-model="formData.useUserMobile" placeholder="已使用的用户手机号" clearable />
      </el-form-item>
      <el-form-item label="公司">
        <el-select v-model="formData.uesUserCompany" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
                v-model="formData.createdDate"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item style="margin-left: 20px;">
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="danger" @click="downloadRechargeList">下载</el-button>
      </el-form-item>
    </el-form>
    <op-table
            style="margin-top: 20px;"
            size="small"
            :data="table.data"
            :cols="table.cols"
            :pagination="table.pagination" />
    <add-dialog ref="AddDialog" @refresh="loadTableData" />
    <add-batch-dialog ref="AddBatchDialog" @refresh="loadTableData" />
    <recharge-batch-dialog ref="rechargeBatchDialog" @refresh="loadTableData" />
  </div>
</template>

<script>
    import dayjs from 'dayjs'
    import AddDialog from './add'
    import AddBatchDialog from './add_batch'
    import RechargeBatchDialog from './recharge_batch.vue'
    import { getCompanyList } from '@/api/company'
    import {
        getRechargeCardList,
        sendRechargeCode,
        cancelRechargeCode,
        downloadRechargeList
    } from '@/api/recharge'

    export default {
        name: 'list',
        components: {
            AddDialog,
            AddBatchDialog,
            RechargeBatchDialog
        },
        data() {
            return {
                // 充值卡状态，未使用 0，已使用 1，已取消 2
                stateOptions: [
                    {
                        value: 0,
                        label: '未使用'
                    },
                    {
                        value: 1,
                        label: '已使用'
                    },
                    {
                        value: 2,
                        label: '已取消'
                    }
                ],
                formData: {
                    state: '',
                    code: '',
                    bindUserMobile: '',
                    useUserMobile: '',
                    uesUserCompany: '',
                    createdDate: []
                },
                companyList: [],
                table: {
                    data: [],
                    cols: [
                        {
                            label: 'ID',
                            width: 50,
                            prop: 'id'
                        },
                        {
                            label: '充值码',
                            width: 140,
                            render: ({ row }) => {
                                return (
                                    <div style="font-weight: bold; font-size: 14px;">{ row.code }</div>
                            )
                            }
                        },
                        {
                            label: '金额',
                            width: 80,
                            render: ({ row }) => {
                                return (
                                    <div>{ this.$options.filters.currency(row.price) }</div>
                            )
                            }
                        },
                        {
                            label: '绑定用户',
                            prop: 'bind_user_mobile'
                        },
                        {
                            label: '状态',
                            // width: 80,
                            render: ({ row }) => {
                                const map = {
                                    0: '未使用',
                                    1: '已使用',
                                    2: '已取消'
                                }
                                return (
                                    <div>
                                    {map[row.state]}
                                {
                                    row.state === 1 &&
                                    <el-tooltip className="item" effect="dark" placement="top-start">
                                    <div slot="content">
                                    使用时间：{row.use_time}
                                </div>
                                <i style="color: red;" class="el-icon-warning"/>
                                    </el-tooltip>
                                }
                                {
                                    row.state === 1 &&
                                    <div>
                                    <div>{row.use_user_mobile}</div>
                                <div>{row.use_user_company_name}</div>
                                </div>
                                }
                            </div>
                            )
                            }
                        },
                        {
                            label: '发放状态',
                            // width: 80,
                            render: ({ row }) => {
                                const map = {
                                    0: '未发放',
                                    1: '已短信发放',
                                    2: '已文件发放'
                                }
                                return (
                                    <div>
                                    { map[row.send_state] }
                                {
                                    row.send_state !== 0 &&
                                    <el-tooltip className="item" effect="dark" placement="top-start">
                                    <div slot="content">
                                    发放时间：{row.send_time}
                                </div>
                                <i style="color: red;" class="el-icon-warning"/>
                                    </el-tooltip>
                                }
                            </div>
                            )
                            }
                        },
                        {
                            label: '创建者',
                            width: 60,
                            prop: 'creator_name'
                        },
                        {
                            label: '创建时间',
                            prop: 'create_time'
                        },
                        {
                            label: '截止时间',
                            prop: 'deadline'
                        },
                        {
                            label: '备注',
                            prop: 'remark'
                        },
                        {
                            label: '操作',
                            fixed: 'right',
                            width: 150,
                            render: ({ row }) => {
                                // 未使用、未发放且绑定了用户才能发送
                                return (
                                    <div>
                                    {
                                        row.state === 0 && row.send_state === 0 && row.bind_user_mobile &&
                                    <el-button type="primary" size="small" onClick={() => this.sendRecharge(row)}>发送</el-button>
                            }
                                {
                                    row.state === 0 &&
                                    <el-button type="danger" size="small" onClick={() => this.cancelRecharge(row)}>取消</el-button>
                                }
                            </div>
                            )
                            }
                        }
                    ],
                    pagination: {
                        total: 0,
                        pageSize: 20,
                        currentPage: 1,
                        pageSizes: [20, 40, 60, 80],
                        layout: 'total, sizes, prev, pager, next, jumper',
                        on: {
                            'current-change': (currentPage) => {
                                this.table.pagination.currentPage = currentPage
                                this.loadTableData()
                            },
                            'size-change': (size) => {
                                this.table.pagination.pageSize = size
                                this.loadTableData()
                            }
                        }
                    }
                }
            }
        },
        created() {
            this.search()
            this.getCompanyList()
        },
        methods: {
            search() {
                this.table.pagination.currentPage = 1
                this.loadTableData()
            },
            async getCompanyList() {
                const { data } = await getCompanyList({ page: 1, size: 5000 })
                this.companyList = data
            },
            async loadTableData() {
                const { currentPage: page, pageSize: size } = this.table.pagination
                const {
                    state,
                    code,
                    bindUserMobile,
                    useUserMobile,
                    uesUserCompany,
                    createdDate
                } = this.formData
                const { data, count } = await getRechargeCardList({
                    page,
                    size,
                    state,
                    code,
                    bindUserMobile,
                    useUserMobile,
                    uesUserCompany,
                    createdBeginDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD'),
                    createdEndDate: createdDate && createdDate[1] && dayjs(createdDate[1]).add(1, 'day').format('YYYY-MM-DD')
                })
                this.table.data = data
                this.table.pagination.total = count
            },
            async sendRecharge({ id }) {
                await this.$confirm('确认发送该充值码给用户？', '提示', { type: 'warning' })
                try {
                    await sendRechargeCode({ id })
                    this.loadTableData()
                    this.$message.success('发送成功')
                } catch (e) {
                    this.$message.error('发送失败')
                }
            },
            async cancelRecharge({ id }) {
                await this.$confirm('确认取消该充值卡？', '提示', { type: 'warning' })
                try {
                    await cancelRechargeCode({ id })
                    this.loadTableData()
                    this.$message.success('取消成功')
                } catch (e) {
                    this.$message.error('取消失败')
                }
            },
            openAddDialog() {
                this.$refs.AddDialog.open()
            },
            openAddBatchDialog() {
                this.$refs.AddBatchDialog.open()
            },
            openRechargeBatchDialog() {
                this.$refs.rechargeBatchDialog.open()
            },
            async downloadRechargeList() {
                const {
                    state,
                    code,
                    bindUserMobile,
                    useUserMobile,
                    uesUserCompany,
                    createdDate
                } = this.formData
                const data = await downloadRechargeList({
                    state,
                    code,
                    bindUserMobile,
                    useUserMobile,
                    uesUserCompany,
                    createdBeginDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD'),
                    createdEndDate: createdDate && createdDate[1] && dayjs(createdDate[1]).add(1, 'day').format('YYYY-MM-DD')
                })
                const url = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', `充值卡数据-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
                document.body.appendChild(link)
                link.click()
            },
        }
    }
</script>

<style lang="scss" scoped>
  .page-user__recharge-list {
    .demo-form-inline {
      margin-top: 20px;
    }
    .el-form-item {
      min-width: 25%;
      margin-right: 0;
    }
  }
</style>
