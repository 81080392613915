<template>
  <div class="page-jd-invoice__list">
    <h2>发票申请列表</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="第三方申请单号">
        <el-input style="width: 150px;" v-model="formData.markId" placeholder="请输入" clearable></el-input>
          或
        <el-select v-model="formData.markId" placeholder="请选择" clearable filterable>
          <el-option
                  v-for="(item, index) in markIdOptions"
                  :key="index"
                  :label="item.mark_id"
                  :value="item.mark_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="cancelApply">取消申请</el-button>
      </el-form-item>
    </el-form>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
    />
  </div>
</template>

<script>
  import { getJdInvoiceList, cancelJdInvoiceApply, getJdInvoiceApplyList } from '@/api/jd_invoice'

  export default {
    name: 'list',
    data() {
      return {
        markIdOptions: [],
        formData: {
          markId: ''
        },
        table: {
          data: [],
          cols: [
            {
              prop: 'invoiceId',
              label: '发票号码'
            },
            {
              prop: 'invoiceCode',
              label: '发票代码'
            },
            {
              prop: 'invoiceDate',
              label: '发票日期'
            },
            {
              prop: 'invoiceNakedAmount',
              label: '发票金额（裸价）（专票有值）'
            },
            {
              prop: 'invoiceTaxRate',
              label: '发票税率（专票有值）'
            },
            {
              prop: 'invoiceTaxAmount',
              label: '发票税额（专票有值）'
            },
            {
              prop: 'invoiceAmount',
              label: '价税合计'
            },
            {
              label: '开票状态',
              render: ({ row }) => {
                const { success } = row
                return (
                  <div>{ success ? '已开票' : '' }</div>
                )
              }
            },
            {
              label: '发票类型',
              render: ({ row }) => {
                const { invoiceType } = row
                const map = {
                  1: '普票',
                  2: '专票'
                }
                return (
                  <div>{map[invoiceType]}</div>
                )
              }
            // },
            // {
            //   label: '操作',
            //   render: ({ row }) => {
            //     return (
            //       <div>
            //         <router-link to={{ name: 'jdSkuList', query: { name: row.name, page_num: row.page_num } }}>
            //           <el-button type="primary" size="small">查看池内商品</el-button>
            //         </router-link>
            //       </div>
            //   );
            //   }
            }
          ]
        }
      }
    },
    created() {
      this.getJdInvoiceApplyList()
    },
    methods: {
      async getJdInvoiceApplyList() {
        this.markIdOptions = await getJdInvoiceApplyList()
      },
      async loadTableData() {
        const { markId } = this.formData;
        if (!markId) {
          return this.$message.error('第三方申请单号不能为空')
        }
        try {
          const { data } = await getJdInvoiceList({ markId })
          this.table.data = data
        } catch (e) {
          this.$message.error(e)
        }
      },
      async cancelApply() {
        const { markId } = this.formData;
        if (!markId) {
          return this.$message.error('第三方申请单号不能为空')
        }
        try {
          const result = await cancelJdInvoiceApply({ markId })
          if (result) {
            this.$message.success('取消成功')
          }
        } catch (e) {
          this.$message.error(e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-jd-invoice__list {
    .async-btn {
      margin-bottom: 20px;
    }
  }
</style>
