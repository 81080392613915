import axios from './base'

export const getAdminList = ({page, size}) => {
  return axios.get('admin/list', {
    params: {page, size}
  })
}

export const addAdminAccount = ({
                                  username,
                                  password,
                                  adminRoleId,
                                  nickname
                                }) => {
  return axios.post('admin/add', {
    username,
    password,
    adminRoleId,
    nickname
  })
}

export const changePassword = ({id, password}) => {
  return axios.post('admin/password', {
    id,
    password
  })
}

export const deleteAdminAccount = ({id}) => {
  return axios.post('admin/delete', {
    id
  })
}

export const adminRoleList = () => {
  return axios.get('admin/roleList')
}

export const setRoleAuth = ({roleId, menusIds, type} = {}) => {
  return axios.post('admin/setRoleAuth', {
    menusIds,
    roleId,
    type
  })
}

export const getRoleAuthChecked = ({ roleId, type } = {}) => {
  return axios.get('admin/getRoleAuthChecked', {
    params: {
      roleId, type
    }
  })
}

export const addAdminRole = ({role_name} = {}) => {
  return axios.post('admin/addAdminRole', {
    role_name
  })
}

export const getAuthByMenu = ({ roleId } = {}) => {
  return axios.get('admin/getAuthByMenu', {
    params: {
      roleId
    }
  })
}

export const setAdminOrg = ({id, info_id} = {}) => {
  return axios.post('admin/setOrg', {
    id,
    info_id
  })
}



