export default {
  jd: {
    // n0 带有京东水印，其余的 n1-n4 不带，12 大图无水印的
    imageHostN0: '//img13.360buyimg.com/n0/', // 最大图 800*800px
    imageHostN12: '//img13.360buyimg.com/n12/', // 最大图 800*800px
    imageHostN1: '//img13.360buyimg.com/n1/', // 350*350px
    imageHostN2: '//img13.360buyimg.com/n2/', // 160*160px
    imageHostN3: '//img13.360buyimg.com/n3/', // 130*130px
    imageHostN4: '//img13.360buyimg.com/n4/' // 100*100px
  },
  wechat: {
    oauthUrl: 'https://open.weixin.qq.com/connect/oauth2/authorize',
    oauthQuery: {
      appid: 'wxf5e47963aac1ea25',
      redirect_uri: encodeURIComponent('https://708c6641.ngrok.io/api/auth/loginUp'),
      response_type: 'code',
      scope: 'snsapi_base'
    }
  }
}
