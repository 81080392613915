<template>
  <div class="page-order__list">
    <h2>退款单</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="第三方订单号">
        <el-input v-model="formData.orderSn" placeholder="请输入" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
      </el-form-item>
    </el-form>
    <op-table
            size="small"
            :data="table.data"
            :cols="table.cols"
            :pagination="table.pagination"
    />
    <order-dialog ref="OrderDialog"></order-dialog>
  </div>
</template>

<script>
  import { getRefundList, confirmRefund, rejectRefund } from '@/api/refund'
  import OrderDialog from '../../bill/order/order'

  export default {
    name: 'refundList',
    components: {
      OrderDialog
    },
    data() {
      return {
        formData: {
          orderSn: '',
        },
        table: {
          data: [],
          cols: [
            // {
            //   type: 'expand',
            //   render: ({ row }) => {
            //     const { goodsInfo } = row;
            //     if (goodsInfo) {
            //       return (
            //         <el-form labelPosition="left" inline class="table-expand">
            //           <el-form-item label="订单ID：">
            //           <span>{goodsInfo.order_id}</span>
            //           </el-form-item>
            //           <el-form-item label="商品ID：">
            //           <span>{goodsInfo.goods_id}</span>
            //           </el-form-item>
            //           <el-form-item label="商品名称：">
            //           <span>{goodsInfo.goods_name}</span>
            //           </el-form-item>
            //           <el-form-item label="商品价格：">
            //           <span>{this.$options.filters.currency(goodsInfo.retail_price)}</span>
            //           </el-form-item>
            //         </el-form>)
            //     }
            //   }
            // },
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              label: '第三方订单号',
              width: 180,
              render: ({ row }) => {
                return (
                  <div class="clicked" onClick={() => this.openOrderDialog(row)}>{row.order_sn}</div>
                )
              }
            },
            {
              label: '退款金额（元）',
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.refund_amount) }</div>
                )
              }
            },
            {
              label: '退款状态',
              width: 100,
              prop: 'refund_status_label',
            },
            {
              label: '账单ID',
              prop: 'bill_id',
            },
            {
              label: '创建人',
              prop: 'username'
            },
            {
              label: '创建时间',
              prop: 'create_time'
            },
            {
              label: '更新时间',
              prop: 'update_time'
            },
            {
              label: '操作',
              width: 200,
              render: ({ row }) => {
                return (
                <div class="operation">
                {
                  row.refund_status === 1 && <el-button size="small" type="danger" onClick={() => this.commitRefund(row.id)}>确认退款</el-button>
                }
                {
                  row.refund_status === 1 && <el-button size="small" type="danger" onClick={() => this.rejectRefund(row.id)}>拒绝退款</el-button>
                }
                </div>
              )
              }
            },
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        },
      }
    },
    created() {
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const {
          orderSn,
        } = this.formData
        const { data, count } = await getRefundList({ page, size, orderSn })
        console.log(data)
        this.table.data = data
        this.table.pagination.total = count
      },
      async commitRefund(id) {
        await this.$confirm('确定确认退款？', '提示', { type: 'warning' })
        await confirmRefund({ id })
        this.loadTableData()
      },
      async rejectRefund(id) {
        await this.$confirm('确定拒绝退款？', '提示', { type: 'warning' })
        await rejectRefund({ id })
        this.loadTableData()
      },
      openOrderDialog({ order_sn: orderSn }) {
        this.$refs.OrderDialog.open({ orderSn })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-order__list {

  }
</style>
