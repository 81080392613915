<template>
  <el-dialog
    title="批量充值"
    v-loading="loading"
    custom-class="page-user__recharge-batch"
    :visible.sync="dialogVisible"
  >
    <el-form
      ref="formData"
      :model="formData"
      class="demo-form-inline"
      label-width="150px"
    >
      <el-form-item label="" prop="avatar">
        <el-upload
          v-model="formData.avatar"
          class="recharge-uploader"
          action="fake"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :http-request="
            ({ file }) => {
              this.uploadBatchRecharge(file, 'fileUrl');
            }
          "
          :show-file-list="true"
          :file-list="fileList"
          :before-upload="beforeUpload"
        >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>

        <a
          :style="{ marginTop: '15px' }"
          href="/file/充值卡模板.xlsx"
          @click="download"
          >模版下载</a
        >
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onDiffSubmit">立即生成</el-button>
      <el-button @click="dialogVisible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import axios from "@/api/base";
import dayjs from "dayjs";
import { createBatchDifPriceRecharge } from "@/api/recharge";
// import {downloadTemplate} from "@/api/recharge";
export default {
  name: "add",
  data() {
    return {
      dialogVisible: false,
      formData: {
        fileUrl: "",
      },
      fileList: [],
      loading: false,
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.formData.resetFields();
      });
    },
    async uploadBatchRecharge(file, itemName) {
      console.log(file, itemName);
      try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        const url = await axios.post("/file/upload", formData);
        this.$message.success("上传成功");

        this.formData[itemName] = url;
      } catch (e) {
        this.$message.error(e);
      }
    },
    beforeUpload(file) {
      console.log(file.type);
      // const isJPG = file.type === 'image/jpeg'||'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      /* if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }*/
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M; //isJPG && isLt2M;
    },
    async download() {
      // const data = await downloadTemplate();
      // const url = window.URL.createObjectURL(
      //   new Blob([data], {
      //     type: "application/vnd.ms-excel",
      //   })
      // );
      // const link = document.createElement("a");
      // link.style.display = "none";
      // link.href = url;
      // link.setAttribute(
      //   "download",
      //   `批量充值模版-${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`
      // );
      // document.body.appendChild(link);
      // link.click();
    },

    async onDiffSubmit() {
      this.loading = true;
      if (!this.formData.fileUrl) {
        return;
      }
      const { fileUrl } = this.formData;
      try {
        const data = await createBatchDifPriceRecharge({
          fileUrl,
        });
        this.genFile(data, "批量不同面额充值卡");
        this.$message.success("生成成功");
        this.dialogVisible = false;
        this.fileList = [];
        this.loading = false;
        this.$emit("refresh");
      } catch (e) {
        this.$message.error("生成失败");
        this.loading = false;
      }
    },
    genFile(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute(
        "download",
        `${filename}-${dayjs().format("YYYY-MM-DD HH:mm:ss")}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss">
.page-user__recharge-batch {
  .el-input {
    width: 260px;
  }
}
</style>
