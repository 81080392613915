<template>
  <div class="page-sku__list">
    <div class="title">
      当前商品池编号：{{formData.pageNum + (pageNumName ? `(${pageNumName})`: '')}}
    </div>

<!--    <el-button @click="asyncPageNum" type="primary" class="async-btn">同步所有商品 SKU</el-button>-->
    <!--    <span>已同步{{table.pagination.total}}条记录</span>-->
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="商品池">
        <el-select v-model="formData.pageNum" placeholder="请选择" @change="changePageNum" clearable>
          <el-option
            v-for="item in pageNumOptions"
            :key="item.page_num"
            :label="item.name"
            :value="item.page_num">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品 SKU">
        <el-input v-model="formData.sku" placeholder="商品 SKU" clearable @change="changeSku"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
      </el-form-item>
    </el-form>

    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </div>
</template>

<script>
  import { getPageNum, getSkuList, syncSku } from '@/api/page_num'

  export default {
    name: 'list',
    data() {
      return {
        pageNum: '',
        pageNumName: '',
        formData: {
          pageNum: '',
          sku: ''
        },
        pageNumOptions: [],
        table: {
          data: [],
          cols: [
            {
              prop: 'sku',
              label: '商品sku编号'
            },
            {
              prop: 'name',
              label: '商品sku名称'
            },
            {
              label: '商品图片',
              render: ({ row }) => {
                return (
                  <img style="width: 100px;" src={row.imgUrl.split(';')[0]} />
                );
              }
            },
            {
              prop: 'productArea',
              label: '产地'
            },
            {
              prop: 'wareQD',
              label: '包装清单'
            },
            {
              prop: 'brandName',
              label: '品牌名称'
            },
            {
              label: '企业购价格（元）',
              render: ({ row }) => {
                return (
                  <div style="color: red; font-weight: bold;">
                    {row.price && this.$options.filters.currency(row.price)}
                  </div>
                )
              }
            },
            {
              label: '京东价格（元）',
              render: ({ row }) => {
                return <div>{row.jd_price && this.$options.filters.currency(row.jd_price)}</div>
              }
            },
              {
                  label: '企业购价/京东价',
                  render: ({ row }) => {
                      return <div>{(row.price/row.jd_price).toFixed(2)*100}%</div>
                  }
              },
            {
              prop: 'category',
              label: '商品分类'
            },
            {
              label: '操作',
              render: ({ row }) => {
                return (
                  <el-button type="primary" size="small" onClick={() => this.syncSku(row)}>同步</el-button>
                );
              }
            }
          ],
          pagination:{
              total: 0,
              pageSize: 20,
              currentPage: 1,
              pageSizes: [20, 40, 60, 80],
              layout: 'total, sizes, prev, pager, next, jumper',
              on: {
                  'current-change': (currentPage) => {
                      this.table.pagination.currentPage = currentPage
                      this.loadTableData()
                  },
                  'size-change': (size) => {
                      this.table.pagination.pageSize = size
                      this.loadTableData()
                  }
              }
          }
        }
      }
    },
    async created() {
      const { data } = await getPageNum({ page: 1, size: 200 });
      this.pageNumOptions = data
      this.formData.pageNum = this.$route.query.page_num
      this.pageNumName = this.$route.query.name
      this.loadTableData()
    },
    methods: {
      changeSku() {
        this.formData.pageNum = ''
        this.pageNumName = ''
      },
      changePageNum() {
        this.formData.sku = ''
        this.loadTableData()
      },
      async loadTableData() {
        const { pageNum, sku } = this.formData
        const {  currentPage: page,pageSize: size } = this.table.pagination
        const { result, total } = await getSkuList({ pageNum, sku, pageNo: page, size })
        this.table.data = result
        this.table.pagination.total = total
      },
      async syncSku({ sku }) {
        try {
          await syncSku({ sku })
          this.$message.success('同步成功')
        } catch (e) {
          this.$message.error(e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-sku__list {
    .title {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
</style>
