var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":"修改账号密码","visible":_vm.dialogVisible,"width":"50%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"page-admin__password"},[_c('el-form',{ref:"form",attrs:{"model":_vm.formData,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"角色","prop":"role"}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":""},model:{value:(_vm.formData.admin_role_id),callback:function ($$v) {_vm.$set(_vm.formData, "admin_role_id", $$v)},expression:"formData.admin_role_id"}},_vm._l(([
            {
              label: '超级管理员',
              value: 1
            },
            {
              label: '运营人员',
              value: 0
            }
          ]),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"账号","prop":"username","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})],1),_c('el-form-item',{attrs:{"label":"密码","prop":"password","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"type":"password"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1),_c('el-form-item',{attrs:{"label":"密码确认","prop":"confirm_password","rules":[
        {
          required: true,
          message: '不能为空'
        }
      ]}},[_c('el-input',{attrs:{"type":"password"},model:{value:(_vm.formData.confirm_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_password", $$v)},expression:"formData.confirm_password"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }