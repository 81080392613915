<template>
  <el-dialog
          :title="`${text}账号`"
          :visible.sync="dialogVisible"
          width="50%">
    <div class="page-company-user__add">
      <el-form :model="formData" ref="form" label-width="100px">


        <el-form-item label="标题" prop="name" :rules="rules">
          <el-input v-model="formData.name" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="href">
          <el-input v-model="formData.href" maxlength="1000"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="title" :rules="rules">
          <el-input v-model="formData.title" type="textarea" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {saveGongGao} from '@/api/gonggao'

export default {
  name: 'upsert',

  data() {
    return {
      isEditing: false,
      dialogVisible: false,
      rules: [{required: true, message: '不能为空'}],
      formData: {
        id: null,
        name: '',
        title: '',
        href: '',
      },
      companyList: [],
    };
  },
  computed: {
    text() {
      return this.isEditing ? '修改' : '新建'
    }
  },
  methods: {
    open(userInfo, companyList) {
      this.dialogVisible = true
      this.isEditing = !!userInfo.id
      this.companyList = companyList
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      if (!this.isEditing) {
        this.formData = {
          id: null,
          name: '',
          title: '',
          href: '',
        }
        return
      }
      this.formData = userInfo
    },
    close() {
      this.dialogVisible = false
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const {
        id,
        name,
        title,
        href
      } = this.formData;
      try {
        await saveGongGao({
          id,
          name,
          title,
          href
        });
        this.$message.success(`${this.text}成功`);
        this.dialogVisible = false
        this.$emit('refresh');
      } catch (e) {
        this.$message.error(`${this.text}失败：${e}`);
      }
    },
    cancel() {
      this.dialogVisible = false
    }
  }
};
</script>

<style lang="scss">
.page-company-user__add {
  .el-form {
    max-width: 660px;

    .el-select .el-input {
      max-width: 360px;
    }

    .line {
      text-align: center;
    }

    .el-radio {
      margin-bottom: 20px;
    }
  }
}
</style>
