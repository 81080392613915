var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"page-user__pickup-add",attrs:{"title":"生成提货卡","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"formData",staticClass:"demo-form-inline",attrs:{"model":_vm.formData,"label-width":"150px"}},[_c('el-form-item',{attrs:{"prop":"price","label":"提货卡金额 (元)","rules":[
      { required: true, message: '请填写提货卡金额' }
    ]}},[_c('el-input',{attrs:{"type":"number","placeholder":"请输入"},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1),_c('el-form-item',{attrs:{"prop":"skus","label":"商品sku编号"}},[_c('el-input',{attrs:{"placeholder":"请输入商品sku，多个中间，隔开"},model:{value:(_vm.formData.skus),callback:function ($$v) {_vm.$set(_vm.formData, "skus", $$v)},expression:"formData.skus"}})],1),_c('el-form-item',{attrs:{"prop":"number","label":"数量"}},[_c('el-input-number',{attrs:{"disabled":"","min":1,"max":10,"label":"描述文字"},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})],1),_c('el-form-item',{attrs:{"prop":"deadline","label":"截止日期","rules":[
      { required: true, message: '请选择截止日期' }
    ]}},[_c('el-date-picker',{attrs:{"clearable":false,"placeholder":"请选择截止日期"},model:{value:(_vm.formData.deadline),callback:function ($$v) {_vm.$set(_vm.formData, "deadline", $$v)},expression:"formData.deadline"}})],1),_c('el-form-item',{attrs:{"prop":"mobile","label":"用户手机","rules":[
      { required: true, message: '请选择用户手机' }
    ]}},[_c('el-select',{staticStyle:{"width":"250px"},attrs:{"filterable":"","placeholder":"请选择"},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}},_vm._l((_vm.userOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":((item.mobile) + " (" + (item.nickname) + ")"),"value":item.mobile}})}),1)],1),_c('el-form-item',{attrs:{"prop":"companyId","label":"绑定公司","rules":[
      { required: true, message: '请选择公司' }
    ]}},[_c('el-select',{staticStyle:{"width":"250px"},attrs:{"filterable":"","placeholder":"请选择"},model:{value:(_vm.formData.companyId),callback:function ($$v) {_vm.$set(_vm.formData, "companyId", $$v)},expression:"formData.companyId"}},_vm._l((_vm.companyOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":((item.id) + " (" + (item.name) + ")"),"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"prop":"categoryId","label":"绑定三级类目"}},[_c('el-select',{staticStyle:{"width":"350px"},attrs:{"filterable":"","multiple":"","placeholder":"请选择"},model:{value:(_vm.formData.categoryId),callback:function ($$v) {_vm.$set(_vm.formData, "categoryId", $$v)},expression:"formData.categoryId"}},_vm._l((_vm.categoryOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":((item.catId) + " (" + (item.name) + ")"),"value":item.catId}})}),1)],1),_c('el-form-item',{attrs:{"prop":"orgId","label":"绑定供应商","rules":[
      { required: true, message: '请选择供应商' }
    ]}},[_c('el-select',{staticStyle:{"width":"250px"},attrs:{"filterable":"","placeholder":"请选择"},model:{value:(_vm.formData.orgId),callback:function ($$v) {_vm.$set(_vm.formData, "orgId", $$v)},expression:"formData.orgId"}},_vm._l((_vm.orgOptions),function(item,index){return _c('el-option',{key:index,attrs:{"label":((item.id) + " (" + (item.org_name) + ")"),"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"prop":"remark","label":"备注"}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入备注"},model:{value:(_vm.formData.remark),callback:function ($$v) {_vm.$set(_vm.formData, "remark", $$v)},expression:"formData.remark"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("立即生成")]),_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }