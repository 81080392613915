import axios from './base'

export const getGongGaoList = ({ page = 1, size = 20 } = {}) => {
  return axios.get('gonggao/list', {
    params: { page, size }
  })
}

export const gongGaoDelete = ({ id }) => {
  return axios.post('gonggao/delete', {
    id
  })
}

export const saveGongGao = ({
                                 id,
                                 name,
                                 title,
                                 href,
}) => {
  return axios.post('gonggao/save', {
    id,
    name,
    title,
    href,
  })
}

