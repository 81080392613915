import axios from './base'

export const getCompanyList = ({page, size, type, name}) => {
  return axios.get('company/list', {
    params: {page, size, type, name}
  })
}

export const getCompanyDetail = ({id}) => {
  return axios.get('company/detail', {
    params: {id}
  });
};

export const getFloorList = ({companyId}) => {
  return axios.get('floor/list', {
    params: {companyId}
  });
};

export const deleteFloor = ({id}) => {
  return axios.post('floor/delete', {id})
};

export const saveFloor = ({id = null, companyId, type, picUrl, actionType, catId, first, second, sku, skuList}) => {
  return axios.post('floor/save', {id, companyId, type, picUrl, actionType, catId, first, second, sku, skuList})
};

export const upsertCompany = data => {
  return axios.post('company/upsert', data);
};

export const submitCompanyCategory = data => {
  return axios.post('company/submitCompanyCategory', data);
};

export const submitallCompanyCategory = data => {
  return axios.post('company/submitallCompanyCategory', data);
};
export const getCompanyCategoryChecked = ({companyId}) => {
  return axios.get('company/getCompanyCategoryChecked', {
    params: {companyId}
  });
};

export const loadCompanyCategoryNode = ({companyId}) => {
  return axios.get('company/companyCurrentCategory', {
    params: {companyId}
  });
};

export const getCompanyPositionCategoryChecked = ({companyId}) => {
  return axios.get('company/getCompanyPositionCategoryChecked', {
    params: {companyId}
  });
};

export const submitCompanyPositionCategory = ({companyId, categoryIds}) => {
  return axios.post('company/submitCompanyPositionCategory', {
    companyId,
    categoryIds
  });
};


export const saveCompanyPriceRate = ({companyId, rate}) => {
  return axios.post('company/saveCompanyPriceRate', {companyId, rate})
}

export const getCompanyPriceRate = ({companyId}) => {
  return axios.get('company/getCompanyPriceRate', {
    params: {companyId}
  })
}
