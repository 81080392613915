<template>
  <div class="page-goods__list">
    <h2>商户商品列表</h2>
    <el-button
            v-if="isAdmin"
            type="danger"
            style="margin-bottom: 20px;"
            @click="openSyncPriceDialog">设置价格同步比例
    </el-button>
    <el-button
            v-if="isAdmin"
            type="danger"
            style="margin-bottom: 20px;"
            @click="openAddBatchDialog">批量改价
    </el-button>
    <op-goods-list ref="OpGoodsList">
      <el-table-column
              label="操作"
              width="250">
        <template slot-scope="{ row }">
          <div class="operation">
            <template v-if="row.state === 1">
              <el-button type="danger" size="small" @click="changeGoodsState(row, 0)">下架</el-button>
              <el-button type="primary" size="small" @click="openChangePriceDialog(row)">改价</el-button>
            </template>
            <el-button v-if="row.state === 0" type="primary" size="small" @click="changeGoodsState(row, 1)">上架
            </el-button>
            <el-button v-if="row.org_id !== 1" size="small" @click="editGoods(row)">修改商品</el-button>
          </div>
        </template>
      </el-table-column>
    </op-goods-list>
    <el-dialog
            title="修改售卖价"
            width="30%"
            :visible.sync="isChangePriceDialogShown">
      <div>
        <el-form :inline="true" :model="changePriceRow" class="demo-form-inline" label-width="150px">
          <el-form-item label="售卖价(元)">
            <el-input v-model="changePriceRow.retail_price" type="number" placeholder="售卖价"/>
            <div class="price-tip" v-if="changePriceRow.retail_price >= changePriceRow.jd_price">售卖价高于等于京东价</div>
          </el-form-item>
          <el-form-item label="利润(元)">
            <div style="color: red; font-weight: bold;">
              {{ changePriceRow.retail_price - changePriceRow.price | currency }}
            </div>
          </el-form-item>
          <el-form-item label="企业购价 (京东价)(元)">
            <div>
              <span style="font-weight: bold;">
               {{ changePriceRow.price | currency }}
              </span>(<span>{{ changePriceRow.jd_price | currency }}</span>)
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isChangePriceDialogShown = false">取 消</el-button>
        <el-button type="primary" @click="changePrice">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
            title="设置价格同步比例"
            width="30%"
            :visible.sync="isSyncPriceDialogShown">
      <div>
        <el-form :model="changePriceRow" class="demo-form-inline" label-width="150px">
          <el-form-item label="价格同步比例">
            <el-input v-model="syncPriceRate" type="number" placeholder="售卖价"/>
          </el-form-item>
          <div class="price-tip">
            <div>价格同步比例：</div>
            <div>1. 每天 0 点 30 分价格同步脚本会执行，该脚本会根据京东侧的价格更新商品的价格。</div>
            <div>2. 商品的售卖价会根据京东侧的企业购价 * 同步比例（如 1.25）设置。</div>
          </div>
          <!--          <el-form-item label="企业购价 (京东价)(元)">-->
          <!--            <div>-->
          <!--              <span style="font-weight: bold;">-->
          <!--               {{changePriceRow.price | currency}}-->
          <!--              </span>(<span>{{changePriceRow.jd_price | currency}}</span>)-->
          <!--            </div>-->
          <!--          </el-form-item>-->
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isSyncPriceDialogShown = false">取 消</el-button>
        <el-button type="primary" @click="changeSyncPriceRate">确 定</el-button>
      </span>
    </el-dialog>
    <add-batch-dialog ref="AddBatchDialog" @refresh="loadTableData"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {changeGoodsState, changePrice, changeSyncPriceRate, getSyncPriceRate} from '@/api/goods'
import OpGoodsList from '@/components/goods_list/goods_list'
import AddBatchDialog from './add_batch'

export default {
  name: 'list',
  components: {
    OpGoodsList, AddBatchDialog
  },
  computed: {
    ...mapGetters([
      'isAdmin'
    ]),
  },
  data() {
    return {
      isChangePriceDialogShown: false,
      isSyncPriceDialogShown: false,
      changePriceRow: {},
      syncPriceRate: 1.25,
    }
  },
  // async created() {
  //   // 获取价格同步比例
  //   this.syncPriceRate = await getSyncPriceRate()
  // },
  methods: {
    openAddBatchDialog() {
      this.$refs.AddBatchDialog.open()
    },
    async loadTableData() {
      await this.$refs.OpGoodsList.loadTableData()
    },
    async changeGoodsState(row, state) {
      const stateMsg = {
        1: '上架',
        0: '下架'
      }
      const message = stateMsg[state]
      await this.$confirm(`确定${message}？`, '提示', {type: 'warning'})
      const {sku} = row
      try {
        await changeGoodsState({sku, state})
        this.$message.success(`商品${message}成功`)
        this.loadTableData()
      } catch (e) {
        this.$message.error(`商品${message}失败`)
      }
    },
    openChangePriceDialog(row) {
      this.isChangePriceDialogShown = true
      this.changePriceRow = Object.assign({}, row)
    },
    async openSyncPriceDialog() {
      this.isSyncPriceDialogShown = true
      // 获取价格同步比例
      this.syncPriceRate = await getSyncPriceRate()
    },
    async changePrice() {
      await this.$confirm('确定改价？', '提示')
      this.isChangePriceDialogShown = false
      try {
        const {sku, retail_price: retailPrice} = this.changePriceRow
        await changePrice({
          sku, retailPrice
        })
        this.$message.success('改价成功')
        this.loadTableData()
      } catch (e) {
        this.$message.error(e)
      }
    },
    async changeSyncPriceRate() {
      await this.$confirm('这将会修改所有商品的价格，确定修改价格同步比例？', '提示')
      try {
        const {syncPriceRate} = this
        await changeSyncPriceRate({
          syncPriceRate
        })
        this.$message.success('修改价格同步比例成功')
        this.isSyncPriceDialogShown = false
        // this.loadTableData()
      } catch (e) {
        this.$message.error(e)
      }
    },
    editGoods(row) {
      this.$router.push({
        name: 'addGoods',
        query: {id: row.sku}
      })
    }
  }
}
</script>

<style lang="scss">
.page-goods__list {
  .price-tip {
    font-size: 12px;
    color: red;
  }
}
</style>
