<template>
  <div class="page-activity__add-edit">
    <h2>{{ isEditing ? '修改活动': '新建活动' }}</h2>
    <el-form :model="formData" ref="formData" label-width="100px" class="demo-formData">
      <el-form-item label="活动名称" prop="name" :rules="[{
        required: true, message: '活动名称不能为空'
      }]" >
        <el-input v-model="formData.name" placeholder="请输入活动名称"></el-input>
      </el-form-item>
      <el-form-item label="活动商品" prop="skuList" >
        <el-button type="primary" @click="openGoodsListDialog">选择商品</el-button>
        <div>共 {{formData.skuList.length}} 个商品</div>
        <op-table
          max-height="500px"
          size="small"
          :data="formData.skuList"
          :cols="skuTableCols"></op-table>
      </el-form-item>
      <el-form-item label="上线" prop="state">
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="formData.state"></el-switch>
      </el-form-item>
      <el-form-item label="图片资源" prop="image" :rules="[{
        required: true, message: '活动图片链接不能为空'
      }]" >
        <div class="upload-tips">Tips: 图片尺寸：750x360</div>
        <el-upload
          class="banner-uploader"
          :show-file-list="false"
          :http-request="uploadBanner"
          action="fake"
          accept="image/*"
        >
          <img v-if="formData.image" :src="formData.image" class="banner" />
          <i v-else class="el-icon-plus banner-uploader-icon"></i>
        </el-upload>
        <el-radio-group v-model="formData.image">
          <el-radio style="width: 200px;" :label="item.url" v-for="(item, index) in imageList" :key="index">
            <el-image :src="item.url"></el-image>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动描述" prop="desc">
        <el-input type="textarea" v-model="formData.desc"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save()">保存</el-button>
        <router-link :to="{ name: 'activityList' }">
          <el-button>取消</el-button>
        </router-link>
      </el-form-item>
    </el-form>

    <goods-list-dialog
            ref="GoodsListDialog"
            :sku-ids="selectedSkuIds"
            @select="selectSku" />
  </div>
</template>

<script>
  import axios from '@/api/base'
  import config from '@/config'
  import { saveActivity, getActivityDetail } from '@/api/activity'
  import GoodsListDialog from './activity_goods/activity_goods'

  export default {
    name: 'add_edit',
    components: {
      GoodsListDialog
    },
    computed: {
      isEditing() {
        return !!this.$route.query.id
      },
      selectedSkuIds() {
        return this.formData.skuList.map(v => v.sku)
      }
    },
    data() {
      return {
        skuTableCols: [
          {
            label: '商品 SKU',
            prop: 'sku'
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '售卖价（元）',
            prop: 'retail_price'
          },
          {
            label: '商品图片',
            render: ({ row }) => {
              console.log(row.imagePath)
              let imagePath = row.imagePath
              if(row.imagePath.indexOf('https:')<0) {
                imagePath = config.jd.imageHostN3 + row.imagePath
              }
              return (
                <el-image style="width: 100px;"
                          previewSrcList={ row.imgUrl.split(';') }
                          src={imagePath} />
              )
            }
          },
          {
            label: '操作',
            render: ({ row }) => {
              return (
                <el-button type="primary" size="small" onClick={() => this.remove(row)}>移除商品</el-button>
              )
            }
          }
        ],
        imageList: [
          {
            label: '',
            url: '/static/image/1572404935382.jpg'
          },
          {
            label: '',
            url: '/static/image/1572404967378.jpg'
          },
          {
            label: '',
            url: '/static/image/1572404985037.jpg'
          },
          {
            label: '',
            url: '/static/image/1573120507208.jpg'
          },
          {
            label: '',
            url: '/static/image/1573120560232.jpg'
          }
        ],
        formData: {
          name: '',
          skuList: [],
          state: true,
          desc: '',
          image: ''
          // type: 1
        }
      }
    },
    async mounted() {
      if (this.isEditing) {
        const { id } = this.$route.query
        this.formData = await getActivityDetail({ id })
      }
    },
    methods: {
      openGoodsListDialog() {
        this.$refs.GoodsListDialog.open();
      },
      remove(v) {
        const index = this.formData.skuList.findIndex(item => +item.sku === +v.sku)
        this.formData.skuList.splice(index, 1)
      },
      async uploadBanner({ file }) {
        try {
          const formData = new FormData();
          formData.append('file', file, file.name);
          const url = await axios.post('/file/upload2', formData);
          this.$message.success('上传成功');
          this.formData.image = url;
        } catch (e) {
          this.$message.error(e);
        }
      },
      selectSku(v, selected) {
        if (selected) {
          this.formData.skuList.push(v);
          return;
        }
        this.remove(v);
      },
      async save() {
        const result = await this.$refs.formData.validate();
        if (!result) {
          return
        }
        try {
          const {
            id,
            name,
            skuList,
            state,
            image,
            type,
            desc
          } = this.formData;
          await saveActivity({
            id,
            name,
            skuIds: skuList.map(item => item.sku),
            state: state ? 1 : 0,
            image,
            type,
            desc
          })
          this.$message.success('保存成功')
          this.$router.push({
            name: 'activityList'
          })
        } catch (e) {
          this.$message.error('创建失败：' + e)
        }
      }
    }
  }
</script>

<style lang="scss">
  .page-activity__add-edit {
    $banner-width: 550px;
    $banner-height: 240px;
    .demo-formData {
      width: 660px;
      .el-select .el-input {
        width: 360px;
      }
      .line {
        text-align: center;
      }
      .el-radio {
        margin-bottom: 20px;
      }
    }
    .banner-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: $banner-width;
      height: $banner-height;
      margin-bottom: 20px;

      &:hover {
        border-color: #409eff;
      }

      &-icon {
        font-size: 28px;
        color: #8c939d;
        width: $banner-width;
        height: $banner-height;
        line-height: $banner-height;
        text-align: center;
      }

      .banner {
        width: $banner-width;
        height: $banner-height;
        display: block;
      }
    }
    .upload-tips {
      color: #F56C6C;
      font-size: 12px;
      font-weight: bold;
    }
  }
</style>
