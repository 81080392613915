<template>
  <div class="page-admin__list">
    <h2>后台账号列表</h2>
    <el-button type="primary" @click="add">新增账号</el-button>
<!--    <el-form :inline="true" :model="formData" class="demo-form-inline">-->
<!--      <el-form-item label="公司">-->
<!--        <el-select v-model="formData.companyId" placeholder="请选择" @change="search" clearable>-->
<!--          <el-option-->
<!--            v-for="(item, index) in companyList"-->
<!--            :key="index"-->
<!--            :label="item.name"-->
<!--            :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      &lt;!&ndash;      <el-form-item label="用户 ID">&ndash;&gt;-->
<!--      &lt;!&ndash;        <el-input v-model="formData.userId" placeholder="用户 ID" clearable />&ndash;&gt;-->
<!--      &lt;!&ndash;      </el-form-item>&ndash;&gt;-->
<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="search">查询</el-button>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
    <add-edit-dialog ref="addEditDialog" @refresh="search" />
    <password-dialog ref="passwordDialog" @refresh="search" />
    <org-dialog ref="orgDialog" @refresh="search" />

  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import addEditDialog from '../add_edit/add_edit'
  import passwordDialog from '../password/password'
  import orgDialog from '../org_dialog/org_dialog'
  import { getAdminList, deleteAdminAccount } from '@/api/admin'

  export default {
    name: 'list',
    components: {
      addEditDialog,
      passwordDialog,
      orgDialog
    },
    data() {
      return {
        table: {
          data: [],
          edit:[],
          cols: [
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              label: '账号',
              prop: 'username'
            },
            {
              label: '用户昵称',
              prop: 'nickname'
            },
            {
              label: '头像',
              render: ({ row }) => {
                return (
                  <img width="50" height="50" src={row.avatar} />
                )
              }
            },
            {
              label: '账号角色',
              prop: 'role_name'
            },
            {
              label: '注册时间',
              prop: 'create_time'
            },
            {
              label: '上次登录时间',
              render: ({ row }) => {
                return (
                  <div>{ row.last_login_time ? dayjs(row.last_login_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '' }</div>
                )
              }
            },
            {
              label: '供应商',
              prop: 'org_name'
            },
            {
              label: '操作',
              width: 300,
              render: ({ row }) => {
                return (
                  <div>
                  {row.admin_role_id === 4 ? (<el-button size="small" type="primary" onClick={() =>{this.bindOrg(row)}}>绑定供应商</el-button>) :''}
                    <el-button size="small" type="primary" onClick={() => this.openPasswordDialog(row)}>修改密码</el-button>
                    <el-button size="small" type="danger" onClick={() => this.deleteAdmin(row)}>删除</el-button>
                  </div>
                )
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          },
        }
      }
    },
    mounted() {
      this.loadTableData()
    },
    methods: {
      search() {
        this.table.pagination.currentPage = 1
        this.loadTableData()
      },
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { data, count } = await getAdminList({ page, size })
        this.table.data = data
        this.table.pagination.total = count
      },
      async add() {
        this.$refs.addEditDialog.open()
      },
      async openPasswordDialog(v) {
        this.$refs.passwordDialog.open(v)
      },
      async deleteAdmin({ id, username }) {
        await this.$confirm(`确定删除 ${username} 后台管理账号？`, '提示', { type: 'warning' })
        try {
          await deleteAdminAccount({ id })
          this.$message.success('删除成功')
          this.loadTableData()
        } catch (e) {
          this.$message.error('删除失败：' + e)
        }
      },
      bindOrg(row){
        this.$refs.orgDialog.open(row);

      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-admin__list {

  }
</style>
