<template>
  <el-dialog
    title="订单商品"
    :visible.sync="dialogVisible" >
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </el-dialog>
</template>

<script>
  import { getOrderGoodsList } from '@/api/order'

  export default {
    name: 'goods',
    data() {
      return {
        dialogVisible: false,
        table: {
          data: [],
          cols: [
            {
              label: '商品 sku',
              prop: 'product_id'
            },
            {
              label: '商品名称',
              prop: 'goods_name'
            },
            {
              label: '商品图片',
              width: 120,
              render: ({ row }) => {
                return (
                  <el-image style="width: 100px;"
                            src={row.list_pic_url} />
                );
              }
            },
            {
              label: '数量',
              prop: 'number'
            },
            {
              label: '售卖价 (元)',
              width: 80,
              render: ({ row }) => {
                return (
                  <div style="color: blue; font-weight: bold;">
                    {row.retail_price && this.$options.filters.currency(row.retail_price)}
                  </div>
                )
              }
            },
            {
              label: '京东价 (元)',
              width: 80,
              render: ({ row }) => {
                return (
                  <div>
                    {row.market_price && this.$options.filters.currency(row.market_price)}
                  </div>
                )
              }
            },
          ]
        }
      }
    },
    methods: {
      async open({ orderId, orderSn }) {
        this.dialogVisible = true
        await this.loadGoodsList({ orderId, orderSn })
      },
      async loadGoodsList({ orderId, orderSn }) {
        const { data } = await getOrderGoodsList({ orderId, orderSn })
        this.table.data = data
      }
    }
  }
</script>

<style scoped>

</style>
