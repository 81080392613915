import axios from './base'

export const orgList = () => {
  return axios.get('org/list')
}

export const orgListByPage = () => {
  return axios.get('org/listByPage')
}

export const orgSaveDetail = ({
                                id,
                                org_name,
                                tax_id,
                                avatar,
                                introduction,
                                address,
                                bank,
                                public_account,
                                phone,
                                contact,
                                contact_phone,
                                contact_mail,
                                label,
                                provinceId,
                                provinceName,
                                cityId,
                                cityName,
                                countyId,
                                countyName,
                                townId,
                                townName,
                              }) => {
  return axios.post('org/saveDetail', {
    id,
    org_name,
    tax_id,
    avatar,
    introduction,
    address,
    bank,
    public_account,
    phone,
    contact,
    contact_phone,
    contact_mail,
    label,
    provinceId: provinceId || 0,
    provinceName: provinceName || '',
    cityId: cityId || 0,
    cityName: cityName || '',
    countyId: countyId || 0,
    countyName: countyName || '',
    townId: townId || 0,
    townName: townName || '',
  })
}

