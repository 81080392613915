<template>
  <el-dialog
    title="申请开票"
    width="80%"
    class="page-order__invoice-apply"
    :visible.sync="dialogVisible" >
    <div class="dialog-body">
      <el-form :model="formData" size="small" ref="formData" label-width="300px" class="demo-formData">
        <el-form-item label="子订单号" prop="supplierOrder" :rules="[{
          required: true, message: '子订单号不能为空'
        }]" >
          <div style="width: 590px;">{{formData.supplierOrder}}</div>
        </el-form-item>
        <el-form-item label="结算单号" prop="settlementId" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <span slot="label">
            结算单号
            <el-tooltip
                    effect="dark"
                    content="一个结算单号可对对应多个第三方申请单号"
                    placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </span>
          <el-input v-model="formData.settlementId" placeholder="请填写结算单号"></el-input>
        </el-form-item>
        <el-form-item label="第三方申请单号" prop="markId" :rules="[{
          required: true, message: '第三方申请单号不能为空'
        }]" >
          <span slot="label">
            第三方申请单号
            <el-tooltip
              effect="dark"
              content="申请发票的唯一id标识 (该标记下可以对应多张发票信息)"
              placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </span>
          <el-input v-model="formData.markId" placeholder="请填写第三方申请单号"></el-input>
        </el-form-item>
        <el-form-item label="结算单子订单总数" prop="settlementNum" >
          <div>{{formData.settlementNum}}</div>
        </el-form-item>
        <el-form-item label="当前批次子订单总数" prop="invoiceNum" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <div>{{formData.invoiceNum}}</div>
        </el-form-item>
<!--        <el-form-item label="当前批次不含税总金额" prop="invoiceNakedPrice" >-->
<!--          <div>{{formData.invoiceNakedPrice}}</div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="当前批次总税额" prop="invoiceTaxPrice" >-->
<!--          <div>{{formData.invoiceTaxPrice}}</div>-->
<!--        </el-form-item>-->
        <el-form-item label="当前批次含税总金额" prop="invoicePrice" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <div>{{formData.invoicePrice}} 元</div>
        </el-form-item>
<!--        <el-form-item label="当前批次号" prop="currentBatch" :rules="[{-->
<!--          required: true, message: '不能为空'-->
<!--        }]" >-->
<!--          <el-input v-model="formData.currentBatch" placeholder="请填写"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总批次数" prop="totalBatch" :rules="[{-->
<!--          required: true, message: '不能为空'-->
<!--        }]" >-->
<!--          <el-input v-model="formData.totalBatch" placeholder="请填写"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总批次开发票金额（不含税）" prop="totalBatchInvoiceNakedAmount" >-->
<!--          <el-input v-model="formData.totalBatchInvoiceNakedAmount" placeholder="请填写"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="总批次开发票税额" prop="totalBatchInvoiceTaxAmount" >-->
<!--          <el-input v-model="formData.totalBatchInvoiceTaxAmount" placeholder="请填写"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="总批次开发票价税合计" prop="totalBatchInvoiceAmount" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <div>{{formData.totalBatchInvoiceAmount}} 元</div>
        </el-form-item>
<!--        <el-form-item label="结算单不含税总金额" prop="settlementNakedPrice" >-->
<!--          <div>{{formData.settlementNakedPrice}}</div>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="结算单总税额" prop="settlementTaxPrice" >-->
<!--          <div>{{formData.settlementTaxPrice}}</div>-->
<!--        </el-form-item>-->
        <el-form-item label="采购单号" prop="poNo" >
          <span slot="label">
            采购单号
            <el-tooltip
                    effect="dark"
                    content="长度范围[1-26]"
                    placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </span>
          <el-input v-model="formData.poNo" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoiceType" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <el-radio-group v-model="formData.invoiceType">
            <el-radio :label="1">增值税普通发票</el-radio>
            <el-radio :label="2">增值税专用发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="开票机构ID" prop="invoiceOrg" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <span slot="label">
            开票机构ID
            <el-tooltip
              effect="dark"
              content="联系京东业务确定"
              placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </span>
          <el-input v-model="formData.invoiceOrg" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="开票内容" prop="bizInvoiceContent" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <el-radio-group v-model="formData.bizInvoiceContent">
            <el-radio :label="1">明细</el-radio>
            <el-radio :label="100">大类</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="期望开票时间" prop="invoiceDate" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <el-date-picker
            v-model="formData.invoiceDate"
            placeholder="期望开票时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="发票抬头" prop="title" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <el-input v-model="formData.title" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票单位" prop="billToParty" >
          <el-input v-model="formData.billToParty" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="纳税人识别号（专票必须）" prop="enterpriseTaxpayer" >
          <el-input v-model="formData.enterpriseTaxpayer" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人（专票必须）" prop="billToer" >
          <el-input v-model="formData.billToer" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人联系电话" prop="billToContact" :rules="[{
          required: true, message: '不能为空'
        }]" >
          <el-input v-model="formData.billToContact" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人地址（省）（专票必须）" prop="billToProvince" >
          <el-input v-model="formData.billToProvince" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人地址（市）（专票必须）" prop="billToCity" >
          <el-input v-model="formData.billToCity" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人地址（区）（专票必须）" prop="billToCounty" >
          <el-input v-model="formData.billToCounty" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人地址（街道）（专票有四级地址则必传，否则传0）" prop="billToTown" >
          <el-input v-model="formData.billToTown" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="收票人地址（详细地址）（专票必须）" prop="billToAddress" >
          <el-input v-model="formData.billToAddress" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="预计还款时间" prop="repaymentDate" >
          <span slot="label">
            预计还款时间
            <el-tooltip
              effect="dark"
              content="开票方式为预借时必传"
              placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </span>
          <el-date-picker
            v-model="formData.repaymentDate"
            placeholder="预计还款时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请类型" prop="billingType" >
          <el-radio-group v-model="formData.billingType">
            <el-radio :label="1">集中开票</el-radio>
            <el-radio :label="2">分别开票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="合并开票" prop="isMerge" >
          <el-radio-group v-model="formData.isMerge">
            <el-radio :label="1">合并SKU</el-radio>
            <el-radio :label="2">分别开票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="企业注册地址" prop="enterpriseRegAddress" >
          <el-input v-model="formData.enterpriseRegAddress" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="企业注册电话" prop="enterpriseRegPhone" >
          <el-input v-model="formData.enterpriseRegPhone" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="企业注册开户行名称" prop="enterpriseBankName" >
          <el-input v-model="formData.enterpriseBankName" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="企业注册开户行账号" prop="enterpriseBankAccount" >
          <el-input v-model="formData.enterpriseBankAccount" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save()">申请</el-button>
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { applyJdInvoice, getJdInvoiceConfig } from '@/api/jd_invoice'

  export default {
    name: 'apply',
    data() {
      return {
        dialogVisible: false,
        formData: {
          supplierOrder: [],
          markId: '',
          settlementId: '',
          settlementNum: 0,
          settlementNakedPrice: '',
          settlementTaxPrice: '',
          invoiceType: 2,
          invoiceOrg: '',
          bizInvoiceContent: 1,
          invoiceDate: dayjs().format('YYYY-MM-DD'),
          title: '',
          billToParty: '',
          enterpriseTaxpayer: '',
          billToer: '',
          billToContact: '',
          billToProvince: '',
          billToCity: '',
          billToCounty: '',
          billToTown: '',
          billToAddress: '',
          repaymentDate: '',
          invoiceNum: 0,
          invoiceNakedPrice: '',
          invoiceTaxPrice: '',
          invoicePrice: '',
          currentBatch: 1,
          totalBatch: 1,
          totalBatchInvoiceNakedAmount: '',
          totalBatchInvoiceTaxAmount: '',
          totalBatchInvoiceAmount: '',
          billingType: 1,
          isMerge: 1,
          poNo: '',
          enterpriseRegAddress: '',
          enterpriseRegPhone: '',
          enterpriseBankName: '',
          enterpriseBankAccount: ''
        }
      }
    },
    methods: {
      async open({ supplierOrder }) {
        this.dialogVisible = true
        this.formData.supplierOrder = supplierOrder.map(item => item.jdOrderId).join()
        this.formData.settlementNum = supplierOrder.length
        this.formData.invoiceNum = supplierOrder.length
        const totalOrderPrice = supplierOrder.reduce((p, v) => p + v.orderPrice, 0).toFixed(2)
        this.formData.invoicePrice = totalOrderPrice
        this.formData.totalBatchInvoiceAmount = totalOrderPrice

        const config = await getJdInvoiceConfig()
        this.formData.invoiceOrg = config.invoiceOrg
        this.formData.title = config.regCompanyName
        this.formData.billToParty = config.companyName
        this.formData.enterpriseTaxpayer = config.regCode
        this.formData.billToer = config.invoiceName
        this.formData.billToContact = config.invoicePhone
        this.formData.billToProvince = config.invoiceProvice
        this.formData.billToCity = config.invoiceCity
        this.formData.billToCounty = config.invoiceCounty
        this.formData.billToTown = config.invoiceTown || 0
        this.formData.billToAddress = config.invoiceAddress
        this.formData.enterpriseRegAddress = config.regAddr
        this.formData.enterpriseRegPhone = config.regPhone
        this.formData.enterpriseBankName = config.regBank
        this.formData.enterpriseBankAccount = config.regBankAccount
      },
      async save() {
        const isValid = await this.$refs.formData.validate().catch(() => false)
        if (!isValid) {
          return
        }
        await this.$confirm('确定信息无误，确认申请？')
        const {
          supplierOrder,
          markId,
          settlementId,
          settlementNum,
          settlementNakedPrice,
          settlementTaxPrice,
          invoiceType,
          invoiceOrg,
          bizInvoiceContent,
          invoiceDate,
          title,
          billToParty,
          enterpriseTaxpayer,
          billToer,
          billToContact,
          billToProvince,
          billToCity,
          billToCounty,
          billToTown,
          billToAddress,
          repaymentDate,
          invoiceNum,
          invoiceNakedPrice,
          invoiceTaxPrice,
          invoicePrice,
          currentBatch,
          totalBatch,
          totalBatchInvoiceNakedAmount,
          totalBatchInvoiceTaxAmount,
          totalBatchInvoiceAmount,
          billingType,
          isMerge,
          poNo,
          enterpriseRegAddress,
          enterpriseRegPhone,
          enterpriseBankName,
          enterpriseBankAccount
        } = this.formData
        try {
          await applyJdInvoice({
            supplierOrder,
            markId,
            settlementId,
            settlementNum,
            settlementNakedPrice,
            settlementTaxPrice,
            invoiceType,
            invoiceOrg,
            bizInvoiceContent,
            invoiceDate,
            title,
            billToParty,
            enterpriseTaxpayer,
            billToer,
            billToContact,
            billToProvince,
            billToCity,
            billToCounty,
            billToTown,
            billToAddress,
            repaymentDate,
            invoiceNum,
            invoiceNakedPrice,
            invoiceTaxPrice,
            invoicePrice,
            currentBatch,
            totalBatch,
            totalBatchInvoiceNakedAmount,
            totalBatchInvoiceTaxAmount,
            totalBatchInvoiceAmount,
            billingType,
            isMerge,
            poNo,
            enterpriseRegAddress,
            enterpriseRegPhone,
            enterpriseBankName,
            enterpriseBankAccount
          })
          this.dialogVisible = false
          this.$message.success('申请成功')
        } catch (e) {
          this.$message.error(e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-order__invoice-apply {
    .el-input {
      width: 300px;
    }
  }
</style>
