<template>
  <el-dialog
    title="批量生成提货卡"
    class="page-user__pickup-add-batch"
    :visible.sync="dialogVisible" >
    <el-form ref="formData" :model="formData" class="demo-form-inline" label-width="150px">
      <el-form-item prop="price" label="提货卡金额 (元)" :rules="[
        { required: true, message: '请填写提货卡金额' }
      ]">
        <el-input v-model="formData.price" type="number" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="skus" label="商品sku编号" >
        <el-input v-model="formData.skus"  placeholder="请输入商品sku，多个中间，隔开" />
      </el-form-item>
      <el-form-item prop="number" label="数量">
        <el-input-number v-model="formData.number" :min="1" :max="1000" label="描述文字"></el-input-number>
      </el-form-item>
      <el-form-item prop="deadline" label="截止日期" :rules="[
        { required: true, message: '请选择截止日期' }
      ]">
        <el-date-picker
          :clearable="false"
          v-model="formData.deadline"
          placeholder="请选择截止日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="orgId" label="绑定供应商" :rules="[
        { required: true, message: '请选择供应商' }
      ]">
        <el-select
          filterable
          v-model="formData.orgId"
          placeholder="请选择"
          style="width: 250px;">
          <el-option
            v-for="(item, index) in orgOptions"
            :key="index"
            :label="`${item.id} (${item.org_name})`"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="categoryId" label="绑定三级类目">
        <el-select
                filterable
                multiple
                v-model="formData.categoryId"
                placeholder="请选择"
                style="width: 350px;">
          <el-option
                  v-for="(item, index) in categoryOptions"
                  :key="index"
                  :label="`${item.catId} (${item.name})`"
                  :value="item.catId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即生成</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { createBatchPickup } from '@/api/pickup'
  import { getCatalogList } from '@/api/catalog'
  import { orgList } from '@/api/org'

  export default {
    name: 'add',
    data() {
      const today = dayjs()
      return {
        dialogVisible: false,
        formData: {
            skus:'',
          price: '',
          companyId: '',
          categoryId: '',
          number: '',
          orgId:'',
          // 截止日期默认 6 个月后
          deadline: today.add(6, 'month').format('YYYY-MM-DD'),
          remark: '批量生成'
        },
        orgOptions: [],
          categoryOptions: []
      }
    },
    mounted(){
      this.getOrgList()
        this.getCategoryList()
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.formData.resetFields()
        })
      },
        async getCategoryList() {
            const { data } = await getCatalogList({ page: 1, size: 3000, catClass: 2 })
            this.categoryOptions = data
        },
      async onSubmit() {
        const isValid = await this.$refs.formData.validate().catch(() => false)
        if (!isValid) {
          return
        }
        const {
          price,
          companyId,
          categoryId,
          number,
          deadline,
          remark,
          orgId,
            skus
        } = this.formData
        try {
          const data = await createBatchPickup({
            price,
            companyId,
           categoryId:categoryId.join(','),
            number,
            deadline,
            remark,
            orgId,
              skus
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', `批量创建提货卡-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.$message.success('生成成功')
          this.dialogVisible = false
          this.$emit('refresh')
        } catch (e) {
          this.$message.error('生成失败')
        }
      },
      async getOrgList(){
        const res = await orgList();
        this.orgOptions = res;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__pickup-add-batch {
    .el-input {
      width: 260px;
    }
  }
</style>
