// import axios from '../../api/base'

const defaultUserInfo = () => {
  return {
    id: '',
    gender: 0, // 未知 0，男 1， 女 2
    nickname: '点击头像登录',
    admin_role_id: 0, // 超级管理员 1，运营管理员 0
    avatar: 'http://yanxuan.nosdn.127.net/8945ae63d940cc42406c3f67019c5cb6.png'
  }
}

const userModule = {
  state: {
    userInfo: defaultUserInfo(),
    token: ''
  },
  mutations: {
    RESET_USERINFO(state) {
      state.userInfo = defaultUserInfo()
    }
    ,
    RESET_TOKEN(state) {
      state.token = ''
    },
    SET_USERINFO(state, payload) {
      state.userInfo = payload
    },
    SET_TOKEN(state, payload) {
      state.token = payload
      // 设置 axios 请求头，后端将根据这个请求头判断用户的登录态
      // axios.defaults.headers.common['x-jd-token'] = payload
    }
  },
  actions: {},
  getters: {
    isLogin(state) {
      // token 且 userInfo.id 同时不为空时，则为已登录
      return state.userInfo.id !== '' && !!state.token
    },
    isAdmin(state) {
      // token 且 userInfo.id 同时不为空时，则为已登录
      return state.userInfo.id !== '' && state.userInfo.admin_role_id === 1
    }
  }
}

export default userModule
