<template>
  <el-dialog
          :title="`设置角色菜单`"
          :visible.sync="dialogVisible"
          @close="close"
          width="50%">
    <el-tree
            ref="menuTree"
            :data="tree.data"
            show-checkbox
            node-key="id"
            :default-expanded-keys="tree.defaultExpandedKeys"
            :default-checked-keys="tree.defaultCheckedKeys"
            :props="tree.defaultProps"
            :check-strictly="false">
    </el-tree>
    <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">保存</el-button>
          </span>
  </el-dialog>
</template>

<script>
  import { setRoleAuth, getRoleAuthChecked } from '@/api/admin'
  import { defaultMenu } from '@/enums/left_menu'

  export default {
    name: 'menuTree',
    data() {
      return {
        dialogVisible: false,
        companyId: null,
        tree: {
          data: [],
          defaultExpandedKeys: [],
          defaultCheckedKeys: [],
          defaultProps: {
            children: 'children',
            label: 'name'
          }
        }
      };
    },
    computed: {},
    watch: {},
    methods: {
      async open(id) {
        this.roleId = id
        this.dialogVisible = true
        this.tree.data = defaultMenu;
        this.tree.defaultCheckedKeys = await getRoleAuthChecked({ roleId: this.roleId, type: 0 })
      },
      close() {
        this.dialogVisible = false
        this.clean()
      },
      clean() {
        this.tree.data = []
        this.tree.defaultExpandedKeys = []
        this.tree.defaultCheckedKeys = []
        this.companyId = null
      },
      async save() {
        const menusIds = this.$refs.menuTree.getCheckedKeys();
        try {
          await setRoleAuth({ roleId: this.roleId, menusIds, type: 0 })
          this.$message({message: '设置成功', type: 'success'});
        } catch (e) {
          console.log(e)
          this.$message({message: '设置失败', type: 'error'});
        }
        this.close()
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
