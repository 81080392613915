import axios from './base'

export const getRechargeCardList = ({
  page,
  size,
  state,
  code,
  bindUserMobile,
  useUserMobile,
  uesUserCompany,
  createdBeginDate,
  createdEndDate
}) => {
  return axios.get('recharge/list', {
    params: {
      page,
      size,
      state,
      code,
      bindUserMobile,
      useUserMobile,
      uesUserCompany,
      createdBeginDate,
      createdEndDate
    }
  })
}

export const downloadRechargeList = ({
  state,
  code,
  bindUserMobile,
  useUserMobile,
  uesUserCompany,
  createdBeginDate,
  createdEndDate
}) => {
  return axios.post('recharge/download', {
    state,
    code,
    bindUserMobile,
    useUserMobile,
    uesUserCompany,
    createdBeginDate,
    createdEndDate
  }, {
    responseType: 'blob'
  })
}
export const createBatchDifPriceRecharge = ({ fileUrl }) => {
  return axios.post('recharge/fileCreateBatchDifPrice', {
    fileUrl
  }, {
    responseType: 'blob'
  })
}
export const downloadTemplate = () => {
  return axios.get('recharge/downloadTemplate', {
    responseType: 'blob'
  })
}
export const createRecharge = ({ price, number, deadline, mobile, remark }) => {
  return axios.post('recharge/create', {
    price, number, deadline, mobile, remark
  })
}

export const createBatchRecharge = ({ price, number, deadline, mobile, remark }) => {
  return axios.post('recharge/createBatch', {
    price, number, deadline, mobile, remark
  }, {
    responseType: 'blob'
  })
}

export const sendRechargeCode = ({ id }) => {
  return axios.post('recharge/sendCode', {
    id
  })
}

export const cancelRechargeCode = ({ id }) => {
  return axios.post('recharge/cancel', {
    id
  })
}
