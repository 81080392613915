<template>
    <el-dialog
            title="上传分类图片"
            :visible.sync="dialogVisible"
            width="40%">
        <div class="catalog_sort">
            <el-form :model="formData" ref="form" label-width="150px">

                <el-form-item label="图片资源" prop="image" :rules="[{
        required: true, message: '头像资源不能为空'
      }]" >
                    <div class="upload-tips">Tips: 图片尺寸：100*100</div>
                    <el-upload
                            class="banner-uploader"
                            :show-file-list="false"
                            :http-request="uploadBanner"
                            action="fake"
                            accept="image/*"
                    >
                        <img v-if="formData.image" :src="formData.image" class="banner" />
                        <i v-else class="el-icon-plus banner-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="close">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>
<script>
    import { editImage } from '@/api/catalog'
    import axios from '@/api/base'
    export default {
        name: 'sort',
        data() {
            return {
                dialogVisible: false,
                formData: {
                    image:'',
                    catId:''
                }
            }
        },
        methods: {
            open(row) {
                this.dialogVisible = true
                this.formData.image = row.image
                this.formData.catId = row.catId
            },
            async uploadBanner({ file }) {
                try {
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    const url = await axios.post('/file/upload2', formData);
                    this.$message.success('上传成功');
                    this.formData.image = url;
                } catch (e) {
                    this.$message.error(e);
                }
            },
            async save() {
                if (!(await this.$refs.form.validate())) {
                    return
                }
                try {
                    const {
                        image,
                        catId
                    } = this.formData
                    await editImage({
                        catId: catId,
                        image: image
                    })
                    this.$message.success('修改成功')
                    this.close()
                    this.$emit('refresh')
                } catch (e) {
                    this.$message.error('修改失败：' + e)
                }
            },
            close() {
                this.dialogVisible = false
            }
        }
    }
</script>