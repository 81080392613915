<template>
  <div class="page-goods__add-edit">
    <h2>{{ isEditing ? "修改商品" : "新建商品" }}</h2>
    <el-form
            :model="formData"
            ref="formData"
            label-width="100px"
            class="demo-formData"
    >
      <el-form-item
              label="商品名称"
              prop="name"
              :rules="[
          {
            required: true,
            message: '商品名称不能为空',
          },
        ]"
      >
        <el-input
                v-model="formData.name"
                placeholder="请输入商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item
              label="品牌名称"
              prop="brandName"
              :rules="[
          {
            required: true,
            message: '品牌名称不能为空',
          },
        ]"
      >
        <el-input
                v-model="formData.brandName"
                placeholder="请输入品牌名称"
        ></el-input>
      </el-form-item>
      <el-form-item
              label="商品分类"
              prop="category"
              :rules="[
          {
            required: true,
            message: '商品分类不能为空',
          },
        ]"
      >
        <el-cascader
                v-model="formData.category"
                :props="categoryProps"
                class="cascader"
                :placeholder="categoryValue"
                @change="categoryChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item
              label="产品首图"
              prop="imagePath"
              :rules="[
          {
            required: false,
            message: '商品首图不能为空',
          },
        ]"
      >
        <el-upload
                class="upload-demo"
                v-model="formData.imagePath"
                :http-request="
            ({ file }) => {
              this.uploadBanner(file, 'imagePath');
            }
          "
                action="fake"
                accept="image/*"
                :on-preview="handlePreview"
                :file-list="imagePathFileList"
                list-type="picture"
                :limit="1"
                :on-exceed="overLimit"
                :on-remove="
            (files, fileList) => handleRemove(files, fileList, 'imagePath')
          "
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb，414*414
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
              label="banner图"
              prop="imgUrl"
              :rules="[
          {
            required: false,
            message: 'banner图不能为空',
          },
        ]"
      >
        <el-upload
                class="banner-uploader"
                v-model="formData.imgUrl"
                :http-request="
            ({ file }) => {
              this.uploadBanner(file, 'imgUrl');
            }
          "
                action="fake"
                accept="image/*"
                :on-preview="handlePreview"
                :file-list="imgUrlFileList"
                list-type="picture"
                :on-remove="
            (files, fileList) => handleRemove(files, fileList, 'imgUrl')
          "
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb，414*414
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
              label="小程序商品介绍图"
              prop="detailImgUrl"
              :rules="[
          {
            required: false,
            message: '小程序商品介绍图不能为空',
          },
        ]"
      >
        <el-upload
                class="banner-uploader"
                v-model="formData.detailImgUrl"
                :http-request="
            ({ file }) => {
              this.uploadBanner(file, 'detailImgUrl');
            }
          "
                action="fake"
                accept="image/*"
                :on-preview="handlePreview"
                :file-list="detailImgUrlList"
                list-type="picture"
                :on-remove="
            (files, fileList) => handleRemove(files, fileList, 'detailImgUrl')
          "
        >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg/png文件，且不超过500kb，414*414
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item
              label="商品介绍"
              prop="introduction"
              :rules="[
          {
            required: true,
            message: '商品介绍不能为空',
          },
        ]"
      >
        <vue-ueditor-wrap
                ref="ueditor"
                v-model="formData.introduction"
                :destroy="false"
                :config="myConfig"
        ></vue-ueditor-wrap>

        <!--        <el-input type="textarea" v-model="formData.introduction" placeholder="请输入商品介绍"></el-input>-->
      </el-form-item>
      <el-form-item
              label="规格参数"
              prop="param"
              :rules="[
          {
            required: true,
            message: '规格参数不能为空',
          },
        ]"
      >
        <vue-ueditor-wrap
                ref="ueditor"
                v-model="formData.param"
                :destroy="false"
                :config="myConfig"
        ></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item
              label="包装售后"
              prop="wareQD"
              :rules="[
          {
            required: true,
            message: '包装售后不能为空',
          },
        ]"
      >
        <el-input
                type="textarea"
                v-model="formData.wareQD"
                placeholder="请输入包装售后"
        ></el-input>
      </el-form-item>

      <el-form-item
              label="成本价"
              prop="price"
              :rules="[
          {
            required: true,
            message: '成本价不能为空',
          },
        ]"
      >
        <el-input-number
                v-model="formData.price"
                :min="0"
                :precision="2"
                :step="1"
        ></el-input-number>
        元
      </el-form-item>
      <el-form-item
              label="零售价"
              prop="jd_price"
              :rules="[
          {
            required: true,
            message: '零售价不能为空',
          },
        ]"
      >
        <el-input-number
                v-model="formData.jd_price"
                :min="0"
                :precision="2"
                :step="1"
        ></el-input-number>
        元
      </el-form-item>
      <el-form-item
              label="会员价"
              prop="retail_price"
              :rules="[
          {
            required: true,
            message: '会员价不能为空',
          },
        ]"
      >
        <el-input-number
                v-model="formData.retail_price"
                :min="0"
                :precision="2"
                :step="1"
        ></el-input-number>
        元
      </el-form-item>
      <el-form-item
              label="库存"
              prop="stock"
              :rules="[
          {
            required: true,
            message: '库存不能为空',
          },
        ]"
      >
        <el-input-number
                v-model="formData.stock"
                :min="0"
                :step="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item
              label="商品单位"
              prop="saleUnit"
              :rules="[
          {
            required: true,
            message: '商品单位不能为空',
          },
        ]"
      >
        <el-input
                class="small-input"
                v-model="formData.saleUnit"
                placeholder="请输入商品单位"
        ></el-input>
      </el-form-item>
      <el-form-item
              label="产地"
              prop="productArea"
              :rules="[
          {
            required: true,
            message: '产地不能为空',
          },
        ]"
      >
        <el-input
                class="small-input"
                v-model="formData.productArea"
                placeholder="请输入产地"
        ></el-input>
      </el-form-item>

      <el-form-item
              label="商品重量"
              prop="weight"
              :rules="[
          {
            required: true,
            message: '商品重量',
          },
        ]"
      >
        <el-input
                class="small-input"
                v-model="formData.weight"
                placeholder="请输入商品重量"
        ></el-input>
        kg
      </el-form-item>
      <el-form-item label="upc码" prop="upc">
        <el-input
                class="small-input"
                v-model="formData.upc"
                placeholder="请输入upc码"
        ></el-input>
      </el-form-item>
      <el-form-item
              label="商品状态"
              prop="state"
              :rules="[
          {
            required: true,
            message: '商品状态不能为空',
          },
        ]"
      >
        <el-select v-model="formData.state" placeholder="请选择">
          <el-option
                  v-for="cItem in stateOption"
                  :key="cItem.id"
                  :label="`${cItem.name}`"
                  :value="cItem.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
              label="供应商"
              prop="state"
              :rules="[
          {
            required: true,
            message: '供应商不能为空',
          },
        ]"
      >
        <el-select
                v-model="formData.org_id"
                placeholder="请选择"
                :disabled="orgDisabled"
        >
          <el-option
                  v-for="cItem in orgOption"
                  :key="cItem.id"
                  :label="`${cItem.org_name}`"
                  :disabled="cItem.disabled"
                  :value="cItem.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save()">保存</el-button>
        <el-button @click="channel">取消</el-button>
      </el-form-item>
    </el-form>
    <!--        <goods-list-dialog ref="GoodsListDialog" :sku.sync="formData.skuList" />-->
  </div>
</template>

<script>
import {getCatalogList} from "@/api/catalog";
import {getDetail, saveGoods} from "@/api/goods";
import {orgList} from "@/api/org";
import VueUeditorWrap from "vue-ueditor-wrap";
import axios from "@/api/base";
import store from "@/store";
// import store from '../../store'

export default {
  name: "add_edit",
  components: {
    VueUeditorWrap,
  },
  data() {
    return {
      categoryValue: "",
      orgOption: [],
      formData: {
        name: "",
        desc: "",
        brand: "",
        category: [],
        imagePath: "",
        imgUrl: [],
        detailImgUrl: [],
        price: "",
        jd_price: "",
        retail_price: "",
        org_id: "",
        upc: "",
        stock: "",
      },
      categoryProps: {
        lazy: true,
        lazyLoad(node, resolve) {
          const {level} = node;
          console.log("node", node);
          console.log("resolve", this);

          setTimeout(() => {
            // console.log('Array.from({ length: level + 1 })', Array.from({length: level + 1}))

            getCatalogList({
              parentId: node.data ? node.data.catId : "",
              catClass: level,
              size: 100,
            }).then((res) => {
              console.log("res", res);
              const {data} = res;
              const nodes = data.map((value) => ({
                value: value.catId,
                catId: value.catId,
                label: value.name,
                leaf: level >= 2,
              }));
              // 通过调用resolve将子节点数据返回，通知组件数据加载完成
              resolve(nodes);
            });
          }, 0);
        },
      },
      stateOption: [
        {
          id: 0,
          name: "已下架",
        },
        {
          id: 1,
          name: "已上架",
        },
        {
          id: 3,
          name: "同步异常",
        },
      ],
      imagePathFileList: [],
      imgUrlFileList: [],
      detailImgUrlList: [],
      myConfig: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        UEDITOR_HOME_URL: "/UEditor/",
        serverUrl: "admin/file/UEditorUpload",
        headers: {
          "x-jd-token": store.state.user.token,
        },
      },
      adminRoleId: store.state.user.userInfo.admin_role_id,
      orgDisabled: false,
    };
  },
  computed: {
    isEditing() {
      return !!this.$route.query.id;
    },
  },
  async mounted() {
    this.getOrgListApi();
    if (this.isEditing) {
      const {id} = this.$route.query;
      let getData = await getDetail({id});
      getData.state = this.stateOption.find(
              (value) => value.id === getData.state
      ).id;
      getData.org_id = this.orgOption.find(
              (value) => value.id === getData.org_id
      ).id;
      this.imagePathFileList = [{name: "首图", url: getData.imagePath}]; //首图
      if (getData.imgUrl && getData.imgUrl.replace(";", "").length > 0) {
        this.imgUrlFileList = getData.imgUrl
                .split(";")
                .map((value) => ({url: value}));
      }
      if (getData.detail_img_url) {
        this.detailImgUrlList = getData.detail_img_url.split(",").map((value) => ({url: value}));
      }
      this.formData = getData;
      this.categoryValue = getData.catalogInfo
              .map((value) => {
                return value.name;
              })
              .join("/");
    }
    if (this.adminRoleId === 4) {
      this.orgDisabled = true;
      this.formData.org_id = store.state.user.userInfo.info_id;
    }
  },
  methods: {
    /***
     * 提交表单
     * ***/
    async save() {
      console.log("this.formData", this.formData);
      const result = await this.$refs.formData.validate();
      if (!result) {
        return;
      }
      try {
        const {
          name,
          brandName,
          category,
          introduction,
          imagePath,
          // imgUrl,
          param,
          price,
          jd_price,
          retail_price,
          productArea,
          saleUnit,
          weight,
          state,
          wareQD,
          sku,
          upc,
          org_id,
          stock,
        } = this.formData;
        console.log("org_id", org_id);
        await saveGoods({
          name,
          brandName,
          category:
                  typeof category === "string" ? category : category.join(";"),
          introduction,
          imagePath,
          imgUrl: this.imgUrlFileList.map((value) => value.url).join(";"),
          detail_img_url: this.detailImgUrlList.map((value) => value.url).join(","),
          param,
          price,
          jd_price,
          retail_price,
          productArea,
          saleUnit,
          weight,
          state,
          wareQD,
          sku,
          org_id,
          upc,
          stock,
        });
        // console.log(name, brand,desc,category)
        if (this.isEditing) {
          this.$alert("修改成功", "提示", {
            confirmButtonText: "确定",
            callback: () => {
              this.$router.push({
                name: "skuGoodsList",
              });
            },
          });
        } else {
          this.$confirm("添加成功", "提示", {
            confirmButtonText: "去商品列表",
            cancelButtonText: "继续添加",
            type: "warning",
          })
                  .then(() => {
                    this.$router.push({
                      name: "skuGoodsList",
                    });
                  })
                  .catch(() => {
                    this.$router.go(0);
                  });
        }
      } catch (e) {
        this.$message.error("创建失败：" + e);
      }
    },

    onSuccess(response, file, fileList, itemName) {
      console.log(response, file, fileList, itemName);
      this.formData[itemName] = fileList;
    },
    handlePreview(file) {
      console.log("file", file);
    },
    categoryChange(value) {
      console.log(value);
    },
    async uploadBanner(file, itemName) {
      console.log("this.formData.imgUrlArray", this.imgUrlFileList);
      try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        const url = await axios.post("/file/upload2", formData);
        this.$message.success("上传成功");
        if (itemName === "imgUrl") {
          // this.imgUrlFileList.push({
          this.imgUrlFileList.push({
            url: url,
          });
          // this.formData[itemName].push(url);
        } else if (itemName === "detailImgUrl") {
          // this.imgUrlFileList.push({
          this.detailImgUrlList.push({
            url: url,
          });
          // this.formData[itemName].push(url);
        } else {
          this.formData[itemName] = url;
        }
      } catch (e) {
        this.$message.error(e);
      }
      console.log("this.formData.imgUrlArray1111", this.imgUrlFileList);
    },
    overLimit() {
      this.$message.error("产品首图只能上传一张");
    },
    async getOrgListApi() {
      const res = await orgList();
      this.orgOption = res;
      this.orgOption[0].disabled = true;
      console.log(this.orgOption);
    },
    handleRemove(files, fileList, itemName) {
      if (itemName === "imgUrl") {
        this.imgUrlFileList = fileList;
      }
      if (itemName === "detailImgUrl") {
        this.detailImgUrlList = fileList;
      }
    },
    channel() {
    },
  },
};
</script>

<style lang="scss" scoped>
.page-goods__add-edit {
  width: 70%;

  .small-input {
    width: 200px;
  }

  .cascader {
    width: 100%;
  }
}
</style>
