<template>
  <el-dialog
    title="已同步商品"
    width="80%"
    :visible.sync="dialogVisible" >
    <op-goods-list ref="OpGoodsList"
                   @data-change="tableDataChange"
                   @select="handleSelect">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
    </op-goods-list>
  </el-dialog>
</template>

<script>
  import OpGoodsList from '@/components/goods_list/goods_list'

  export default {
    name: 'goods_list',
    props: {
      skuIds: {
        type: Array,
        default: () => []
      }
    },
    components: {
      OpGoodsList
    },
    data() {
      return {
        dialogVisible: false,
        tableData: []
      }
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.tableDataChange()
      },
      handleSelect(selection, row) {
        const selected = selection.indexOf(row) > -1
        this.$emit('select', row, selected)
      },
      tableDataChange({ data } = { data: this.tableData }) {
        // 缓存商品列表数据
        this.tableData = data
        const result = data.filter(item => this.skuIds.some(v => +v === +item.sku))
        this.$nextTick(() => {
          this.$refs.OpGoodsList.$table.clearSelection()
          result.forEach(sku => this.$refs.OpGoodsList.$table.toggleRowSelection(sku, true))
        })
      }
    }
  }
</script>

<style scoped>

</style>
