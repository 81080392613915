import axios from './base'

export const getPickupCardList = ({ page, size, state }) => {
  return axios.get('pickup/list', {
    params: { page, size, state }
  })
}

export const createPickup = ({ price, companyId, categoryId, number, deadline, mobile, remark, orgId,skus }) => {
  return axios.post('pickup/create', {
    price, companyId, categoryId, number, deadline, mobile, remark, orgId,skus
  })
}

export const createBatchPickup = ({ price, companyId, categoryId, number, deadline, mobile, remark, orgId,skus }) => {
  return axios.post('pickup/createBatch', {
    price, companyId, categoryId, number, deadline, mobile, remark, orgId,skus
  }, {
    responseType: 'blob'
  })
}

export const sendPickupCode = ({ id }) => {
  return axios.post('pickup/sendCode', {
    id
  })
}
