<template>
  <el-dialog :title="`批量新增账号`" :visible.sync="dialogVisible" width="50%">
    <div class="page-user__list__add-batch">
      <el-upload
        class="upload-demo"
        ref="upload"
        :limit="1"
        action="/shop/admin/user/addBatch"
        :on-remove="handleRemove"
        :on-error="handleError"
        :on-success="handleSuccess"
        :headers="headers"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <a class="clicked" href="/file/批量新增用户.xlsx">下载模板文件</a>
        <div slot="tip" class="el-upload__tip">
          请下载模板文件，并按批注填写相关内容后，上传文件。只能上传.xlsx文件，且不超过500kb
        </div>
      </el-upload>
    </div>
  </el-dialog>
</template>

<script>
import store from "@/store";

export default {
  name: "add_batch",
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      headers: {
        "x-jd-token": store.state.user.token,
      },
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    handleRemove() {
      this.$message.success("移除成功");
    },
    handleError(err) {
      this.$message.error(`${err}`);
    },
    handleSuccess(res) {
      this.$message.success(`${res.data}`);
      this.dialogVisible = false;
      this.$emit("refresh");
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-user__list__add-batch {
  .clicked {
    text-decoration: underline;
    color: #00a0e9;
    cursor: pointer;
  }
}
</style>
