<template>
  <el-dialog
    title="修改账号密码"
    :visible.sync="dialogVisible"
    width="50%">
    <div class="page-company-user__add">
      <el-form :model="formData" ref="form" label-width="100px">
        <el-form-item label="角色" prop="role">
          <el-select v-model="formData.role" placeholder="请选择" disabled>
            <el-option
              v-for="(item, index) in [
              {
                label: '二级账号',
                value: 'normal'
              },
              {
                label: '一级账号',
                value: 'admin'
              }
            ]"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="账号"
          prop="username"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input v-model="formData.username" disabled></el-input
          ></el-form-item>


        <el-form-item
          label="密码"
          prop="password"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input type="password" v-model="formData.password"></el-input
          ></el-form-item>

        <el-form-item
          label="密码确认"
          prop="confirm_password"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input type="password" v-model="formData.confirm_password"></el-input
          ></el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { changePassword } from '@/api/user'

export default {
  name: 'password',

  data() {
    return {
      dialogVisible: false,
      userId: '',
      formData: {
        role: 'normal'
      }
    };
  },
  methods: {
    open(userInfo) {
      this.dialogVisible = true
      this.formData = userInfo
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    close() {
      this.dialogVisible = false
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      const {
        id,
        role,
        username,
        password,
        confirm_password,
        mobile,
        nickname
      } = this.formData;
      if (!this.isEditing && password !== confirm_password) {
        this.$message.error('两次密码不一致');
        return;
      }

      try {
        await changePassword({
          id,
          company_id: this.companyId,
          role,
          username,
          password,
          mobile,
          nickname
        });
        this.$message.success('修改成功');
        this.dialogVisible = false
        this.$emit('refresh');
      } catch (e) {
        this.$message.error('修改失败：' + e);
      }
    },
    cancel() {
      this.dialogVisible = false
    }
  }
};
</script>

<style lang="scss">
.page-company-user__add {
  .el-form {
    max-width: 660px;
    .el-select .el-input {
      max-width: 360px;
    }
    .line {
      text-align: center;
    }
    .el-radio {
      margin-bottom: 20px;
    }
  }
}
</style>
