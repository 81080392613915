<template>
  <div class="page-user__list">
    <h2>公告列表</h2>
    <el-button type="primary" class="create-btn" @click="openAddEditDialog">新增</el-button>
    <op-table
            size="small"
            :data="table.data"
            :cols="table.cols"
            :pagination="table.pagination"
    />
    <add-edit-dialog ref="AddEditDialog" @refresh="search"/>
  </div>
</template>

<script>
import AddEditDialog from '../add_edit/add_edit'
import {getGongGaoList, gongGaoDelete} from '@/api/gonggao'

export default {
  name: 'list',
  components: {
    AddEditDialog,
  },
  data() {
    return {
      permission: [],
      formData: {
        companyId: '',
        mobile: ''
      },
      companyList: [],
      table: {
        data: [],
        cols: [
          {
            label: 'ID',
            prop: 'id',
            width: 50
          },
          {
            label: '标题',
            prop: 'name',
            width: 150
          },
          {
            label: '内容',
            minWidth: 100,
            prop: 'title',
          },
          {
            label: '创建时间',
            width: 150,
            prop: 'createTime',
          },
          {
            label: '操作',
            width: 80,
            render: ({row}) => {
              return (
                      <div>
                        {
                          <el-button size="small" type="text" onClick={() => this.delete(row)}>删除</el-button>
                        }
                      </div>
              )
            }
          }
        ],
        pagination: {
          total: 0,
          pageSize: 20,
          currentPage: 1,
          pageSizes: [20, 40, 60, 80],
          layout: 'total, sizes, prev, pager, next, jumper',
          on: {
            'current-change': (currentPage) => {
              this.table.pagination.currentPage = currentPage
              this.loadTableData()
            },
            'size-change': (size) => {
              this.table.pagination.pageSize = size
              this.loadTableData()
            }
          }
        }
      }
    }
  },
  async created() {
    this.search()
  },
  methods: {
    search() {
      this.table.pagination.currentPage = 1
      this.loadTableData()
    },
    async loadTableData() {
      const {currentPage: page, pageSize: size} = this.table.pagination
      const {data, count} = await getGongGaoList({page, size})
      this.table.data = data
      this.table.pagination.total = count
    },
    openAddEditDialog(row) {
      this.$refs.AddEditDialog.open(row, this.companyList)
    },
    async delete({id} = {}) {
      await this.$confirm('确定删除？', '提示', {type: 'warning'});
      try {
        await gongGaoDelete({id})
        this.$message.success('删除成功')
        this.loadTableData()
      } catch (e) {
        this.$message.error('删除失败')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-user__list {
  .create-btn {
    margin-bottom: 20px;
  }

  .clicked {
    text-decoration: underline;
    color: #00a0e9;
    cursor: pointer;
  }
}
</style>
