var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-goods__add-edit"},[_c('h2',[_vm._v(_vm._s(_vm.isEditing ? "修改商品" : "新建商品"))]),_c('el-form',{ref:"formData",staticClass:"demo-formData",attrs:{"model":_vm.formData,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"商品名称","prop":"name","rules":[
        {
          required: true,
          message: '商品名称不能为空',
        } ]}},[_c('el-input',{attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('el-form-item',{attrs:{"label":"品牌名称","prop":"brandName","rules":[
        {
          required: true,
          message: '品牌名称不能为空',
        } ]}},[_c('el-input',{attrs:{"placeholder":"请输入品牌名称"},model:{value:(_vm.formData.brandName),callback:function ($$v) {_vm.$set(_vm.formData, "brandName", $$v)},expression:"formData.brandName"}})],1),_c('el-form-item',{attrs:{"label":"商品分类","prop":"category","rules":[
        {
          required: true,
          message: '商品分类不能为空',
        } ]}},[_c('el-cascader',{staticClass:"cascader",attrs:{"props":_vm.categoryProps,"placeholder":_vm.categoryValue},on:{"change":_vm.categoryChange},model:{value:(_vm.formData.category),callback:function ($$v) {_vm.$set(_vm.formData, "category", $$v)},expression:"formData.category"}})],1),_c('el-form-item',{attrs:{"label":"产品首图","prop":"imagePath","rules":[
        {
          required: false,
          message: '商品首图不能为空',
        } ]}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"http-request":function (ref) {
            var file = ref.file;

            this$1.uploadBanner(file, 'imagePath');
          },"action":"fake","accept":"image/*","on-preview":_vm.handlePreview,"file-list":_vm.imagePathFileList,"list-type":"picture","limit":1,"on-exceed":_vm.overLimit,"on-remove":function (files, fileList) { return _vm.handleRemove(files, fileList, 'imagePath'); }},model:{value:(_vm.formData.imagePath),callback:function ($$v) {_vm.$set(_vm.formData, "imagePath", $$v)},expression:"formData.imagePath"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png文件，且不超过500kb，414*414 ")])],1)],1),_c('el-form-item',{attrs:{"label":"banner图","prop":"imgUrl","rules":[
        {
          required: false,
          message: 'banner图不能为空',
        } ]}},[_c('el-upload',{staticClass:"banner-uploader",attrs:{"http-request":function (ref) {
            var file = ref.file;

            this$1.uploadBanner(file, 'imgUrl');
          },"action":"fake","accept":"image/*","on-preview":_vm.handlePreview,"file-list":_vm.imgUrlFileList,"list-type":"picture","on-remove":function (files, fileList) { return _vm.handleRemove(files, fileList, 'imgUrl'); }},model:{value:(_vm.formData.imgUrl),callback:function ($$v) {_vm.$set(_vm.formData, "imgUrl", $$v)},expression:"formData.imgUrl"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png文件，且不超过500kb，414*414 ")])],1)],1),_c('el-form-item',{attrs:{"label":"小程序商品介绍图","prop":"detailImgUrl","rules":[
        {
          required: false,
          message: '小程序商品介绍图不能为空',
        } ]}},[_c('el-upload',{staticClass:"banner-uploader",attrs:{"http-request":function (ref) {
            var file = ref.file;

            this$1.uploadBanner(file, 'detailImgUrl');
          },"action":"fake","accept":"image/*","on-preview":_vm.handlePreview,"file-list":_vm.detailImgUrlList,"list-type":"picture","on-remove":function (files, fileList) { return _vm.handleRemove(files, fileList, 'detailImgUrl'); }},model:{value:(_vm.formData.detailImgUrl),callback:function ($$v) {_vm.$set(_vm.formData, "detailImgUrl", $$v)},expression:"formData.detailImgUrl"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png文件，且不超过500kb，414*414 ")])],1)],1),_c('el-form-item',{attrs:{"label":"商品介绍","prop":"introduction","rules":[
        {
          required: true,
          message: '商品介绍不能为空',
        } ]}},[_c('vue-ueditor-wrap',{ref:"ueditor",attrs:{"destroy":false,"config":_vm.myConfig},model:{value:(_vm.formData.introduction),callback:function ($$v) {_vm.$set(_vm.formData, "introduction", $$v)},expression:"formData.introduction"}})],1),_c('el-form-item',{attrs:{"label":"规格参数","prop":"param","rules":[
        {
          required: true,
          message: '规格参数不能为空',
        } ]}},[_c('vue-ueditor-wrap',{ref:"ueditor",attrs:{"destroy":false,"config":_vm.myConfig},model:{value:(_vm.formData.param),callback:function ($$v) {_vm.$set(_vm.formData, "param", $$v)},expression:"formData.param"}})],1),_c('el-form-item',{attrs:{"label":"包装售后","prop":"wareQD","rules":[
        {
          required: true,
          message: '包装售后不能为空',
        } ]}},[_c('el-input',{attrs:{"type":"textarea","placeholder":"请输入包装售后"},model:{value:(_vm.formData.wareQD),callback:function ($$v) {_vm.$set(_vm.formData, "wareQD", $$v)},expression:"formData.wareQD"}})],1),_c('el-form-item',{attrs:{"label":"成本价","prop":"price","rules":[
        {
          required: true,
          message: '成本价不能为空',
        } ]}},[_c('el-input-number',{attrs:{"min":0,"precision":2,"step":1},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}}),_vm._v(" 元 ")],1),_c('el-form-item',{attrs:{"label":"零售价","prop":"jd_price","rules":[
        {
          required: true,
          message: '零售价不能为空',
        } ]}},[_c('el-input-number',{attrs:{"min":0,"precision":2,"step":1},model:{value:(_vm.formData.jd_price),callback:function ($$v) {_vm.$set(_vm.formData, "jd_price", $$v)},expression:"formData.jd_price"}}),_vm._v(" 元 ")],1),_c('el-form-item',{attrs:{"label":"会员价","prop":"retail_price","rules":[
        {
          required: true,
          message: '会员价不能为空',
        } ]}},[_c('el-input-number',{attrs:{"min":0,"precision":2,"step":1},model:{value:(_vm.formData.retail_price),callback:function ($$v) {_vm.$set(_vm.formData, "retail_price", $$v)},expression:"formData.retail_price"}}),_vm._v(" 元 ")],1),_c('el-form-item',{attrs:{"label":"库存","prop":"stock","rules":[
        {
          required: true,
          message: '库存不能为空',
        } ]}},[_c('el-input-number',{attrs:{"min":0,"step":1},model:{value:(_vm.formData.stock),callback:function ($$v) {_vm.$set(_vm.formData, "stock", $$v)},expression:"formData.stock"}})],1),_c('el-form-item',{attrs:{"label":"商品单位","prop":"saleUnit","rules":[
        {
          required: true,
          message: '商品单位不能为空',
        } ]}},[_c('el-input',{staticClass:"small-input",attrs:{"placeholder":"请输入商品单位"},model:{value:(_vm.formData.saleUnit),callback:function ($$v) {_vm.$set(_vm.formData, "saleUnit", $$v)},expression:"formData.saleUnit"}})],1),_c('el-form-item',{attrs:{"label":"产地","prop":"productArea","rules":[
        {
          required: true,
          message: '产地不能为空',
        } ]}},[_c('el-input',{staticClass:"small-input",attrs:{"placeholder":"请输入产地"},model:{value:(_vm.formData.productArea),callback:function ($$v) {_vm.$set(_vm.formData, "productArea", $$v)},expression:"formData.productArea"}})],1),_c('el-form-item',{attrs:{"label":"商品重量","prop":"weight","rules":[
        {
          required: true,
          message: '商品重量',
        } ]}},[_c('el-input',{staticClass:"small-input",attrs:{"placeholder":"请输入商品重量"},model:{value:(_vm.formData.weight),callback:function ($$v) {_vm.$set(_vm.formData, "weight", $$v)},expression:"formData.weight"}}),_vm._v(" kg ")],1),_c('el-form-item',{attrs:{"label":"upc码","prop":"upc"}},[_c('el-input',{staticClass:"small-input",attrs:{"placeholder":"请输入upc码"},model:{value:(_vm.formData.upc),callback:function ($$v) {_vm.$set(_vm.formData, "upc", $$v)},expression:"formData.upc"}})],1),_c('el-form-item',{attrs:{"label":"商品状态","prop":"state","rules":[
        {
          required: true,
          message: '商品状态不能为空',
        } ]}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.formData.state),callback:function ($$v) {_vm.$set(_vm.formData, "state", $$v)},expression:"formData.state"}},_vm._l((_vm.stateOption),function(cItem){return _c('el-option',{key:cItem.id,attrs:{"label":("" + (cItem.name)),"value":cItem.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"供应商","prop":"state","rules":[
        {
          required: true,
          message: '供应商不能为空',
        } ]}},[_c('el-select',{attrs:{"placeholder":"请选择","disabled":_vm.orgDisabled},model:{value:(_vm.formData.org_id),callback:function ($$v) {_vm.$set(_vm.formData, "org_id", $$v)},expression:"formData.org_id"}},_vm._l((_vm.orgOption),function(cItem){return _c('el-option',{key:cItem.id,attrs:{"label":("" + (cItem.org_name)),"disabled":cItem.disabled,"value":cItem.id}})}),1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("保存")]),_c('el-button',{on:{"click":_vm.channel}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }