import axios from './crontab_base'

// 同步商品池内的 sku Id
export const asyncAllSkuId = () => {
  return axios.post('page_num/async')
}

// 同步 sku 详情
export const asyncSkuDetail = () => {
  return axios.post('sku/asyncDetail')
}

// 同步商品分类目录
export const asyncCatalog = () => {
  return axios.post('sku/asyncCatalog')
}

// 同步 sku 价格
export const asyncPrice = () => {
  return axios.post('sku/asyncPrice')
}

// 下线商品数量为 0 的目录
export const checkSkuCount = () => {
  return axios.post('catalog/checkSkuCount')
}
