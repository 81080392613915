import axios from './base'

export const getAccountBalance = ({ type }) => {
  return axios.get('account/balance', {
    params: { type }
  })
}

export const getAccountDetailList = ({
                                       page,
                                       size,
                                       orderId,
                                       startDate,
                                       endDate
                                     }) => {
  return axios.get('account/detailList', {
    params: {
      page,
      size,
      orderId,
      startDate,
      endDate
    }
  })
}
