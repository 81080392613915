<template>
  <el-dialog
          :title="`设置用户商品分类`"
          :visible.sync="dialogVisible"
          @close="close"
          class="page-user__category-tree"
          width="50%">
    <el-tree
            ref="categoryTree"
            :data="tree.data"
            show-checkbox
            node-key="id"
            :default-expanded-keys="tree.defaultExpandedKeys"
            :default-checked-keys="tree.defaultCheckedKeys"
            :props="tree.defaultProps"
            :check-strictly="false">
    </el-tree>
    <span slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="save">保存</el-button>
          </span>
  </el-dialog>
</template>

<script>
  import {allCatalogTree} from '@/api/catalog'
  import {setUserCategory, getUserCategoryChecked} from '@/api/user'

  export default {
    name: 'list',
    data() {
      return {
        dialogVisible: false,
        userId: null,
        tree: {
          data: [],
          defaultExpandedKeys: [],
          defaultCheckedKeys: [],
          defaultProps: {
            children: 'children',
            label: 'name'
          }
        }
      };
    },
    computed: {},
    watch: {},
    methods: {
      async open({id}) {
        this.userId = id
        this.dialogVisible = true
        this.tree.data = await allCatalogTree();
        this.tree.defaultCheckedKeys = await getUserCategoryChecked({ userId: this.userId })
      },
      close() {
        this.dialogVisible = false
        this.clean()
      },
      clean() {
        this.tree.data = []
        this.tree.defaultExpandedKeys = []
        this.tree.defaultCheckedKeys = []
        this.userId = null
      },
      async save() {
        const categoryIds = this.$refs.categoryTree.getCheckedKeys().concat(this.$refs.categoryTree.getHalfCheckedKeys())
        try {
          await setUserCategory({userId: this.userId, categoryIds})
          this.$message({message: '设置成功', type: 'success'});
        } catch (e) {
          console.log(e)
          this.$message({message: '设置失败', type: 'error'});
        }
        this.close()
      },
    },
  }
</script>

<style lang="scss">
  .page-user__category-tree {
    /*.el-tree-node__children {*/
    /*  label.el-checkbox {*/
    /*    display: none;*/
    /*  }*/
    /*}*/
  }
</style>
