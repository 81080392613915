<template>
  <el-dialog
    title="京东订单"
    width="80%"
    :visible.sync="dialogVisible" >
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
      default-expand-all
    />
  </el-dialog>
</template>

<script>
  import JdOrderTypeEnum from '@/enums/jd_order_type';
  import JdOrderStateEnum from '@/enums/jd_order_state'
  import { getJdOrderDetail } from '@/api/jd_order'

  export default {
    name: 'jd_order',
    data() {
      const tableCols = [
        {
          label: '京东订单号ID',
          // width: 110,
          prop: 'jdOrderId'
        },
        {
          label: '订单价 (元)',
          width: 80,
          render: ({ row }) => {
            return (
              <div style="color: red; font-weight: bold;">
                { this.$options.filters.currency(row.orderPrice) }
              </div>
            )
          }
        },
        {
          label: '运费 (元)',
          width: 80,
          render: ({ row }) => {
            return (
              <div>{ this.$options.filters.currency(row.freight) }</div>
            )
          }
        },
        {
          label: '父订单号',
          width: 110,
          prop: 'pOrder',
          render: ({ row }) => {
            return (
              <span>{ row.type === 1 ? row.pOrder.jdOrderId : row.pOrder }</span>
            )
          }
        },
        {
          label: '订单状态',
          render: ({ row }) => {
            const map = {
              0: '取消订单',
              1: '有效'
            }
            return (
              <div>{ map[row.orderState] }</div>
            )
          }
        },
        {
          label: '物流状态',
          render: ({ row }) => {
            const map = {
              0: '新建',
              1: '妥投',
              2: '拒收'
            }
            return (
              <div>{ map[row.state] }</div>
            )
          }
        },
        {
          label: '预占确认状态',
          render: ({ row }) => {
            const map = {
              0: '没确认预占',
              1: '确认预占'
            }
            return (
              <div>{ map[row.submitState] }</div>
            )
          }
        },
        {
          label: '订单类别',
          render: ({ row }) => {
            return (
              <div>{ JdOrderTypeEnum.parse(row.orderType).label }</div>
            )
          }
        },
        {
          label: '订单类型',
          render: ({ row }) => {
            const map = {
              1: '父订单',
              2: '子订单'
            }
            return (
              <div>{ map[row.type] }</div>
            )
          }
        },
        {
          label: '京东状态',
          render: ({ row }) => {
            return (
              <div>{ JdOrderStateEnum.parse(row.jdOrderState).label }</div>
            )
          }
        },
        {
          label: '创建时间',
          prop: 'createOrderTime'
        }
      ]
      return {
        dialogVisible: false,
        expandTableCols: [
          {
            label: 'sku',
            prop: 'skuId'
          },
          {
            label: '商品名称',
            width: 250,
            prop: 'name'
          },
          {
            label: '主商品ID',
            prop: 'oid'
          },
          {
            label: '商品数量',
            prop: 'num'
          },
          {
            label: '京东三级分类',
            prop: 'category'
          },
          {
            label: '企业购价(元)',
            prop: 'price'
          },
          {
            label: '商品未含税金额(元)',
            prop: 'nakedPrice'
          },
          {
            label: '商品税额(元)',
            prop: 'taxPrice'
          },
          {
            label: '商品税率',
            render: ({ row }) => {
              return <div>{ row.tax } %</div>
            }
          }
        ],
        table: {
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({ row }) => {
                return (
                  <div>
                    <h2>子订单</h2>
                    <op-table
                      border
                      style="margin-top: 20px;"
                      size="small"
                      data={row.cOrder || []}
                      cols={tableCols} />
                    <h2 style="margin-top: 20px;">订单商品</h2>
                    <op-table
                      border
                      style="margin-top: 20px;"
                      size="small"
                      data={row.sku}
                      cols={this.expandTableCols} />
                  </div>
                )
              }
            },
            ...tableCols
          ]
        }
      }
    },
    methods: {
      async open(jdOrderId) {
        this.dialogVisible = true
        await this.loadJdOrder({ jdOrderId })
      },
      async loadJdOrder({ jdOrderId }) {
        let data = await getJdOrderDetail({ jdOrderId })
        // 如果为父订单（type===1），则将 pOrder 内的数据提升至最外层
        data = { ...data, ...data.pOrder };
        this.table.data = [data]
      }
    }
  }
</script>

<style scoped>

</style>
