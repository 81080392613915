<template>
  <div class="page-index">
    <el-container>
      <el-header class="flex-row">
        <div>商城管理系统</div>
        <div>
          <span>{{userInfo.nickname || 'admin'}}</span>
          <span @click="logout" class="logout">  logout</span>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">
          <el-menu
            default-active="1-1"
            class="el-menu-vertical"
            @open="handleOpen"
            @close="handleClose">
            <template v-for="(v, index) of menus.filter(vv => vv.isShown)">
              <router-link v-if="v.to" :to="v.to" :key="index" :id="v.to.name">
                <el-menu-item :index="`${index}`">
                  <i :class="v.icon"></i>
                  <span slot="title">{{v.name}}</span>
                </el-menu-item>
              </router-link>
              <el-submenu :index="`${index}`" v-else :key="index">
                <template slot="title">
                  <i :class="v.icon"></i>
                  <span>{{v.name}}</span>
                </template>
                <router-link :to="c.to" v-for="(c, ci) of v.children.filter(vv => vv.isShown)" :key="ci" :id="c.to.name">
                  <el-menu-item :index="`${index}-${ci}`">
                    {{c.name}}
                  </el-menu-item>
                </router-link>
              </el-submenu>
            </template>
          </el-menu>
        </el-aside>
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { logout } from '@/api/auth'
  import { getRoleAuthChecked } from '@/api/admin'
  import { getAuthMenu } from '../enums/left_menu'
  export default {
    name: 'index',
    data() {
      return {
        menus: []
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      })
    },
    async mounted(){
      const { admin_role_id } = this.userInfo || '';
      // const menuAuth = await getAuthByMenu({ roleId: admin_role_id });
      const menuChecked = await getRoleAuthChecked({ roleId: admin_role_id, type: 0 });
      this.menus = getAuthMenu({ menuChecked });

      console.log('this.menus', this.menus)
    },
    methods: {
      handleOpen() {
        console.log('open')
      },
      handleClose() {
        console.log('close')
      },
      async logout() {
        try {
          await logout()
          this.$store.commit('RESET_TOKEN')
          this.$store.commit('RESET_USERINFO')
          this.$message.success('登出成功')
          this.$router.push({ name: 'login' })
        } catch (e) {
          this.$message.error('登出失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-index {
    height: 100%;
    .el-container {
      height: 100%;
    }
    .el-header {
      background: #409EFF;
      font-size: 16px;
      color: #fff;
      line-height: 60px;
      justify-content: space-between;
      padding: 0 40px;
    }
    .el-menu-vertical {
      height: 100%;
    }
    .logout {
      display: inline-block;
      cursor: pointer;
      margin-left: 20px;
    }
  }
</style>
