<template>
  <el-dialog
    title="绑定供应商信息"
    :visible.sync="dialogVisible"
    @close="close"
    width="25%">
    <div class="page-company__org_dialog">
      <el-form :model="formData" size="small" ref="form" label-width="150px">
        <el-form-item label="请选择供应商" prop="info_id">
          <el-select v-model="formData.info_id" placeholder="请选择">
            <el-option
              v-for="item in orgOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import {orgList} from '@/api/org'
  import {setAdminOrg} from '@/api/admin'

  export default {
    name: "org_dialog",
    data() {
      return {
        dialogVisible: false,
        isEditing: false,
        formData: {
          info_id:''
        },
        orgOptions: []
      }
    },
    mounted() {
      this.orgListApi();
    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        this.formData.id = row.id;
      },
      close() {
        this.dialogVisible = false
      },
      async orgListApi() {
        const res = await orgList();
        this.orgOptions = res.map(v => ({value: v.id, label: v.org_name}));
      },
      cancel() {
        this.close();
      },
      async save() {
        const {info_id,id} = this.formData;
        await setAdminOrg({id,info_id})
        this.$emit('refresh')
        this.close();
      }
    }
  }
</script>

<style lang="scss">
  .page-company__org_dialog {

  }
</style>
