<template>
  <el-dialog
          :title="userInfo.admin_role_id == 13 ? '商品批量改价': '批量改价'"
          :visible.sync="dialogVisible"
          width="50%">
    <div class="page-user__list__add-batch">
      <el-upload
              class="upload-demo"
              ref="upload"
              :limit="1"
              :data="params"
              action="/shop/admin/goods/updatePrice"
              :on-remove="handleRemove"
              :on-error="handleError"
              :on-success="handleSuccess"
              :headers="headers"
              :file-list="fileList"
              :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
        <a class="clicked" href="/file/批量改价模板.xlsx">下载模板文件</a>
        <div slot="tip" class="el-upload__tip">
          注意内容全部为文本格式，禁止出现（ # ）和科学计数法。
          请下载模板文件，填写相关内容后，上传文件。只能上传.xlsx文件，且不超过500kb
        </div>
      </el-upload>
    </div>
  </el-dialog>
</template>

<script>
// import { downloadBatchFailList } from '@/api/user'
import store from '@/store'
import {mapState} from 'vuex'
// import dayjs from "dayjs";

export default {
  name: 'add_batch',
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      params: {
        isStaff: false
      },
      headers: {
        'x-jd-token': store.state.user.token
      },
      failDownloadPath: '',
      showFailDownload: false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  mounted() {
    this.params.isStaff = this.userInfo.admin_role_id == 13;
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
    handleRemove() {
      this.$message.success('移除成功')
    },
    handleError(err) {
      this.$message.error(`${err}`)
    },
    handleSuccess(res) {
      console.log(res);
      this.$message.success(`${res.data}`)
      this.dialogVisible = false
      this.$emit('refresh')
    },
    async downloadFail() {
      // const data = await downloadBatchFailList({ filename: this.failDownloadPath })
      // const url = window.URL.createObjectURL(new Blob([data]))
      // const link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.setAttribute('download', `上传失败-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
      // document.body.appendChild(link)
      // link.click();

      window.open(this.failDownloadPath);
      this.dialogVisible = false
    },
    submitUpload() {
      this.$refs.upload.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-user__list__add-batch {
  .clicked {
    text-decoration: underline;
    color: #00a0e9;
    cursor: pointer;
  }
}
</style>
