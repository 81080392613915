<template>
  <el-dialog width="35%" title="设置文字滚动活动" :visible.sync="dialogVisible">
    <el-form :model="formData" ref="formData" label-width="100px" class="demo-formData">
      <el-form-item label="活动" prop="activityId" :rules="[{
        required: true, message: '请选择活动'
      }]">
        <el-select
                filterable
                v-model="formData.activityId"
                placeholder="请选择活动">
          <el-option
                  v-for="(item, index) in activityOptions"
                  :key="index"
                  :label="item.name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save()">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { getNoticeActivity, setNoticeActivity } from '@/api/activity'

  export default {
    name: 'notice',
    data() {
      return {
        dialogVisible: false,
        formData: {
          activityId: ''
        },
        activityOptions: []
      }
    },
    methods: {
      open(activityOptions) {
        this.dialogVisible = true;
        this.activityOptions = activityOptions;
        this.getNoticeActivity();
      },
      async getNoticeActivity() {
        this.formData.activityId = (await getNoticeActivity()).id;
      },
      async save() {
        const {
          activityId
        } = this.formData;
        try {
          await setNoticeActivity({ activityId });
          this.$message.success('设置成功');
        } catch (e) {
          this.$message.error('设置失败');
        }
      }
    }
  }
</script>

<style scoped>

</style>
