import Vue from 'vue'
import store from './store'
import VueRouter from 'vue-router'
import Root from './components/root'
import Index from './pages/index'
import Login from './pages/login/login'
import jdPageNum from './pages/jd/page_num/page_num'
import jdSkuList from './pages/jd/sku/list/list'
import JdInvoiceList from './pages/jd/invoice/list/list'
import jdOrderList from './pages/jd/order/list/list'
import jdOrderIndex from './pages/jd/order/list/index'
import jdOrderNewList from './pages/jd/order/list/new/new'
import jdOrderDeliveredList from './pages/jd/order/list/delivered/delivered'
import jdOrderRejectList from './pages/jd/order/list/reject/reject'
import jdCatalogList from './pages/catalog/list/list'
import JdAccount from './pages/jd/account/account'
import JdMessage from './pages/jd/message/message'
import catalogDetail from './pages/catalog/detail/detail'
import skuGoodsList from './pages/goods/list/list'
import addGoods from './pages/goods/addGoods'
import OrderList from './pages/order/list/list'
import UserList from './pages/user/list/list'
import UserAccountLogList from './pages/user/log_list/log_list'
import RechargeList from './pages/user/recharge/list'
import PickupList from './pages/user/pickup/list'
import ActivityList from './pages/activity/list/list'
import ActivityAddEdit from './pages/activity/add_edit/add_edit'
import DashboardIndex from './pages/dashboard/index'
import BillList from './pages/bill/list/list'
import RefundList from './pages/refund/list/list'
import AdminList from './pages/admin/list/list'
import RoleList from './pages/admin/role_list/role_list'
import CompanyList from './pages/user/company/list/list'
import BannerAddEdit from './pages/user/company/index/add_edit/add_edit'
import CompanyIndexComponent from './pages/user/company/index/indexComponent'
import UserIndexComponent from './pages/user/list/position/indexComponent'
import orgList from './pages/user/org/list/list'
import offlineShopList from './pages/user/offshop/list/list'
import OffOrderList from './pages/order/list/offlist'
import GongGaoList from './pages/gonggao/list/list'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: {
      name: 'dashboard'
    },
    components: {
      default: Index,
      // bottom: Tabbar
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardIndex
      },
      {
        path: '/gonggao',
        name: 'gongGaoList',
        component: GongGaoList
      },
      {
        path: '/page_num',
        name: 'page_num',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'jdPageNum',
            component: jdPageNum
          }
        ]
      },
      {
        path: '/jd_sku',
        name: 'jd_sku',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'jdSkuList',
            component: jdSkuList
          }
        ]
      },
      {
        path: '/jd_order',
        name: 'jd_order',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'jdOrderList',
            component: jdOrderList
          },
          {
            path: 'index',
            name: 'jdOrderIndex',
            redirect: {
              name: 'jdOrderNewList'
            },
            component: jdOrderIndex,
            children: [
              {
                path: 'new',
                name: 'jdOrderNewList',
                component: jdOrderNewList,
              },
              {
                path: 'delivered',
                name: 'jdOrderDeliveredList',
                component: jdOrderDeliveredList,
              },
              {
                path: 'reject',
                name: 'jdOrderRejectList',
                component: jdOrderRejectList,
              }
            ]
          }
        ]
      },
      {
        path: '/jd_account',
        name: 'jdAccount',
        component: JdAccount
      },
      {
        path: '/jd_invoice',
        name: 'jdInvoiceList',
        component: JdInvoiceList
      },
      {
        path: '/jd_message',
        name: 'jdMessage',
        component: JdMessage
      },
      {
        path: '/goods',
        name: 'goods',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'skuGoodsList',
            component: skuGoodsList
          }
        ]
      },
      {
        path: '/add_goods',
        name: 'addGoods',
        component: addGoods
      },
      {
        path: '/catalog',
        name: 'catalog',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'jdCatalogList',
            component: jdCatalogList
          },
          {
            path: 'detail/:catId/:name',
            name: 'catalogDetail',
            component: catalogDetail
          }
        ]
      },
      {
        path: '/order',
        name: 'order',
        redirect: 'orderList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'orderList',
            component: OrderList
          },
          {
            path: 'offlist',
            name: 'offlist',
            component: OffOrderList

          }
        ]
      },
      {
        path: '/bill',
        name: 'bill',
        redirect: 'billList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'billList',
            component: BillList
          }
        ]
      },
      {
        path: '/refund',
        name: 'refund',
        redirect: 'refundList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'refundList',
            component: RefundList
          }
        ]
      },
      {
        path: '/user',
        name: 'user',
        redirect: 'userList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'userList',
            component: UserList
          },
          {
            path: 'position',
            name: 'userIndexComponent',
            component: UserIndexComponent
          }
        ]
      },
      {
        path: '/user_account',
        name: 'userAccount',
        redirect: 'userAccountLogList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'userAccountLogList',
            component: UserAccountLogList
          }
        ]
      },
      {
        path: '/company',
        name: 'company',
        redirect: 'companyList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'companyList',
            component: CompanyList
          },
          {
            path: 'index/:companyId',
            name: 'CompanyIndexComponent',
            component: CompanyIndexComponent
          },
          {
            path: 'add',
            name: 'bannerAdd',
            component: BannerAddEdit
          },
          {
            path: 'edit',
            name: 'bannerEdit',
            component: BannerAddEdit
          }
        ]
      },
      {
        path: '/recharge',
        name: 'recharge',
        redirect: 'rechargeList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'rechargeList',
            component: RechargeList
          }
        ]
      },
      {
        path: '/pickup',
        name: 'pickup',
        redirect: 'pickupList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'pickupList',
            component: PickupList
          }
        ]
      },
      {
        path: '/manage_admin',
        name: 'admin',
        redirect: 'adminList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'adminList',
            component: AdminList
          },
          {
            path: 'role_list',
            name: 'roleList',
            component: RoleList
          },

        ]
      },
      {
        path: '/activity',
        name: 'activity',
        redirect: 'activityList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'activityList',
            component: ActivityList
          },
          {
            path: 'add',
            name: 'activityAdd',
            component: ActivityAddEdit
          },
          {
            path: 'edit',
            name: 'activityEdit',
            component: ActivityAddEdit
          }
        ]
      },
      {
        path: '/org',
        name: 'org',
        redirect: 'org',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'orgList',
            component: orgList
          }
        ]
      }, {
        path: '/offshop',
        name: 'offshop',
        redirect: 'shopList',
        component: Root,
        children: [
          {
            path: 'list',
            name: 'shopList',
            component: offlineShopList
          }
        ]
      },

    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    return next()
  }
  if (store.getters.isLogin) {
    return next()
  }
  next({ name: 'login' })
})

export default router
