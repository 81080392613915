<template>
  <div class="page-pageNum__list">
    <h2>商品池为商品分类三级目录</h2>
    <div style="margin-bottom: 20px;">
      <el-button type="danger" @click="asyncAllSkuId">同步商品池内的 sku Id</el-button>
      <el-button type="danger" @click="asyncSkuDetail">同步 sku 详情</el-button>
      <el-button type="danger" @click="asyncCatalog">同步商品分类目录</el-button>
      <el-button type="danger" @click="asyncPrice">同步 sku 价格</el-button>
      <el-button type="danger" @click="checkSkuCount">下线商品数量为 0 的目录</el-button>
    </div>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="商品池 ID">
        <el-input v-model="formData.pageNum" placeholder="商品池 ID" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadTableData">查询</el-button>
        <el-button type="danger" @click="exportTableData">导出</el-button>
      </el-form-item>
    </el-form>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { getJdPageNum, downloadJdPageNum, asyncPageNum } from '@/api/page_num'
  import { asyncAllSkuId, asyncSkuDetail, asyncCatalog, asyncPrice, checkSkuCount } from '@/api/crontab'

  export default {
    name: 'list',
    data() {
      return {
        isASyncing: false,
        formData: {
          pageNum: '',
          name: ''
        },
        table: {
          data: [],
          cols: [
            {
              prop: 'page_num',
              label: '商品池编号'
            },
            {
              prop: 'name',
              label: '商品池名称'
            },
            {
              label: '是否已同步',
              render: ({ row }) => {
                return (
                  <div>{ row.isAsync ? '是' : '否' }</div>
                )
              }
            },
            {
              label: '操作',
              render: ({ row }) => {
                return (
                  <div>
                    <router-link to={{ name: 'jdSkuList', query: { name: row.name, page_num: row.page_num } }}>
                      <el-button type="primary" size="small">查看池内商品</el-button>
                    </router-link>
                    {
                      // !row.isAsync &&
                      // <el-button type="primary"
                      //            size="small"
                      //            loading={this.isASyncing}
                      //            onClick={() => this.asyncPageNum(row)}>同步</el-button>
                    }
                    <el-button type="primary"
                               size="small"
                               loading={this.isASyncing}
                               onClick={() => this.asyncPageNum(row)}>同步</el-button>
                  </div>
              );
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    created() {
      const { page } = this.$route.query
      if (page) {
        this.table.pagination.currentPage = parseInt(page)
      }
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { pageNum } = this.formData;
        const { data, count } = await getJdPageNum({ page, size, pageNum })
        if (page !== parseInt(this.$route.query.page)) {
          this.$router.push({ query: { page } })
        }
        this.table.data = data
        this.table.pagination.total = count
      },
      async exportTableData() {
        const data = await downloadJdPageNum()
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `商品池数据-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      },
      async asyncPageNum({ name, page_num }) {
        await this.$confirm('该操作将会同步京东商品池（即三级目录），同时也会同步二级、一级目录。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          this.isASyncing = true
          await asyncPageNum({ name, pageNum: page_num })
          this.$message.success(`同步成功`)
          this.isASyncing = false
          setTimeout(() => {
            this.loadTableData();
          }, 500)
        } catch (e) {
          this.isASyncing = false
          this.$message.error(`同步失败`)
        }
      },
      async asyncAllSkuId() {
        await this.$confirm('该操作将会京东商品池内所有商品 SKU ID 同步到 SKU ID 表。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          this.$message.success(`触发同步成功`)
          await asyncAllSkuId()
          // setTimeout(() => {
          //   this.loadTableData();
          // }, 500)
        } catch (e) {
          this.$message.error(e || `触发同步失败`)
        }
      },
      async asyncSkuDetail() {
        await this.$confirm('该操作将会同步 SKU ID 表下所有商品详情。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          this.$message.success(`触发同步成功`)
          await asyncSkuDetail()
          // setTimeout(() => {
          //   this.loadTableData();
          // }, 500)
        } catch (e) {
          this.$message.error(e || `触发同步失败`)
        }
      },
      async asyncCatalog() {
        await this.$confirm('该操作将会根据所有已同步商品详情的分类，同步分类信息。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          this.$message.success(`触发同步成功`)
          await asyncCatalog()
          // setTimeout(() => {
          //   this.loadTableData();
          // }, 500)
        } catch (e) {
          this.$message.error(e || `触发同步失败`)
        }
      },
      async asyncPrice() {
        await this.$confirm('该操作将会所有已同步商品的价格。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          this.$message.success(`触发同步成功`)
          await asyncPrice()
          // setTimeout(() => {
          //   this.loadTableData();
          // }, 500)
        } catch (e) {
          this.$message.error(e || `触发同步失败`)
        }
      },
      async checkSkuCount() {
        await this.$confirm('该操作将会下线商品数量为 0 的目录。请您知悉该操作的影响后，再确认！', '警告', {
          type: 'warning'
        });
        try {
          await checkSkuCount()
          this.$message.success(`触发同步成功`)
          // setTimeout(() => {
          //   this.loadTableData();
          // }, 500)
        } catch (e) {
          this.$message.error(e || `触发同步失败`)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-pageNum__list {
    .async-btn {
      margin-bottom: 20px;
    }
  }
</style>
