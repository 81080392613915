<template>
  <el-dialog width="25%" title="配置推荐位" :visible.sync="dialogVisible">
    <el-form :model="form">
      <el-form-item label="推荐位" label-width="80px">
        <el-select
            filterable
            v-model="form.positionId"
            placeholder="请选择">
          <el-option
                  v-for="(item, index) in positionOptions"
                  :key="index"
                  :label="`${item.title} (${item.dec})`"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { positionList, positionSet } from '@/api/position'

  export default {
    name: 'change',
    data() {
      return {
        dialogVisible: false,
        positionOptions: [],
        form: {
          positionId: ''
        }
      }
    },
    mounted() {
      this.getPositionOptions();
    },
    methods: {
      open({ sku, id }) {
        this.dialogVisible = true;
        this.goodsId = id;
        this.goodsSku = sku;
      },
      async save() {
        const {
          positionId
        } = this.form;
        await this.$confirm('确认修改该商品？', '提示', { type: 'warning' })
        try {
          const { companyId } = this.$route.params
          await positionSet({
            company_id: companyId,
            position_id: positionId,
            goods_id: this.goodsSku
          })
          this.dialogVisible = false;
          this.$emit('refresh', this.goodsId);
          this.$message.success('修改成功')
        } catch (e) {
          this.$message.error('修改失败')
        }
      },
      async getPositionOptions() {
        const { companyId } = this.$route.params
        this.positionOptions = await positionList({ company_id: companyId });
      }
    }
  }
</script>

<style scoped>

</style>
