import axios from './base'

export const getGoodsList = ({page, size, sku, name, catId, state, org_id} = {}) => {
  return axios.get('goods/list', {
    params: {
      page, size, sku, name, catId, state, org_id
    }
  })
}

// TODO 查库存需要地址
// export const getGoodsList = ({ page, size, sku, catId, state } = {}) => {
//   return axios.get('goods/getGoodsStock', {
//     params: {
//       page, size, sku, catId, state
//     }
//   })
// }

export const changeGoodsState = ({sku, state} = {}) => {
  return axios.post('goods/changeState', {
    sku, state
  })
}

export const changePrice = ({sku, retailPrice} = {}) => {
  return axios.post('goods/changePrice', {
    sku, retailPrice
  })
}

export const changePriceNotSync = ({sku, isPriceNotSync} = {}) => {
  return axios.post('goods/changePriceNotSync', {
    sku, isPriceNotSync
  })
}

export const changeSyncPriceRate = ({syncPriceRate} = {}) => {
  return axios.post('goods/changeSyncPriceRate', {
    syncPriceRate
  })
}

export const getSyncPriceRate = () => {
  return axios.get('goods/getSyncPriceRate')
}

export const saveGoods = (
    {
      id,
      name,
      brandName,
      category,
      imagePath,
      imgUrl,
      introduction,
      param,
      price,
      jd_price,
      retail_price,
      profit,
      productArea,
      saleUnit,
      sku,
      state,
      upc,
      wareQD,
      weight,
      create_time,
      update_time,
      company_id,
      org_id,
      detail_img_url,
      stock
    }) => {
  return axios.post('goods/saveGoods', {
    id,
    name,
    brandName,
    category,
    imagePath,
    imgUrl,
    introduction,
    param,
    price,
    jd_price,
    retail_price,
    profit,
    productArea,
    saleUnit,
    sku,
    state,
    upc,
    wareQD,
    weight,
    create_time,
    update_time,
    company_id,
    org_id,
    detail_img_url,
    stock
  })
}
export const getDetail = ({id} = {}) => {
  return axios.get('goods/detail', {params: {id}})
}

export const searchGoods = (({catId, keyword} = {}) => {
  return axios.get('goods/searchGoods', {params: {catId, keyword}})

})

export const download = ({
                           sku, name, state, catId, orgId
                         }) => {
  return axios.post('goods/download', {
    sku, name, state, catId, orgId
  }, {
    responseType: 'blob'
  })
}
