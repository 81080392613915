<template>
  <el-dialog
    :title="`${ isEditing ? '修改' : '新建' }公司信息`"
    :visible.sync="dialogVisible"
    @close="close"
    width="50%">
    <div class="page-company__upsert">
      <el-form :model="formData" size="small" ref="form" label-width="150px">
        <el-form-item
          label="公司名称"
          prop="name"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.name"
            placeholder="请输入公司名称"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="纳税识别号"
          prop="tax_id"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.tax_id"
            placeholder="请输入纳税识别号"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="地址"
          prop="address"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.address"
            placeholder="请输入公司地址"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="开户行"
          prop="bank"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.bank"
            placeholder="请输入公司开户行"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="对公账号"
          prop="public_account"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.public_account"
            placeholder="请输入对公账号"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="公司电话"
          prop="phone"
          :rules="[
          {
            required: true,
            message: '不能为空'
          },
          {
            min: 11,
            max: 11,
            message: '不是有效的电话号码'
          }
        ]"
        >
          <el-input
            v-model="formData.phone"
            placeholder="请输入公司电话"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="联系人"
          prop="contact"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.contact"
            placeholder="请输入联系人"
          ></el-input
          ></el-form-item>
        <el-form-item
                label="业务类型"
                prop="type"
                :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-select v-model="formData.type" clearable placeholder="请选择公司类型">
            <el-option
                    v-for="(item, index) in typeOp"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
            >
            </el-option>
          </el-select></el-form-item>
        <el-form-item
          label="联系人电话"
          prop="contact_phone"
          :rules="[
          {
            required: true,
            message: '不能为空'
          },
          {
            min: 11,
            max: 11,
            message: '不是有效的电话号码'
          }
        ]"
        >
          <el-input
            v-model="formData.contact_phone"
            placeholder="请输入联系人电话"
          ></el-input
          ></el-form-item>

        <el-form-item
          label="联系人邮箱"
          prop="contact_mail"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input
            v-model="formData.contact_mail"
            placeholder="请输入联系人邮箱"
          ></el-input
          ></el-form-item>
        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import { getCompanyDetail, upsertCompany } from '@/api/company';

export default {
  name: 'upsert',

  data() {
    return {
      dialogVisible: false,
      isEditing: false,
        typeOp:[
            {label:'工会',value:1},
            {label:'核电',value:2},
            {label:'普通',value:3},
            {label:'餐补',value:4},
            {label:'供电',value:5}
        ],
      formData: {
          type:'',
        name: '',
        tax_id: '',
        address: '',
        bank: '',
        public_account: '',
        phone: '',
        contact: '',
        contact_phone: '',
        contact_mail: ''
      }
    };
  },
  methods: {
    open(id) {
      this.dialogVisible = true
      this.loadCompanyData(id)
    },
    close() {
      this.dialogVisible = false
    },
    async loadCompanyData(id) {
      this.isEditing = !!id
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      if (!this.isEditing) {
        this.formData = {
          name: '',
          tax_id: '',
          address: '',
          bank: '',
          public_account: '',
          phone: '',
          contact: '',
          contact_phone: '',
          contact_mail: ''
        }
        return
      }
      const company = await getCompanyDetail({ id })
      delete company.create_time
      delete company.update_time
      this.formData = company
    },
    async save() {
      if (!(await this.$refs.form.validate().catch(() => false))) {
        return
      }

      try {
        await upsertCompany(this.formData)
        this.$message.success('保存成功')
        this.close()
        this.$emit('refresh')
      } catch (e) {
        this.$message.error('创建失败：' + e)
      }
    }
  }
};
</script>

<style lang="scss">
.page-company__upsert {
  .el-form {
    max-width: 660px;
    .el-select .el-input {
      max-width: 360px;
    }
    .line {
      text-align: center;
    }
    .el-radio {
      margin-bottom: 20px;
    }
  }
}
</style>
