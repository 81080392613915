<template>
  <div class="op-goods-list">
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="商品 SKU">
        <el-input v-model="formData.sku" placeholder="商品 SKU" clearable/>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input v-model="formData.name" placeholder="商品名称" clearable/>
      </el-form-item>
      <el-form-item label="商品状态">
        <el-select v-model="formData.state" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
                  v-for="(item, index) in skuStateOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品分类">
        <el-cascader
                :props="props"
                v-model="formData.catId"
                placeholder="请选择"
                @change="loadTableData"
                :show-all-levels="false"
                clearable></el-cascader>
      </el-form-item>
      <el-form-item v-if="adminRoleId !== 4" label="供应商 ID">
        <el-select v-model="formData.orgId" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
                  v-for="(item, index) in orgList"
                  :key="index"
                  :label="item.org_name"
                  :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="primary" @click="download" :loading="isDownloading">下载</el-button>
      </el-form-item>
    </el-form>
    <op-table
            ref="Table"
            size="small"
            @select="(v, i) => $emit('select', v, i)"
            @selection-change="(v) => $emit('selection-change', v)"
            :data="table.data"
            :cols="table.cols"
            :pagination="table.pagination">
      <slot></slot>
    </op-table>
  </div>
</template>

<script>
import config from '@/config'
import {getGoodsList, changePriceNotSync, download} from '@/api/goods'
import {getCatalogList} from '@/api/catalog'
import {orgList} from '@/api/org'
import store from '@/store'
import dayjs from 'dayjs'

export default {
  name: 'goods_list',
  data() {
    return {
      props: {
        checkStrictly: true,
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          // 第 3 层时直接返回空数组
          if (level === 3) {
            return resolve([])
          }
          // 第 0 层时 parentId 为 0
          const parentId = level === 0 ? 0 : value
          const {data} = await getCatalogList({parentId, size: 100})
          const result = data.map(item => ({
            label: item.name,
            value: item.catId,
            leaf: level >= 2
          }))
          resolve(result)
        }
      },
      skuStateOptions: [
        {
          value: 3,
          label: '同步异常'
        },
        {
          value: 1,
          label: '已上架'
        },
        {
          value: 0,
          label: '已下架'
        }
      ],
      formData: {
        sku: '',
        name: '',
        catId: [],
        state: 1,
        orgId: ''
      },
      orgList: [],
      adminRoleId: store.state.user.userInfo.admin_role_id,
      table: {
        data: [],
        cols: [
          {
            type: 'expand',
            render: ({row}) => {
              return (
                      <el-form labelPosition="left" inline class="table-expand">
                        <el-form-item label="商品名称">
                          <span>{row.name}</span>
                        </el-form-item>
                        <el-form-item label="包装清单">
                          <span>{row.wareQD}</span>
                        </el-form-item>
                        <el-form-item label="商品 SKU">
                          <span>{row.sku}</span>
                        </el-form-item>
                        <el-form-item label="商品分类">
                          <span>{row.categoryName}</span>
                        </el-form-item>
                        <el-form-item label="库存">
                          <span>{row.stock}</span>
                        </el-form-item>
                        <el-form-item label="品牌名称">
                          <span>{row.brandName}</span>
                        </el-form-item>
                        <el-form-item label="产地">
                          <span>{row.productArea}</span>
                        </el-form-item>
                        <el-form-item label="商品重量">
                          <span>{row.weight} kg</span>
                        </el-form-item>
                        <el-form-item label="upc">
                          <span>{row.upc}</span>
                        </el-form-item>
                        <el-form-item label="销售单位">
                          <span>{row.saleUnit}</span>
                        </el-form-item>
                        <el-form-item label="更新时间">
                          <span>{row.update_time}</span>
                        </el-form-item>
                      </el-form>
              )
            }
          },
          {
            prop: 'sku',
            label: '商品sku编号',
            width: 100
          },
          {
            prop: 'name',
            label: '商品sku名称',
            width: 140
          },
          {
            label: '商品图片',
            width: 120,
            render: ({row}) => {
              let imagePath = row.imagePath
              if (row.imagePath.indexOf('https:') < 0) {
                imagePath = config.jd.imageHostN3 + row.imagePath
              }
              return (
                      <el-image style="width: 100px;"
                                previewSrcList={row.imgUrl.split(';')}
                                src={imagePath}/>
              );
            }
          },
          {
            header: () => {
              return (
                      <span>
                          <span style="display: block;">利润 (元)</span>
                          <span style="display: block;">利润率</span>
                          </span>
              )
            },
            width: 100,
            render: ({row}) => {
              return (
                      <div>
                        <div style="font-weight: bold;">
                          {row.profit && this.$options.filters.currency(row.profit)}
                        </div>
                        (<span>{(row.profit / row.jd_price).toFixed(2) * 100}%</span>)
                      </div>
              )
            }
          },
          {
            label: '售卖价 (元)',
            width: 80,
            render: ({row}) => {
              return (
                      <div style="color: blue; font-weight: bold;">
                        {row.retail_price && this.$options.filters.currency(row.retail_price)}
                      </div>
              )
            }
          },
          {
            header: () => {
              return (
                      <span>
                    <span style="display: block;">企业购价</span>
                    <span style="display: block;">(京东价)(元)</span>
                  </span>
              )
            },
            width: 100,
            render: ({row}) => {
              return (
                      <div>
                        <div style="font-weight: bold;">
                          {row.price && this.$options.filters.currency(row.price)}
                        </div>
                        (<span>{row.jd_price && this.$options.filters.currency(row.jd_price)}</span>)
                      </div>
              )
            }
          },
          {
            prop: 'categoryName',
            label: '商品分类'
          },
          {
            width: 95,
            header: () => {
              return (
                      <span>
                    同步白名单
                    <el-tooltip className="item" effect="dark" content="打开后同步价格时，售卖价将不会根据京东侧的企业购价变更" placement="top">
                      <i class="el-icon-warning"></i>
                    </el-tooltip>
                  </span>
              )
            },
            render: ({row}) => {
              return (
                      <el-switch
                              value={row.isPriceNotSync}
                              active-color="#13ce66"
                              onInput={(value) => this.changePriceNotSync(value, row)}
                              active-value={1}
                              inactive-value={0}>
                      </el-switch>
              )
            }
          },
          {
            prop: 'state',
            label: '商品状态',
            width: 80,
            render: ({row}) => {
              const stateMap = {
                3: '同步异常',
                1: '已上架',
                0: '已下架'
              }
              return <div>{stateMap[row.state] || ''}</div>
            }
          },
        ],
        pagination: {
          total: 0,
          pageSize: 20,
          currentPage: 1,
          pageSizes: [20, 40, 60, 80],
          layout: 'total, sizes, prev, pager, next, jumper',
          on: {
            'current-change': (currentPage) => {
              this.table.pagination.currentPage = currentPage
              this.loadTableData()
            },
            'size-change': (size) => {
              this.table.pagination.pageSize = size
              this.loadTableData()
            }
          }
        }
      }
    }
  },
  computed: {
    $table() {
      return this.$refs.Table.$refs.OpTable
    }
  },
  created() {
    if (this.adminRoleId === 4) {
      this.formData.orgId = store.state.user.userInfo.info_id;
    }
    const {page} = this.$route.query
    if (page) {
      this.table.pagination.currentPage = parseInt(page)
    }
    this.loadTableData()
    this.loadCatalogList()
    this.orgListApi();
  },
  methods: {
    search() {
      this.table.pagination.currentPage = 1;
      this.loadTableData();
    },
    async loadTableData() {
      const {currentPage: page, pageSize: size} = this.table.pagination
      const {sku, name, catId: catIds, state, orgId} = this.formData
      const {data, count} = await getGoodsList({
        page,
        size,
        sku,
        name,
        catId: catIds[catIds.length - 1],
        state,
        org_id: orgId
      })
      // if (page !== parseInt(this.$route.query.page)) {
      //   this.$router.push({ query: { page } })
      // }
      this.table.data = data
      this.table.pagination.total = count
      this.$emit('data-change', {data, count})
    },
    async loadCatalogList() {
      const {data} = await getCatalogList({page: 1, size: 5000})
      this.catalogOptions = data
    },
    async orgListApi() {
      this.orgList = await orgList();
    },
    async download() {
      const {sku, name, catId: catIds, state, orgId} = this.formData
      const data = await download({
        sku,
        name,
        catId: catIds[catIds.length - 1],
        state,
        orgId: orgId
      });

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', `商品列表-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
      document.body.appendChild(link)
      link.click()
    },
    async changePriceNotSync(value, {sku}) {
      const msg = value ?
              '打开后同步价格时，售卖价将不会根据京东侧的企业购价变更。确定打开？' :
              '关闭后，售卖价将根据京东侧的企业购价 1.25 倍变更（同步变更时间为每日 0 点 30 分整）'
      try {
        await this.$confirm(msg, '提示', {type: 'warning'})
        await changePriceNotSync({sku, isPriceNotSync: value})
        this.loadTableData()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss">
.op-goods-list {
  .operation {
    a + .el-button, a + a, .el-button + .el-button {
      margin-left: 10px;
    }
  }

  .table-expand {
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 50%;
    }

    .el-form-item__label {
      width: 90px;
      color: #99a9bf;
    }

    .el-form-item__content {
      width: 80%;
    }
  }
}
</style>
