<template>
  <el-dialog
    title="修改用户信息"
    width="30%"
    :visible.sync="dialogVisible" >
    <el-form :model="formData" class="demo-form-inline" label-width="120px">
      <el-form-item label="用户手机">
        {{formData.mobile}}
      </el-form-item>
      <el-form-item prop="number" label="余额">
        <el-input-number v-model="formData.account_amount" :min="0" label="描述文字" />
      </el-form-item>
<!--      <el-form-item label="公司">-->
<!--        <el-select v-model="formData.company_id" placeholder="请选择">-->
<!--          <el-option-->
<!--                  v-for="(item, index) in companyList"-->
<!--                  :key="index"-->
<!--                  :label="item.name"-->
<!--                  :value="item.id">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { editUserInfo } from '@/api/user'

  export default {
    name: 'account_log',
    data() {
      return {
        dialogVisible: false,
        formData: {
          company_id: '',
          account_amount: 0,
          mobile: '',
          id: ''
        },
        companyList: []
      }
    },
    methods: {
      open(formData, companyList) {
        this.dialogVisible = true
        this.formData = formData
        this.companyList = companyList
      },
      async save() {
        const {
          company_id: companyId,
          account_amount: accountAmount,
          mobile,
          id: userId
        } = this.formData;
        try {
          await editUserInfo({ companyId, accountAmount, mobile, userId })
          this.dialogVisible = false
          this.$message.success('修改成功')
          this.$emit('refresh')
        } catch (e) {
          this.$message.error('修改失败：' + e)
        }
      }
    }
  }
</script>

<style scoped>

</style>
