import './index.scss'

export default {
  name: 'OpTable',
  props: {
    data: {
      type: Array,
      required: true
    },
    cols: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object
    },
    slots: {
      type: Object
    }
  },
  data() {
    return {

    }
  },
  render() {
    return (
      <div class="op-table">
        <el-table
          ref="OpTable"
          data={this.data}
          {...{ attrs: this.$attrs }}
          {...{ props: this.$props }}
          {...{ on: this.$listeners }}
          stripe
          style="width: 100%">
          {
            this.cols.map(item =>
              <el-table-column {...{ props: item }}
               {...{ scopedSlots: {
                 default: item.render ? (scope) => item.render(scope) : '',
                 header: item.header ? (scope) => item.header(scope) : ''
               }}} />
            )
          }
          {
            // TODO 有点问题
            // this.slots || this.$slots.default
            this.$slots.default
          }
        </el-table>
        {
          this.pagination &&
          <el-pagination
            background
            layout="prev, pager, next"
            {...{ props: this.pagination }}
            {...{ on: this.pagination.on }}
          />
        }
      </div>
    )
  }
}
