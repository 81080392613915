<template>
  <el-dialog width="35%" :title="`${text}推荐位`" :visible.sync="dialogVisible">
    <el-form :model="formData" ref="formData" label-width="100px" class="demo-formData">
      <el-form-item label="模块ID" prop="id" v-if="formData.id">
        {{formData.id}}
      </el-form-item>
      <el-form-item label="模块名称" prop="title" :rules="[{
        required: true, message: '名称不能为空'
      }]">
        <el-input v-model="formData.title" placeholder="请输入模块名称"></el-input>
      </el-form-item>
      <el-form-item label="上线状态" prop="state">
        <el-switch
                :active-value="1"
                :inactive-value="0"
                v-model="formData.state"></el-switch>
      </el-form-item>
      <el-form-item label="模块类型" prop="type">
        <el-radio-group v-model="formData.type">
          <el-radio :label="1">首页精选模块</el-radio>
          <el-radio :label="2">首页滚动列表</el-radio>
          <!--          <el-radio :label="3">备选项</el-radio>-->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="模块描述" prop="dec">
        <el-input type="textarea" v-model="formData.dec"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="save()">保存</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import {editPosition} from '@/api/position'

  export default {
    name: 'edit',
    data() {
      return {
        dialogVisible: false,
        isEditing: false,
        formData: {
          id: '',
          title: '',
          dec: '',
          type: 1,
          state: 0
        }
      }
    },
    computed: {
      text() {
        return this.isEditing ? '修改' : '新增'
      }
    },
    methods: {
      open(data) {
        this.dialogVisible = true
        if (!data) {
          this.isEditing = false
          this.formData = {
            id: '',
            title: '',
            dec: '',
            type: 1,
            state: 0
          }
          return
        }
        this.isEditing = true
        const {id, title, dec, state, type} = data
        this.formData = {id, title, dec, state, type}
      },
      async save() {
        await this.$confirm(`确认${this.text}模块？`, '提示', {type: 'warning'})
        try {
          const {id, title, dec, state, type} = this.formData
          await editPosition({
            id, title, dec, state, type
          })
          this.dialogVisible = false;
          this.$emit('refresh');
          this.$message.success(`${this.text}成功`)
        } catch (e) {
          this.$message.error(`${this.text}失败`)
        }
      }
    }
  }
</script>

<style scoped>

</style>
