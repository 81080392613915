<template>
  <div class="page-message">
    <h2>查询京东消息</h2>
    <el-tabs v-model="pType" type="border-card" class="message-container">
      <el-tab-pane
        v-for="(item, index) in typeOptions"
        :key="index"
        :label="item.label"
        :name="item.value" >
        <el-tabs v-model="type" @tab-click="loadMessageList">
          <el-tab-pane
            v-for="(cItem, cIndex) in item.children"
            :key="cIndex"
            :label="cItem.label"
            :name="`${cItem.value}`" >
            <div class="message-list">
<!--              {{cItem.label}}-->
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import { getMessageList } from '@/api/message'
  import JdMessageType from '@/enums/jd_message_type'

  export default {
    name: 'message',
    data() {
      return {
        type: '',
        pType: 'order',
        typeOptions: []
      }
    },
    created() {
      const map = {}
      const items = Object.values(JdMessageType)
      items.forEach(item => {
        if (!map[item.type.value]) {
          map[item.type.value] = []
        }
        map[item.type.value].push(item)
      })
      this.typeOptions = Object.entries(map).map(item => ({
        value: item[1][0].type.value,
        label: item[1][0].type.label,
        children: item[1]
      }))
    },
    methods: {
      async loadMessageList() {
        const { type } = this
        try {
          await getMessageList({ type })
        } catch (e) {
          this.$message.error(e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-message {
    .message-container {
      min-height: 400px;
    }
  }
</style>
