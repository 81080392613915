<template>
  <div class="page-login">
    <h2>商城管理系统</h2>
    <el-form :model="formData">
      <el-form-item label="用户名">
        <el-input v-model="formData.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="formData.password" @keypress.native.enter="login" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="login" type="primary">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { login } from '@/api/auth'

  export default {
    name: 'login',
    data() {
      return {
        formData: {
          username: '',
          password: ''
        }
      }
    },
    methods: {
      async login() {
        const { username, password } = this.formData
        try {
          const { userInfo, token } = await login({
            username, password
          })
          this.$message.success('登录成功')
          this.$store.commit('SET_USERINFO', userInfo)
          this.$store.commit('SET_TOKEN', token)
          if (userInfo.admin_role_id === 1) {
              this.$router.push({
                  name: 'index'
              })
          } else {
              this.$router.push({
                  name: 'orderList'
              })
          }
        } catch (e) {
          this.$message.error('登录失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-login {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .el-form {
      background: #f5f5f5;
      /*border: 1px solid #409EFF;*/
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      padding: 20px;
      width: 350px;
      .el-button {
        width: 100%;
      }
    }
  }
</style>
