<template>
    <el-dialog
            :title="`${ isEditing ? '修改' : '新建' }线下店信息`"
            :visible.sync="dialogVisible"
            @close="close"
            width="50%">
        <div class="page-offshop__upsert">
            <el-form :model="formData" size="small" ref="form" label-width="150px" :rules="rules">
                <el-form-item label="公司名称" prop="shop_name">
                    <el-input v-model="formData.shop_name" placeholder="请输入线下店名称"/>
                </el-form-item>

                <el-form-item label="纳税识别号" prop="tax_id">
                    <el-input v-model="formData.tax_id" placeholder="请输入纳税识别号"/>
                </el-form-item>

                <el-form-item label="头像" prop="avatar">
                    <el-upload
                            v-model="formData.avatar"
                            class="avatar-uploader"
                            action="fake"
                            accept="image/*"
                            :http-request="({file})=>{this.uploadAvatar(file, 'avatar')}"
                            :show-file-list="false"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="formData.avatar" :src="formData.avatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item label="简介" prop="introduction">
                    <el-input  type="textarea" rows="2" v-model="formData.introduction" placeholder="请输入简介"/>
                </el-form-item>

                <el-form-item label="地址" prop="address">
                    <el-input v-model="formData.address" placeholder="请输入公司地址" />
                </el-form-item>

                <el-form-item label="开户行" prop="bank">
                    <el-input v-model="formData.bank" placeholder="请输入公司开户行" />
                </el-form-item>

                <el-form-item label="对公账号" prop="public_account">
                    <el-input v-model="formData.public_account" placeholder="请输入对公账号" />
                </el-form-item>

                <el-form-item label="公司电话" prop="phone">
                    <el-input v-model="formData.phone" placeholder="请输入公司电话" />
                </el-form-item>

                <el-form-item label="联系人" prop="contact">
                    <el-input v-model="formData.contact" placeholder="请输入联系人" />
                </el-form-item>

                <el-form-item label="手机号码" prop="contact_phone">
                    <el-input v-model="formData.contact_phone" placeholder="请输入联系人手机号码" />
                </el-form-item>

                <el-form-item label="密码" prop="password">
                    <el-input v-model="formData.password" placeholder="密码" />
                </el-form-item>
                <el-form-item label="确认密码" prop="confirm_password">
                    <el-input v-model="formData.confirm_password" placeholder="确认密码" />
                </el-form-item>

                <el-form-item label="联系人邮箱" prop="contact_mail">
                    <el-input v-model="formData.contact_mail" placeholder="请输入联系人邮箱" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save">保存</el-button>
                    <el-button @click="close">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
    import {offshopSaveDetail} from '@/api/offshop';
    import axios from '@/api/base'

    export default {
        name: 'upsert',

        data() {
            return {
                dialogVisible: false,
                isEditing: false,
                defaultData:{
                    id:'',
                    shop_name: '',
                    tax_id: '',
                    avatar:'',
                    introduction: '',
                    address: '',
                    bank: '',
                    public_account: '',
                    phone: '',
                    contact: '',
                    contact_phone: '',
                    contact_mail: '',
                    password: '',
                    confirm_password: ''
                },
                formData: {},
                fileList:[],
                avatarUrl:'',
                rules: {
                    shop_name: [
                        {required: true, message: '请输入活动名称', trigger: 'blur'}
                    ],
                    tax_id: [
                        {required: true, message: '请输入纳税识别号', trigger: 'blur'}
                    ],
                    avatar: [
                        {required: true, message: '请上传头像', trigger: 'blur'}
                    ],
                    introduction: [
                        {required: true, message: '请输入简介', trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请输入公司地址', trigger: 'blur'}
                    ],
                    bank:[
                        {required: true, message: '请输入开户行', trigger: 'blur'}
                    ],
                    public_account:[
                        {required: true, message: '请输入对公账号', trigger: 'blur'}
                    ],
                    phone:[
                        {required: true, message: '请输入公司电话', trigger: 'blur'},
                        { min: 11, max: 11, message: '不是有效的电话号码', trigger: 'blur'}
                    ],
                    contact:[
                        {required: true, message: '请输入联系人', trigger: 'blur'}
                    ],
                    contact_phone:[
                        {required: true, message: '请输入联系人电话', trigger: 'blur'},
                        { min: 11, max: 11, message: '不是有效的电话号码', trigger: 'blur'}
                    ],
                    password:[
                        {required: true, message: '请输入密码', trigger: 'blur'}
                    ],
                    confirm_password:[
                        {required: true, message: '请输入确认密码', trigger: 'blur'}
                    ],
                    contact_mail:[
                        {required: true, message: '请输入联系人邮箱', trigger: 'blur'}
                    ],
                }
            };
        },
        methods: {
            open(row) {
                this.dialogVisible = true
                if (row) {
                    this.formData = row
                    this.formData['password'] = '';
                    this.isEditing = true;
                } else {
                    this.formData = this.defaultData;
                    this.isEditing = '';
                }
            },
            close() {
                this.dialogVisible = false
            },
            async save() {
                const { password, confirm_password } = this.formData;

                if (!(await this.$refs.form.validate().catch(() => false))) {
                    return
                }

                if (password !== confirm_password) {
                    this.$message.error('两次密码不一致');
                    return;
                }

                try {
                    await offshopSaveDetail(this.formData)
                    this.$message.success('保存成功')
                    this.close()
                    this.$emit('refresh')
                } catch (e) {
                    this.$message.error('创建失败：' + e)
                }
            },
            async uploadAvatar(file, itemName) {
                console.log(file, itemName)
                try {
                    const formData = new FormData();
                    formData.append('file', file, file.name);
                    const url = await axios.post('/file/upload2', formData);
                    this.$message.success('上传成功');
                    if(itemName === 'imgUrl'){
                        console.log(url)
                        this.formData[itemName] += url+';';
                    }else{
                        this.formData[itemName] = url;

                    }
                } catch (e) {
                    this.$message.error(e);
                }
            },
            beforeAvatarUpload(file) {

                console.log(file.type)
                const isJPG = file.type === 'image/jpeg'||'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            }
        }
    };
</script>

<style lang="scss">
    .page-offshop__upsert {
        .el-form {
            max-width: 660px;

            .el-select .el-input {
                max-width: 360px;
            }

            .line {
                text-align: center;
            }

            .el-radio {
                margin-bottom: 20px;
            }
        }
        .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }
    }
</style>
