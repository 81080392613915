import axios from './base';

export const getProvinceList = () => {
  return axios.get('region/province');
};

export const getCityList = parentId => {
  return axios.get('region/city', {
    params: { parentId }
  });
};

export const getCountyList = parentId => {
  return axios.get('region/county', {
    params: { parentId }
  });
};

export const getTownList = parentId => {
  return axios.get('region/town', {
    params: { parentId }
  });
};
