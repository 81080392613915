<template>
  <div class="page-jd-order__list">
    <h2>京东订单列表</h2>
    <op-table
      style="margin-top: 20px;"
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination" />
  </div>
</template>

<script>
  import { getJdOrderList, confirmOrder, cancelOrder } from '@/api/jd_order'

  export default {
    name: 'list',
    data() {
      return {
        expandTableCols: [
          {
            label: 'sku',
            prop: 'skuId'
          },
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '主商品ID',
            prop: 'oid'
          },
          {
            label: '商品数量',
            prop: 'num'
          },
          {
            label: '京东三级分类',
            prop: 'category'
          },
          {
            label: '企业购价(元)',
            prop: 'price'
          },
          {
            label: '商品未含税金额(元)',
            prop: 'nakedPrice'
          },
          {
            label: '商品税额(元)',
            prop: 'taxPrice'
          },
          {
            label: '商品税率',
            render: ({ row }) => {
              return <div>{ row.tax } %</div>
            }
          }
        ],
        table: {
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({ row }) => {
                return (
                  <op-table
                    border
                    style="margin-top: 20px;"
                    size="small"
                    data={row.info.sku}
                    cols={this.expandTableCols} />
                )
              }
            },
            {
              label: '京东订单号ID',
              width: 110,
              prop: 'jdOrderId'
            },
            {
              label: '订单价 (元)',
              width: 80,
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.orderPrice) }</div>
                )
              }
            },
            {
              label: '运费 (元)',
              width: 80,
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.info.freight) }</div>
                )
              }
            },
            {
              label: '父订单号',
              width: 80,
              prop: 'info.pOrder'
            },
            {
              label: '订单状态',
              render: ({ row }) => {
                const map = {
                  0: '取消订单',
                  1: '有效'
                }
                return (
                  <div>{ map[row.info.orderState] }</div>
                )
              }
            },
            {
              label: '物流状态',
              render: ({ row }) => {
                const map = {
                  0: '新建',
                  1: '妥投',
                  2: '拒收'
                }
                return (
                  <div>{ map[row.info.state] }</div>
                )
              }
            },
            {
              label: '预占确认状态',
              render: ({ row }) => {
                const map = {
                  0: '没确认预占',
                  1: '确认预占'
                }
                return (
                  <div>{ map[row.info.submitState] }</div>
                )
              }
            },
            {
              label: '订单类型',
              render: ({ row }) => {
                const map = {
                  1: '父订单',
                  2: '子订单'
                }
                return (
                  <div>{ map[row.info.type] }</div>
                )
              }
            },
            {
              label: '创建时间',
              prop: 'create_time'
            },
            {
              label: '操作',
              width: 150,
              render: ({ row }) => {
                return (
                  <div>
                    {
                      row.info.submitState === 0 && (
                        <span>
                          <el-button type="primary" size="small" onClick={() => this.confirmOrder(row)}>确认</el-button>
                          <el-button type="danger" size="small" onClick={() => this.cancelOrder(row)}>取消</el-button>
                        </span>
                      )
                    }
                  </div>
                )
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    created() {
      this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const { data, count } = await getJdOrderList({ page, size })
        this.table.data = data
        this.table.pagination.total = count
      },
      async confirmOrder({ jdOrderId, orderPrice }) {
        await this.$confirm(`确认订单后将扣除账户 ${orderPrice} 元，是否确认？`, '提示', { type: 'warning' })
        await confirmOrder({ jdOrderId })
        this.$message.success('确认成功，请查看账户明细')
      },
      async cancelOrder({ jdOrderId }) {
        await this.$confirm(`是否取消？`, '提示', { type: 'warning' })
        await cancelOrder({ jdOrderId })
        this.$message.success('取消成功')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-jd-order__list {
    .table-expand {
      .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
      }
      .el-form-item__label {
        width: 90px;
        color: #99a9bf;
      }
      .el-form-item__content {
        width: 80%;
      }
    }
  }
</style>
