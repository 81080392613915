import { BaseEnum } from './base';

const PayStatusEnum = new BaseEnum({
  ALL: {
    key: 'ALL',
    value: '',
    label: '全部',
    handleOptions: {
    }
  },
  PAYING: {
    key: 'PAYING',
    value: 1,
    label: '待审核',
    handleOptions: {
    }
  },
  SUCCESSFUL: {
    key: 'SUCCESSFUL',
    value: 2,
    label: '支付成功',
    handleOptions: {
    }
  },
  FINISHED: {
    key: 'FAILED',
    value: 3,
    label: '支付失败',
    handleOptions: {
    }
  },
  RETURN: {
    key: 'RETURN',
    value: 4,
    label: '退款成功',
    handleOptions: {
    }
  }
});

export default PayStatusEnum;
