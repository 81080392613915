<template>
  <div class="page-activity__add-edit">
    <h2>{{ isEditing ? "修改活动" : "新建活动" }}</h2>
    <el-form
            :model="formData"
            ref="formData"
            label-width="100px"
            class="demo-formData"
    >
      <el-form-item label="活动id" prop="id">
        <el-input v-model="formData.id" placeholder="新建" disabled></el-input>
      </el-form-item>

      <el-form-item label="商品分类" prop="sku" :rules="[{required: true, message: '关联类目不能为空'}]">
        <el-select v-model="formData.sku" placeholder="请选择">
          <el-option
                  v-for="cItem in stateOption"
                  :key="cItem.id"
                  :label="`${cItem.name}`"
                  :value="cItem.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="活动商品" prop="skuList" v-if="formData.sku==1"
                    :rules="[{required: true, message: '关联商品不能为空'}]">
        <el-button type="primary" @click="openGoodsListDialog">选择商品</el-button>
        <div>共 {{ formData.skuList.length }} 个商品</div>
        <op-table
                max-height="500px"
                size="small"
                :data="formData.skuList"
                :cols="skuTableCols"></op-table>
      </el-form-item>

      <el-form-item label="商品分类" prop="catId" v-if="formData.sku==0"
                    :rules="[{required: true, message: '关联类目不能为空'}]">
        <el-cascader
                v-if="showSelect"
                v-model="formData.catId"
                :placeholder="catNamePlaceholder"
                @change="handleChange"
                :props="props"
        ></el-cascader>
      </el-form-item>

      <el-form-item
              label="图片资源"
              prop="picUrl"
              :rules="[
          {
            required: true,
            message: '活动图片链接不能为空',
          },
        ]"
      >
        <div class="upload-tips">Tips: 图片推荐尺寸：750x360</div>
        <el-upload
                class="banner-uploader"
                :show-file-list="false"
                :http-request="uploadBanner"
                action="fake"
                accept="image/*"
        >
          <img v-if="formData.picUrl" :src="formData.picUrl" class="banner"/>
          <i v-else class="el-icon-plus banner-uploader-icon"></i>
        </el-upload>
        <el-radio-group v-model="formData.type">
          <el-radio style="width: 200px" :label="1">轮播图</el-radio>
          <el-radio style="width: 200px" :label="2">首页bar</el-radio>
          <el-radio style="width: 200px" :label="3">猜你喜欢</el-radio>
          <el-radio style="width: 200px" :label="4">首页栏目</el-radio>
          <el-radio style="width: 200px" :label="5">特别推荐</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save()">保存</el-button>
        <el-button @click="naviBack()">取消</el-button>
      </el-form-item>
    </el-form>
    <goods-list-dialog
            ref="GoodsListDialog"
            :sku-ids="selectedSkuIds"
            @select="selectSku"/>
  </div>
</template>

<script>
import axios from "@/api/base";
import {saveFloor, getFloorList} from "@/api/company";
import {getAllCatalogList} from "@/api/catalog";
import GoodsListDialog from '../activity_goods/activity_goods'
import config from '@/config'

export default {
  name: "add_edit",
  components: {GoodsListDialog},
  computed: {
    isEditing() {
      return !!this.$route.query.id;
    },
    selectedSkuIds() {
      return this.formData.skuList.map(v => v.sku)
    }
  },
  data() {
    return {
      skuTableCols: [
        {
          label: '商品 SKU',
          prop: 'sku'
        },
        {
          label: '商品名称',
          prop: 'name'
        },
        {
          label: '售卖价（元）',
          prop: 'retail_price'
        },
        {
          label: '商品图片',
          render: ({row}) => {
            console.log(row.imagePath)
            let imagePath = row.imagePath
            if (row.imagePath.indexOf('https:') < 0) {
              imagePath = config.jd.imageHostN3 + row.imagePath
            }
            return (
                    <el-image style="width: 100px;"
                              previewSrcList={row.imgUrl.split(';')}
                              src={imagePath}/>
            )
          }
        },
        {
          label: '操作',
          render: ({row}) => {
            return (
                    <el-button type="primary" size="small" onClick={() => this.remove(row)}>移除商品</el-button>
            )
          }
        }
      ],
      props: {
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        checkStrictly: true
      },
      showSelect: true,
      catNamePlaceholder: "请选择",
      catalogOptions: [],
      stateOption: [
        {
          id: 0,
          name: "分类",
        },
        {
          id: 1,
          name: "商品",
        }
      ],
      formData: {
        id: null,
        picUrl: "https://7072-prod-4gg97ttc904fef5b-1310787822.tcb.qcloud.la/upload/e11adad2-c4b3-420e-826b-28da4b140f22.JPG",
        companyId: 0,
        actionType: 1,
        type: 1,
        catId: 0,
        sku: 0,
        skuList: [],
      },
    };
  },
  async mounted() {
    let data = await getAllCatalogList({parentId: 0, catClass: 0});
    this.catalogOptions = data.map((item) => ({
      label: item.name,
      value: item.catId,
      leaf: false,
    }));
    if (this.isEditing) {
      const {id, companyId} = this.$route.query;
      let result = await getFloorList({companyId});
      for (let p of result) {
        if (p.id == id) {
          this.formData = p;
          if (p.first && p.second) {
            this.formData.catId = [p.first, p.second, p.catId];
            this.showSelect = false
            setTimeout(() => {
              this.showSelect = true
            }, 50)
          } else {
            this.formData.catId = [null, null, p.catId];
          }
          this.catNamePlaceholder = p.catName;
          return;
        }
      }
    }
  },
  methods: {
    openGoodsListDialog() {
      this.$refs.GoodsListDialog.open();
    },
    remove(v) {
      const index = this.formData.skuList.findIndex(item => +item.sku === +v.sku)
      this.formData.skuList.splice(index, 1)
    },
    async cascaderLazyLoad(node, resolve) {
      const {level, value} = node;
      // 第 3 层时直接返回空数组
      if (level === 3) {
        return resolve([]);
      }
      // 第 0 层时 parentId 为 0
      const parentId = level === 0 ? 0 : value;
      let data = await getAllCatalogList({parentId, catClass: level});
      const result = data.map((item) => ({
        label: item.name,
        value: item.catId,
        leaf: level >= 2,
      }));
      resolve(result);
    },
    handleChange(value) {
      // this.formData.catId = value
      console.log(value);
    },
    async uploadBanner({file}) {
      try {
        const formData = new FormData();
        formData.append("file", file, file.name);
        const url = await axios.post("/file/upload2", formData);
        this.$message.success("上传成功");
        this.formData.picUrl = url;
      } catch (e) {
        this.$message.error(e);
      }
    },
    naviBack() {
      this.$router.go(-1);
    },
    selectSku(v, selected) {
      if (selected) {
        this.formData.skuList.push(v);
        return;
      }
      this.remove(v);
    },
    async save() {
      const result = await this.$refs.formData.validate();
      if (!result) {
        return;
      }
      try {
        const {id, type, picUrl, actionType, catId, companyId, sku, skuList} = this.formData;

        // let realCatId = Array.isArray(catId) ? catId.pop() : catId

        if (catId.length == 0) {
          this.$message.error("关联类目不能为空");
          return;
        }
        await saveFloor({
          id,
          companyId: this.isEditing ? companyId : this.$route.query.companyId,
          first: catId[0],
          second: catId[1],
          catId: catId[2] ? catId[2] : catId[1] ? catId[1] : catId[0],
          sku,
          skuList: skuList.map(item => item.sku),
          type,
          picUrl,
          actionType,
        })
        this.$message.success("保存成功");
        this.$router.go(-1);
      } catch (e) {
        this.$message.error("创建失败：" + e);
      }
    },
  },
};
</script>

<style lang="scss">
.page-activity__add-edit {
  $banner-width: 550px;
  $banner-height: 240px;

  .demo-formData {
    width: 660px;

    .el-select .el-input {
      width: 360px;
    }

    .line {
      text-align: center;
    }

    .el-radio {
      margin-bottom: 20px;
    }
  }

  .banner-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: $banner-width;
    height: $banner-height;
    margin-bottom: 20px;

    &:hover {
      border-color: #409eff;
    }

    &-icon {
      font-size: 28px;
      color: #8c939d;
      width: $banner-width;
      height: $banner-height;
      line-height: $banner-height;
      text-align: center;
    }

    .banner {
      width: $banner-width;
      height: $banner-height;
      display: block;
    }
  }

  .upload-tips {
    color: #f56c6c;
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
