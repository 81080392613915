<template>
  <div class="page-dashboard">
    <h2>数据汇总</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="查询日期">
        <el-date-picker
          style="width: 380px;"
          v-model="formData.date"
          type="daterange"
          :clearable="false"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="loadData">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="dashboard">
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              订单总交易金额
              <el-tooltip effect="dark" content="状态为待收货、已完成、退换/售后、退款拒绝、退款取消的订单" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.orderPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              京东侧总交易金额
              <el-tooltip effect="dark" content="账户余额明细中业务类型为实物/礼品卡余额支付、余额支付" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.jdOrderPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              未使用余额
              <el-tooltip effect="dark" content="所有用户的剩余账户余额" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.userAccountPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              已生成充值卡总金额
              <el-tooltip effect="dark" content="所有生成的充值卡金额" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.rechargeCardPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              已使用充值卡金额
              <el-tooltip effect="dark" content="已使用充值卡金额" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.usedRechargeCardPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              直接修改账户余额总金额
              <el-tooltip effect="dark" content="直接修改账户余额总金额" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.changedAccountPrice}} 元</div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple">
            <h4>
              自建订单总金额
              <el-tooltip effect="dark" content="自建订单总金额" placement="top-start">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </h4>
            <div class="number">{{data.ownOrderPrice}} 元</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { getDashboardData } from '@/api/dashboard'

  export default {
    name: 'index',
    data() {
      const today = dayjs()
      return {
        formData: {
          date: [today.subtract(1, 'month').format('YYYY-MM-DD'), today.format('YYYY-MM-DD')]
        },
        data: {
          orderPrice: 0
        }
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      async loadData() {
        const { date } = this.formData;
        this.data = await getDashboardData({
          startDate: dayjs(date[0]).format('YYYY-MM-DD'),
          endDate: dayjs(date[1]).add(1, 'day').format('YYYY-MM-DD')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-dashboard {
    .dashboard {
      margin: 50px;
      .bg-purple {
        background: #f9fafc;
      }
      .el-col{ margin-bottom: 20px;}
      .grid-content {
        font-size: 16px;
        border-radius: 4px;
        padding: 25px;
        min-height: 36px;
        .number {
          margin-top: 15px;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }
</style>
