<template>
  <div class="page-user__list">
    <h2>用户列表</h2>
    <el-button type="primary" class="create-btn" @click="openAddEditDialog">新增用户</el-button>
    <el-button type="primary" class="create-btn" @click="openAddBatchDialog">批量新增</el-button>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="公司">
        <el-select v-model="formData.companyId" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户手机">
        <el-input v-model="formData.mobile" placeholder="请输入用户手机" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="danger" @click="downloadUserList">下载</el-button>
      </el-form-item>
    </el-form>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
    <account-log-dialog ref="AccountLogDialog" />
    <account-dialog ref="AccountDialog" @refresh="search" />
    <add-edit-dialog ref="AddEditDialog" @refresh="search" />
    <add-batch-dialog ref="AddBatchDialog" @refresh="search" />
    <password-dialog ref="PasswordDialog" @refresh="search" />
    <category-dialog ref="CategoryDialog" @refresh="search" />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { mapGetters } from 'vuex'
  import AccountLogDialog from './account_log/account_log'
  import AddEditDialog from './add_edit/add_edit'
  import AddBatchDialog from './add_batch/add_batch'
  import AccountDialog from './account/account'
  import PasswordDialog from './password/password'
  import CategoryDialog from './category/category'
  import { getUserList, downloadUserList, deleteUser } from '@/api/user'
  import { getCompanyList } from '@/api/company'
  import { getRoleAuthChecked } from '@/api/admin'

  export default {
    name: 'list',
    components: {
      AccountLogDialog,
      AddEditDialog,
      AddBatchDialog,
      AccountDialog,
      PasswordDialog,
      CategoryDialog
    },
    data() {
      return {
        permission: [],
        formData: {
          companyId: '',
          mobile: ''
        },
        companyList: [],
        table: {
          data: [],
          cols: [
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              label: '账号',
              prop: 'username',
              render: ({ row }) => {
                return (
                  <div class="clicked" onClick={() => this.openPasswordDialog(row)}>{row.username}</div>
                )
              }
            },
            {
              label: '昵称&手机',
              minWidth: 100,
              prop: 'nickname',
              render: ({ row }) => {
                return (
                  <div>
                    <div>{ row.nickname }</div>
                    <div>{ row.mobile }</div>
                  </div>
                )
              }
            },
            {
              label: '头像',
              isPartUserInfoHidden: true,
              render: ({ row }) => {
                return (
                  <img width="50" height="50" src={row.avatar} />
                )
              }
            },
            {
              label: '性别',
              width: 60,
              isPartUserInfoHidden: true,
              render: ({ row }) => {
                const genderMap = {
                  0: '',
                  1: '男',
                  2: '女'
                }
                return (
                  <span>{genderMap[row.gender]}</span>
                )
              }
            },
            // {
            //   label: '出生年月',
            //   prop: 'birthday'
            // },
            {
              label: '账户余额 (元)',
              width: 100,
              isPartUserInfoHidden: true,
              render: ({ row }) => {
                return (
                  <div class="clicked" onClick={() => this.openAccountDialog(row)}>{this.$options.filters.currency(row.account_amount)}</div>
                )
              }
            },
            {
              label: '用户等级',
              isPartUserInfoHidden: true,
              render: ({ row }) => {
                return (
                  <span>{row.user_level_id ? '会员'+this.isPartUserInfoHidden : '普通用户'}</span>
                )
              }
            },
            {
              label: '账号等级',
              render: ({ row }) => {
                const rolMap = {
                  admin: '一级账号',
                  normal: '二级账号'
                }
                return (
                  <div>{ rolMap[row.role] }</div>
                )
              }
            },
            {
              label: '所属公司',
              prop: 'company_name'
            },
            {
              label: '注册时间',
              // width: 150,
              render: ({ row }) => {
                return (
                  <div>{ row.register_time ? dayjs(row.register_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '' }</div>
                )
              }
            },
            {
              label: '登录时间',
              // width: 140,
              render: ({ row }) => {
                return (
                  <div>{ row.last_login_time ? dayjs(row.last_login_time * 1000).format('YYYY-MM-DD HH:mm:ss') : '' }</div>
                )
              }
            },
            {
              label: '操作',
              width: 280,
              render: ({ row }) => {
                return (
                  <div>
                    {
                      !this.isPartUserInfoHidden &&
                      <el-button size="small" type="text" onClick={() => this.showAccountLog(row)}>账户记录</el-button>
                    }
                    {
                      this.isCategoryShown && <el-button size="small" type="text" onClick={() => this.openCategoryDialog(row)}>商品分类</el-button>
                    }
                    {
                      this.isIndexConfigShown &&
                      <router-link to={{ name: 'userIndexComponent', query: { userId: row.id } }}>
                        <el-button size="small" type="text">首页配置</el-button>
                      </router-link>
                    }
                    {
                      this.isAdmin && <el-button size="small" type="text" onClick={() => this.openAddEditDialog(row)}>修改信息</el-button>
                    }
                    {
                      this.isAdmin && <el-button size="small" type="text" onClick={() => this.delete(row)}>删除</el-button>
                    }
                  </div>
                )
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        'isAdmin'
      ]),
      isCategoryShown() {
        return this.permission.indexOf('pUseCategory') > -1
      },
      isIndexConfigShown() {
        return this.permission.indexOf('pUseIndexPosition') > -1
      },
      isPartUserInfoHidden() {
        return this.permission.indexOf('pHidePartUserInfo') > -1
      }
    },
    async created() {
      this.search()
      this.getCompanyList()
      this.getPermission()
    },
    methods: {
      async getPermission() {
        this.permission = await getRoleAuthChecked({
          type: 1,
          roleId: this.$store.state.user.userInfo.admin_role_id
        })
        this.table.cols = this.table.cols.filter(v => {
          return !v.isPartUserInfoHidden || !this.isPartUserInfoHidden
        })
      },
      search() {
        this.table.pagination.currentPage = 1
        this.loadTableData()
      },
      async getCompanyList() {
        const { data } = await getCompanyList({ page: 1, size: 5000 })
        this.companyList = data
      },
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const {
          companyId,
          mobile
        } = this.formData
        const { data, count } = await getUserList({ page, size, companyId, mobile })
        this.table.data = data
        this.table.pagination.total = count
      },
      showAccountLog(row) {
        this.$refs.AccountLogDialog.open(row.id)
      },
      openAccountDialog(row) {
        this.$refs.AccountDialog.open(row, this.companyList)
      },
      openAddEditDialog(row) {
        this.$refs.AddEditDialog.open(row, this.companyList)
      },
      openAddBatchDialog() {
        this.$refs.AddBatchDialog.open()
      },
      openPasswordDialog(row) {
        this.$refs.PasswordDialog.open(row)
      },
      openCategoryDialog(row) {
        this.$refs.CategoryDialog.open(row)
      },
      async downloadUserList() {
        const {
          companyId,
          mobile
        } = this.formData
        const data = await downloadUserList({ companyId, mobile })
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `用户数据-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      },
      async delete({ id } = {}) {
        await this.$confirm( '确定删除该账号？', '提示', { type: 'warning' });
        try {
          await deleteUser({ id })
          this.$message.success('删除成功')
          this.loadTableData()
        } catch (e) {
          this.$message.error('删除失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__list {
    .create-btn {
      margin-bottom: 20px;
    }

    .clicked {
      text-decoration: underline;
      color: #00a0e9;
      cursor: pointer;
    }
  }
</style>
