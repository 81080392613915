<template>
  <el-dialog
    title="账户操作记录"
    :visible.sync="dialogVisible" >
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </el-dialog>
</template>

<script>
  import { getUserAccountLogList } from '@/api/user'

  export default {
    name: 'account_log',
    data() {
      return {
        dialogVisible: false,
        table: {
          data: [],
          cols: [
            {
              label: '记录 ID',
              prop: 'id'
            },
            {
              label: '操作金额 (元)',
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.operate_amount) }</div>
                )
              }
            },
            {
              label: '操作人',
              prop: 'operator_id',
              render: ({row}) => {
                return (
                  <div>
                    {`[${row.operator_id}] ${row.operator_name}`}
                  </div>
                )
              }
            },
            {
              label: '操作类型',
              render: ({ row }) => {
                // 充值 1，付款 2，退款 3，微信支付 201，账户扣款 202，直接修改为 301
                const map = {
                  1: '充值',
                  2: '付款',
                  3: '退款',
                  201: '微信支付',
                  202: '账户扣款',
                  203: '混合支付',
                  301: '直接修改为'
                }
                return (
                  <div>{ map[row.operate_type] + this.$options.filters.currency(row.operate_amount) }</div>
                )
              }
            },
            {
              label: '创建时间',
              width: 150,
              prop: 'create_time'
            }
          ]
        }
      }
    },
    methods: {
      open(userId) {
        this.dialogVisible = true
        this.loadTableData({ userId })
      },
      async loadTableData({ page = 1, size = 1000, userId }) {
        const { data } = await getUserAccountLogList({ page, size, userId })
        this.table.data = data
      }
    }
  }
</script>

<style scoped>

</style>
