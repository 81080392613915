import axios from './base'

export const offshopList = () => {
  return axios.get('offshop/list')
}

export const offshopListByPage = ({page, size}) => {
  return axios.get('offshop/listByPage', {params: {page, size}})
}

export const offshopSaveDetail = ({
                                    id,
                                    shop_name,
                                    tax_id,
                                    avatar,
                                    introduction,
                                    address,
                                    bank,
                                    public_account,
                                    phone,
                                    contact,
                                    contact_phone,
                                    password,
                                    contact_mail
                                  }) => {
  return axios.post('offshop/saveDetail', {
    id,
    shop_name,
    tax_id,
    avatar,
    introduction,
    address,
    bank,
    public_account,
    phone,
    contact,
    contact_phone,
    password,
    contact_mail
  })
}

