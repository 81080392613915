<template>
  <el-dialog
    title="查看物流"
    :visible.sync="dialogVisible">
    <div>
      <el-form :inline="true" :model="formData" class="demo-form-inline">
        <el-form-item prop="expressType" :rules="[{
        required: true, message: '请选择快递公司'
      }]" >
          <el-select style="width: 150px" v-model="formData.expressType" placeholder="请选择快递公司" clearable>
            <el-option
              v-for="(item, index) in expressTypeOptions"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="expressId" :rules="[{
        required: true, message: '请输入快递号'
      }]" >
          <el-input v-model="formData.expressId" placeholder="请输入快递号" clearable/>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="saveExpressInfo('formData')">确认</el-button>
        </el-form-item>
      </el-form>
<!--      <el-steps direction="vertical" :active="1" v-else style="height: 300px;overflow: scroll">-->
<!--        <el-step v-for="(item,index) in expressData.data" :key='index'-->
<!--          :title="item.context"-->
<!--          :description="item.time +'&#45;&#45;&#45;&#45;'+ item.ftime"-->
<!--        >{item}</el-step>-->

<!--      </el-steps>-->
    </div>
  </el-dialog>

</template>

<script>
  import ExpressTypeEnum from '@/enums/express_type'
  import { saveExpress } from '@/api/order'

  export default {
    name: 'express',
    data() {
      return {
        dialogVisible: false,
        formData: {
          expressId: '',
          expressType: ''
        },
        orderId: '',
        expressTypeOptions: ExpressTypeEnum.getList(),
      }
    },
    methods: {
      async open({expressId, expressType, orderId}) {
        this.dialogVisible = true;
        this.orderId = orderId;
        if (expressId) {
          this.formData.expressId = expressId;
        }
        if (expressType) {
          this.formData.expressType = expressType;
        }
        console.log({expressId, expressType, orderId})
      },
      async saveExpressInfo() {
        const {expressType, expressId} = this.formData;
        if (!expressType) {
          this.$alert('请选择快递公司')
          return
        }
        if (!expressId) {
          this.$alert('请输入快递号')
          return
        }
        await saveExpress({expressId, expressType,orderId: this.orderId })
        this.$emit('refresh');
        this.dialogVisible = false;
      }
    }
  }
</script>

<style scoped>

</style>
