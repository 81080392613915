import { render, staticRenderFns } from "./jd_order_dialog.vue?vue&type=template&id=7d8a9468&scoped=true&"
import script from "./jd_order_dialog.vue?vue&type=script&lang=js&"
export * from "./jd_order_dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d8a9468",
  null
  
)

export default component.exports