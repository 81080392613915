<template>
  <div class="page-indexComponent">
    <h2>首页配置</h2>
    <el-button type="primary"
               @click="openAddEditDialog()"
               style="margin-left: 10px">新增</el-button>
    <op-table
            size="small"
            :data="table.data"
            :cols="table.cols" />
    <change-position-dialog
      ref="changePositionDialog"
      @refresh="changePosition" />
    <add-goods-dialog
      ref="AddGoodsDialog"
      @refresh="loadTableData" />
    <add-edit-dialog
      ref="AddEditDialog"
      @refresh="loadTableData" />
  </div>
</template>

<script>
  import config from '@/config'
  import changePositionDialog from './change/change'
  import addGoodsDialog from './add_goods/add_goods'
  import addEditDialog from './add_edit/add_edit'
  import { positionDelGood, positionDetailList } from '@/api/position'

  export default {
    name: 'indexComponent',
    data() {
      const GoodsTableCols = [
        {
          label: '商品sku编号',
          prop: 'sku'
        },
        {
          label: '商品sku名称',
          prop: 'name'
        },
        {
          label: '商品图片',
          render: ({ row }) => {
            return (
              <img src={row.org_id ===1 ? `${config.jd.imageHostN3}${row.imagePath}` : row.imagePath}
                   style="width: 100px; height: 100px" />
            )
          }
        },
        {
          label: '利润 (元)',
          render: ({ row }) => {
            return (
              <span>{ this.$options.filters.currency(row.profit) }</span>
            )
          }
        },
        {
          label: '售卖价 (元)',
          render: ({ row }) => {
            return (
              <span>{ this.$options.filters.currency(row.retail_price) }</span>
            )
          }
        },
        {
          label: '企业购价 (元)',
          render: ({ row }) => {
            return (
              <span>{ this.$options.filters.currency(row.price) }</span>
            )
          }
        },
        {
          label: '京东价 (元)',
          render: ({ row }) => {
            return (
              <span>{ this.$options.filters.currency(row.jd_price) }</span>
            )
          }
        },
        {
          label: '操作',
          width: 150,
          render: ({ row }) => {
            return (
              <div>
                <el-button type="primary" size="small" onClick={() => this.openChangePositionDialog(row)}>修改</el-button>
                <el-button type="danger" size="small" onClick={() => this.removeGoods(row.id)}>移除</el-button>
              </div>
            )
          }
        },
      ];
      return {
        table: {
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({ row }) => {
                return (
                  <op-table size="small" data={row.item} cols={GoodsTableCols} />
                )
              }
            },
            {
              label: '模块id',
              prop: 'id'
            },
            {
              label: '模块名称',
              prop: 'title'
            },
            {
              label: '模块描述',
              prop: 'dec'
            },
            {
              label: '商品数量',
              prop: 'item.length'
            },
            {
              label: '模块类型',
              render: ({ row }) => {
                const map = {
                  1: '首页精选模块',
                  2: '首页滚动列表'
                }
                return (
                  <div>{map[row.type]}</div>
                )
              }
            },
            {
              label: '模块状态',
              render: ({ row }) => {
                const map = {
                  0: '下线',
                  1: '上线'
                }
                return (
                  <div>{map[row.state]}</div>
                )
              }
            },
            {
              label: '操作',
              render: ({ row }) => {
                return (
                  <div>
                    <el-button type="primary"
                               size="small"
                               onClick={() => this.openAddGoodsDialog(row)}
                               style="margin-left: 10px">添加</el-button>
                    <el-button type="primary"
                               size="small"
                               onClick={() => this.openAddEditDialog(row)}
                               style="margin-left: 10px">修改</el-button>
                  </div>
                )
              }
            }
          ]
        }
      }
    },
    components: {
      changePositionDialog,
      addGoodsDialog,
      addEditDialog
    },
    created() {
      this.loadTableData();
    },
    methods: {
      async loadTableData() {
        const { userId } = this.$route.query;
        this.table.data = await positionDetailList({ userId });
      },
      openAddGoodsDialog(row) {
        this.$refs.AddGoodsDialog.open(row.id);
      },
      openAddEditDialog(row) {
        this.$refs.AddEditDialog.open(row);
      },
      openChangePositionDialog(row) {
        this.$refs.changePositionDialog.open(row);
      },
      async removeGoods(id) {
        await this.$confirm('确认移除该商品？', '提示', { type: 'warning' })
        try {
          await positionDelGood({ id })
          this.loadTableData()
          this.$message.success('移除成功')
        } catch (e) {
          this.$message.error('移除失败')
        }
      },
      async changePosition(id) {
        await positionDelGood({ id })
        this.loadTableData()
      }
    }
  }
</script>

<style lang="scss">
  .page-indexComponent {
  }
</style>
