export default [{
  id: 'pUseCategory',
  name: '商品分类按用户维度'
}, {
  id: 'pUseIndexPosition',
  name: '首页推荐位配置按用户维度'
}, {
  id: 'pHidePartUserInfo',
  name: '隐藏用户列表头像、账户余额、用户等级、账户记录'
}, {
  id: 'pCompanyCategory',
  name: '商品分类按公司维度'
}, {
  id: 'pCompanyIndexPosition',
  name: '首页推荐位配置按公司维度'
}]
