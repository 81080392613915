<template>
  <el-dialog
    title="提示"
    class="page-admin__add"
    :visible.sync="dialogVisible"
    width="50%">
    <el-form :model="formData" ref="form" label-width="80px">
      <el-form-item label="角色" prop="role">
        <el-select v-model="formData.adminRoleId" placeholder="请选择">
          <el-option
            v-for="(item, index) in adminRoleData"
            :key="index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        label="账号"
        prop="username"
        :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
      >
        <el-input v-model="formData.username"></el-input
        ></el-form-item>

      <el-form-item
        label="昵称"
        prop="nickname"
        :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
      >
        <el-input
          v-model="formData.nickname"
          placeholder="请输入昵称"
        ></el-input
        ></el-form-item>

      <el-form-item
        label="密码"
        prop="password"
        :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
      >
        <el-input type="password" v-model="formData.password"></el-input
        ></el-form-item>

      <el-form-item
        label="密码确认"
        prop="confirm_password"
        :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
      >
        <el-input type="password" v-model="formData.confirm_password"></el-input
        ></el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import { addAdminAccount, adminRoleList } from '@/api/admin'

  export default {
    name: 'add_edit',
    data() {
      return {
        dialogVisible: false,
        formData: {
          username: '',
          password: '',
          adminRoleId: '',
          confirm_password: '',
          nickname: ''
        },
        adminRoleData:[]
      }
    },
    async mounted(){
       const selectData = await adminRoleList();
       this.adminRoleData = selectData.map(value =>{
           return{ value:value.id, label:value.role_name }
       })
    },

    methods: {
      open() {
        this.dialogVisible = true
      },
      close() {
        this.dialogVisible = false
      },
      async save() {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        const {
          username,
          password,
          adminRoleId,
          confirm_password,
          nickname
        } = this.formData

        if (!this.isEditing && password !== confirm_password) {
          this.$message.error('两次密码不一致');
          return;
        }
        try {
          await addAdminAccount({
            username,
            password,
            adminRoleId,
            nickname
          })
          this.$message.success('新增成功')
          this.dialogVisible = false
          this.$emit('refresh')
        } catch (e) {
          this.$message.error('新增失败：' + e)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-admin__add {

  }
</style>
