<template>
  <div class="region-picker" style="display: inline-block">
    <el-cascader
      :props="props"
      :value="region || [provinceId, cityId, countyId, townId].filter(Boolean)"
      @input="input"
      style="width: 100%"
      size="small"
      :disabled="loading"
    ></el-cascader>
  </div>
</template>

<script>
import * as regionAPI from '@/api/region';

const data = {
  provinces: [],
  cites: {},
  counties: {},
  towns: {},
  map: {}
};

async function getProvinces() {
  let { provinces } = data;
  if (!provinces.length) {
    provinces = (await regionAPI.getProvinceList()).map(p => {
      data.map[p.label] = {
        value: p.label,
        label: p.value,
        leaf: false
      };
      return data.map[p.label];
    });
    data.provinces = [...provinces];
  }

  return provinces;
}

async function getCites(id) {
  let { cites } = data;
  if (!cites[id]) {
    cites[id] = (await regionAPI.getCityList(id)).map(p => {
      data.map[p.label] = {
        value: p.label,
        label: p.value,
        leaf: false
      };
      return data.map[p.label];
    });

    data.cites = { ...cites };
  }

  return cites[id];
}

async function getCounties(id) {
  let { counties, towns } = data;
  if (!counties[id]) {
    const onlineCounties = await regionAPI.getCountyList(id);
    counties[id] = await Promise.all(
      onlineCounties.map(async p => {
        const onlineTowns = await regionAPI.getTownList(p.label);
        towns[p.label] = onlineTowns.map(p => {
          data.map[p.label] = {
            value: p.label,
            label: p.value,
            leaf: true
          };
          return data.map[p.label];
        });
        data.map[p.label] = {
          value: p.label,
          label: p.value,
          leaf: !onlineTowns.length
        };
        return data.map[p.label];
      })
    );

    data.counties = { ...counties };
    data.towns = { ...towns };
  }

  return counties[id];
}

export default {
  name: 'region-picker',

  props: {
    value: {},
    provinceId: Number,
    provinceName: String,
    cityId: Number,
    cityName: String,
    countyId: Number,
    countyName: String,
    townId: Number,
    townName: String,
    region: {}
  },

  data() {
    return {
      loading: false,
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          this.loading = true;

          switch (node.level) {
            case 0:
              resolve(await getProvinces());
              break;
            case 1:
              resolve(await getCites(node.value));
              break;
            case 2:
              resolve(await getCounties(node.value));
              break;
            case 3:
              resolve(data.towns[node.value]);
              break;
            default:
              resolve([]);
          }
          this.loading = false;
        }
      }
    };
  },

  methods: {
    input(value) {
      if (!Array.isArray(value)) {
        return;
      }
      const [provinceId, cityId, countyId, townId] = value;
      this.$emit('update:provinceId', provinceId);
      this.$emit('update:provinceName', data.map[provinceId].label);
      this.$emit('update:cityId', cityId);
      this.$emit('update:cityName', data.map[cityId].label);
      this.$emit('update:countyId', countyId);
      this.$emit('update:countyName', data.map[countyId].label);
      this.$emit('update:townId', townId);
      this.$emit('update:townName', data.map[townId] && data.map[townId].label);
      this.$emit('update:region', value);
    }
  }
};
</script>
