<template>
  <el-dialog
    title="批量生成充值卡"
    class="page-user__recharge-add-batch"
    :visible.sync="dialogVisible" >
    <el-form ref="formData" :model="formData" class="demo-form-inline" label-width="150px">
      <el-form-item prop="price" label="充值卡金额 (元)" :rules="[
        { required: true, message: '请填写充值卡金额' }
      ]">
        <el-input v-model="formData.price" type="number" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="number" label="数量">
        <el-input-number v-model="formData.number" :min="1" :max="1000" label="描述文字"></el-input-number>
      </el-form-item>
      <el-form-item prop="deadline" label="截止日期" :rules="[
        { required: true, message: '请选择截止日期' }
      ]">
        <el-date-picker
          :clearable="false"
          v-model="formData.deadline"
          placeholder="请选择截止日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即生成</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { createBatchRecharge } from '@/api/recharge'
  export default {
    name: 'add',
    data() {
      const today = dayjs()
      return {
        dialogVisible: false,
        formData: {
          price: '',
          number: '',
          // 截止日期默认 6 个月后
          deadline: today.add(6, 'month').format('YYYY-MM-DD'),
          remark: '批量生成'
        }
      }
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.formData.resetFields()
        })
      },
      async onSubmit() {
        const isValid = await this.$refs.formData.validate().catch(() => false)
        if (!isValid) {
          return
        }
        const {
          price,
          number,
          deadline,
          remark
        } = this.formData
        try {
          const data = await createBatchRecharge({
            price,
            number,
            deadline,
            remark
          })
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', `批量创建充值卡-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
          document.body.appendChild(link)
          link.click()
          this.$message.success('生成成功')
          this.dialogVisible = false
          this.$emit('refresh')
        } catch (e) {
          this.$message.error('生成失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__recharge-add-batch {
    .el-input {
      width: 260px;
    }
  }
</style>
