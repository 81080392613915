<template>
  <div class="page-user__log-list">
    <h2>账户日志列表</h2>
    <el-form :inline="true" :model="formData" class="demo-form-inline">
      <el-form-item label="公司">
        <el-select v-model="formData.companyId" placeholder="请选择" @change="loadTableData" clearable>
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户手机">
        <el-input v-model="formData.mobile" placeholder="请输入用户手机" clearable />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
                v-model="formData.createdDate"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">查询</el-button>
        <el-button type="danger" @click="downloadUserAccountLogList">下载</el-button>
      </el-form-item>
    </el-form>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  import { getCompanyList } from '@/api/company'
  import { getUserAccountLogList, downloadUserAccountLogList } from '@/api/user'

  export default {
    name: 'log_list',
    data() {
      return {
        formData: {
          companyId: '',
          mobile: '',
          createdDate: []
        },
        companyList: [],
        table: {
          data: [],
          cols: [
            {
              label: '记录 ID',
              width: 80,
              prop: 'id'
            },
            {
              label: '账户用户',
              render: ({ row }) => {
                return (
                  <div>
                    <div>{ row.username }</div>
                    <div>{ row.mobile }</div>
                    <div>{ row.company_name }</div>
                  </div>
                )
              }
            },
            {
              label: '账户余额 (元)',
              render: ({ row }) => {
                return (
                  <div>{ this.$options.filters.currency(row.amount) }</div>
                )
              }
            },
            {
              label: '操作人',
              prop: 'operator_id',
              render: ({row}) => {
                return (
                  <div>
                    {`[${row.operator_id}] ${row.operator_name}`}
                  </div>
                )
              }
            },
            {
              label: '操作类型',
              render: ({ row }) => {
                // 充值 1，付款 2，退款 3，微信支付 201，账户扣款 202，直接修改为 301
                const map = {
                  1: '充值',
                  2: '付款',
                  3: '退款',
                  201: '微信支付',
                  202: '账户扣款',
                  301: '直接修改为'
                }
                return (
                  <div>{ map[row.operate_type] + this.$options.filters.currency(row.operate_amount) }</div>
                )
              }
            },
            {
              label: '备注',
              prop: 'remark'
            },
            {
              label: '创建时间',
              width: 150,
              prop: 'create_time'
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
    async created() {
      this.search()
      this.getCompanyList()
    },
    methods: {
      search() {
        this.table.pagination.currentPage = 1
        this.loadTableData()
      },
      async getCompanyList() {
        const { data } = await getCompanyList({ page: 1, size: 5000 })
        this.companyList = data
      },
      async loadTableData() {
        const { currentPage: page, pageSize: size } = this.table.pagination
        const {
          companyId,
          mobile,
          createdDate
        } = this.formData
        const { data, count } = await getUserAccountLogList({ page, size, companyId, mobile,createdBeginDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD'),
            createdEndDate: createdDate && createdDate[1] && dayjs(createdDate[1]).add(1, 'day').format('YYYY-MM-DD') })
        this.table.data = data
        this.table.pagination.total = count
      },
      async downloadUserAccountLogList() {
        const {
          companyId,
          mobile,
          createdDate
        } = this.formData
        const data = await downloadUserAccountLogList({ companyId, mobile,createdBeginDate: createdDate && createdDate[0] && dayjs(createdDate[0]).format('YYYY-MM-DD'),
            createdEndDate: createdDate && createdDate[1] && dayjs(createdDate[1]).add(1, 'day').format('YYYY-MM-DD') })
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `用户账户日志-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__log-list {
    .create-btn {
      margin-bottom: 20px;
    }

    .clicked {
      text-decoration: underline;
      color: #00a0e9;
      cursor: pointer;
    }
  }
</style>
