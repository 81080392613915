<template>
  <el-dialog
    title="修改账号密码"
    :visible.sync="dialogVisible"
    width="50%">
    <div class="page-admin__password">
      <el-form :model="formData" ref="form" label-width="80px">
        <el-form-item label="角色" prop="role">
          <el-select v-model="formData.admin_role_id" placeholder="请选择" disabled>
            <el-option
              v-for="(item, index) in [
              {
                label: '超级管理员',
                value: 1
              },
              {
                label: '运营人员',
                value: 0
              }
            ]"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="账号"
          prop="username"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input v-model="formData.username" disabled></el-input
          ></el-form-item>


        <el-form-item
          label="密码"
          prop="password"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input type="password" v-model="formData.password"></el-input
          ></el-form-item>

        <el-form-item
          label="密码确认"
          prop="confirm_password"
          :rules="[
          {
            required: true,
            message: '不能为空'
          }
        ]"
        >
          <el-input type="password" v-model="formData.confirm_password"></el-input
          ></el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
  import { changePassword } from '@/api/admin'

  export default {
    name: 'password',

    data() {
      return {
        dialogVisible: false,
        userId: '',
        formData: {
          username: '',
          password: '',
          confirm_password: '',
          admin_role_id: 0,
          nickname: ''
        }
      };
    },
    methods: {
      open(v = {}) {
        this.dialogVisible = true
        this.formData = v
        this.$nextTick(() => {
          this.$refs.form.clearValidate()
        })
      },
      close() {
        this.dialogVisible = false
      },
      async save() {
        if (!(await this.$refs.form.validate())) {
          return;
        }

        const {
          id,
          username,
          password,
          confirm_password
        } = this.formData;
        if (password !== confirm_password) {
          this.$message.error('两次密码不一致');
          return;
        }

        await this.$confirm(`确认修改${username}密码？`, '提示', { type: 'warning' });
        try {
          await changePassword({
            id,
            password
          });
          this.$message.success('修改成功');
          this.dialogVisible = false
          this.$emit('refresh');
        } catch (e) {
          this.$message.error('修改失败：' + e);
        }
      },
      cancel() {
        this.dialogVisible = false
      }
    }
  };
</script>

<style lang="scss">
  .page-admin__password {
    .el-form {
      max-width: 660px;
      .el-select .el-input {
        max-width: 360px;
      }
      .line {
        text-align: center;
      }
      .el-radio {
        margin-bottom: 20px;
      }
    }
  }
</style>
