export const defaultMenu = [
  {
    id: 'dashboard',
    name: '汇总',
    icon: 'el-icon-menu',
    children: [],
    to: {name: 'dashboard'}
  },
  {
    id: 'jd',
    name: '京东',
    icon: 'el-icon-location',
    children: [
      // {
      //   id: 'jdPageNum',
      //   name: '商品池列表',
      //   to: {name: 'jdPageNum'}
      // },
      // {
      //   id: 'jdSkuList',
      //   name: '商品 SKU 列表',
      //   to: {name: 'jdSkuList'}
      // },
      // {
      //   id: 'jdOrderNewList',
      //   name: '订单列表',
      //   to: {name: 'jdOrderNewList'}
      // },
      // {
      //   id: 'jdInvoiceList',
      //   name: '发票列表',
      //   to: {name: 'jdInvoiceList'}
      // },
      {
        id: 'jdAccount',
        name: '账户余额',
        to: {name: 'jdAccount'}
      },
      // {
      //   id: 'jdMessage',
      //   name: '消息通知',
      //   to: {name: 'jdMessage'},
      // }
    ]
  },
  {
    id: 'goods',
    name: '商品',
    icon: 'el-icon-location',
    children: [
      {
        id: 'jdCatalogList',
        name: '商品分类列表',
        to: {name: 'jdCatalogList'}
      },
      {
        id: 'skuGoodsList',
        name: '商户商品列表',
        to: {name: 'skuGoodsList'}
      },
      {
        id: 'addGoods',
        name: '添加商品',
        to: {name: 'addGoods'}
      }
    ]
  },
  {
    id: 'user',
    name: '用户',
    icon: 'el-icon-document',
    children: [
      {
        id: 'userList',
        name: '用户列表',
        to: {name: 'userList'}
      },
      {
        id: 'userAccountLogList',
        name: '账户日志列表',
        to: {name: 'userAccountLogList'}
      },
      {
        id: 'companyList',
        name: '公司列表',
        to: {name: 'companyList'}
      },
      {
        id: 'orgList',
        name: '供应商列表',
        to: {name: 'orgList'}
      },
        {
            id: 'shopList',
            name: '线下店列表',
            to: {name: 'shopList'}
        },

        {
        id: 'rechargeList',
        name: '充值卡列表',
        to: {name: 'rechargeList'},
      },
      {
        id: 'pickupList',
        name: '提货卡列表',
        to: {name: 'pickupList'},
      }
    ]
  },
  {
    id: 'order',
    name: '订单',
    icon: 'el-icon-document',
    children: [
      {
        id: 'orderList',
        name: '订单列表',
        to: {name: 'orderList'}
      },
        {
            id: 'offlist',
            name: '线下店订单列表',
            to: {name: 'offlist'}

        }
    ]
  },
  {
    id: 'activity',
    name: '页面配置',
    icon: 'el-icon-setting',
    children: [
      {
        id: 'activityList',
        name: '活动列表',
        to: {name: 'activityList'}
      }
    ]
  },
  {
    id: 'bill',
    name: '账单',
    icon: 'el-icon-setting',
    children: [
      {
        id: 'billList',
        name: '账单列表',
        to: {name: 'billList'}
      }
    ]
  },
  {
    id: 'refund',
    name: '退款单',
    icon: 'el-icon-setting',
    children: [
      {
        id: 'refundList',
        name: '退款单列表',
        to: {name: 'refundList'}
      }
    ]
  },
  {
    id: 'gongGaoList',
    name: '公告管理',
    icon: 'el-icon-setting',
    children: [
      {
        id: 'gongGaoList',
        name: '公告列表',
        to: {name: 'gongGaoList'}
      },

    ]
  },
  {
    id: 'admin',
    name: '后台管理',
    icon: 'el-icon-setting',
    children: [
      {
        id: 'adminList',
        name: '账号列表',
        to: {name: 'adminList'}
      },
      {
        id: 'roleList',
        name: '角色列表',
        to: {name: 'roleList'}
      }
    ]
  }
];

export const getAuthMenu = ({ menuChecked }) => {
  return defaultMenu.map(v => {
    v.children.forEach(cv => cv.isShown = menuChecked.indexOf(cv.id) > -1);
    v.isShown = menuChecked.indexOf(v.id) > -1 || v.children.some(cv => cv.isShown)
    return v
  })
}

