import axios from './base'

export const getJdNewOrderList = ({ page, size, jdOrderIdIndex, date, endDate } = {}) => {
  return axios.get('jd_order/newList', {
    params: {
      page, size, jdOrderIdIndex, date, endDate
    }
  })
}

export const getJdDeliveredOrderList = ({ page, size, jdOrderId, date, endDate } = {}) => {
  return axios.get('jd_order/deliveredList', {
    params: {
      page, size, jdOrderId, date, endDate
    }
  })
}

export const getJdRejectOrderList = ({ page, size, jdOrderId, date, endDate } = {}) => {
  return axios.get('jd_order/rejectList', {
    params: {
      page, size, jdOrderId, date, endDate
    }
  })
}

export const getJdOrderList = ({ page, size, jdOrderId } = {}) => {
  return axios.get('jd_order/list', {
    params: {
      page, size, jdOrderId
    }
  })
}

export const getJdOrderDetail = ({ jdOrderId } = {}) => {
  return axios.get('jd_order/detail', {
    params: {
      jdOrderId
    }
  })
}

export const getJdOrderTrace = ({ jdOrderId } = {}) => {
  return axios.get('jd_order/express', {
    params: {
      jdOrderId
    }
  })
}

export const confirmOrder = ({ jdOrderId }) => {
  return axios.post('jd_order/confirmOrder', {
    jdOrderId
  })
}

export const cancelOrder = ({ jdOrderId }) => {
  return axios.post('jd_order/cancel', {
    jdOrderId
  })
}
