import axios from './base'

export const login = ({ username, password } = {}) => {
  return axios.post('auth', {
    username,
    password
  })
}

export const logout = () => {
  return axios.post('auth/logout')
}
