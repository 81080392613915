<template>
  <el-dialog
    title="生成提货卡"
    class="page-user__pickup-add"
    :visible.sync="dialogVisible" >
    <el-form ref="formData" :model="formData" class="demo-form-inline" label-width="150px">
      <el-form-item prop="price" label="提货卡金额 (元)" :rules="[
        { required: true, message: '请填写提货卡金额' }
      ]">
        <el-input v-model="formData.price" type="number" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="skus" label="商品sku编号" >
        <el-input v-model="formData.skus"  placeholder="请输入商品sku，多个中间，隔开" />
      </el-form-item>
      <el-form-item prop="number" label="数量">
        <el-input-number disabled v-model="formData.number" :min="1" :max="10" label="描述文字"></el-input-number>
      </el-form-item>
      <el-form-item prop="deadline" label="截止日期" :rules="[
        { required: true, message: '请选择截止日期' }
      ]">
        <el-date-picker
          :clearable="false"
          v-model="formData.deadline"
          placeholder="请选择截止日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="mobile" label="用户手机" :rules="[
        { required: true, message: '请选择用户手机' }
      ]">
        <el-select
          filterable
          v-model="formData.mobile"
          placeholder="请选择"
          style="width: 250px;">
          <el-option
            v-for="(item, index) in userOptions"
            :key="index"
            :label="`${item.mobile} (${item.nickname})`"
            :value="item.mobile">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="companyId" label="绑定公司" :rules="[
        { required: true, message: '请选择公司' }
      ]">
        <el-select
          filterable
          v-model="formData.companyId"
          placeholder="请选择"
          style="width: 250px;">
          <el-option
            v-for="(item, index) in companyOptions"
            :key="index"
            :label="`${item.id} (${item.name})`"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="categoryId" label="绑定三级类目">
        <el-select
          filterable
          multiple
          v-model="formData.categoryId"
          placeholder="请选择"
          style="width: 350px;">
          <el-option
            v-for="(item, index) in categoryOptions"
            :key="index"
            :label="`${item.catId} (${item.name})`"
            :value="item.catId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="orgId" label="绑定供应商" :rules="[
        { required: true, message: '请选择供应商' }
      ]">
        <el-select
          filterable
          v-model="formData.orgId"
          placeholder="请选择"
          style="width: 250px;">
          <el-option
            v-for="(item, index) in orgOptions"
            :key="index"
            :label="`${item.id} (${item.org_name})`"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remark" label="备注">
        <el-input v-model="formData.remark" type="textarea" placeholder="请输入备注" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即生成</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import dayjs from 'dayjs'
  import { createPickup } from '@/api/pickup'
  import { getUserList } from '@/api/user'
  import { getCompanyList } from '@/api/company'
  import { getCatalogList } from '@/api/catalog'
  import { orgList } from '@/api/org'

  export default {
    name: 'add',
    data() {
      const today = dayjs()
      return {
        dialogVisible: false,
        formData: {
          skus: '',
          price: '',
          companyId: '',
          categoryId: [],
          number: '',
          // 截止日期默认 6 个月后
          deadline: today.add(6, 'month').format('YYYY-MM-DD'),
          mobile: '',
          orgId:'',
          remark: '绑定用户'
        },
        userOptions: [],
        companyOptions: [],
        categoryOptions: [],
        orgOptions: []

      }
    },
    created() {
      this.getUserList()
      this.getCompanyList()
      this.getCategoryList()
      this.getOrgList()
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.formData.resetFields()
        })
      },
      async getUserList() {
        const { data } = await getUserList({ page: 1, size: 1000 })
        this.userOptions = data
      },
      async getCompanyList() {
        const { data } = await getCompanyList({ page: 1, size: 1000 })
        this.companyOptions = data
        console.log('this.companyOptions',this.companyOptions)

      },
      async getCategoryList() {
        const { data } = await getCatalogList({ page: 1, size: 3000, catClass: 2 })
        this.categoryOptions = data
      },
      async getOrgList(){
        const res = await orgList();
        this.orgOptions = res;
      },
      async onSubmit() {
        const isValid = await this.$refs.formData.validate().catch(() => false)
          console.log(this.formData)
        if (!isValid) {
          return
        }
        const {
          price,
          companyId,
          categoryId,
          number,
          deadline,
          mobile,
          remark,
          orgId,
            skus
        } = this.formData
        try {
          await createPickup({
            price,
            companyId,
            categoryId:categoryId.join(','),
            number,
            deadline,
            mobile,
            remark,
            orgId,
              skus
          })
          this.$message.success('生成成功')
          this.dialogVisible = false
          this.$emit('refresh')
        } catch (e) {
            console.log(e);
          this.$message.error('生成失败')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-user__pickup-add {
    .el-input {
      width: 260px;
    }
  }
</style>
