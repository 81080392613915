class Enum {
  constructor(data) {
    this._data = data;
    Object.assign(this, { ...data });
  }
  isValueEqual(value) {
    return this._data.value === value;
  }
  isEqual(data) {
    return this._data === data;
  }
}

export class BaseEnum {
  constructor(config) {
    this._keys = Object.keys(config);
    this._list = Object.values(config);
    this._entries = Object.entries(config);
    this._enum = this._entries
      .reduce((pre, entry) => Object.assign(pre, { [entry[0]]: new Enum(entry[1]) }), {});
    this._enumList = Object.values(this._enum);
    Object.assign(this, { ...this._enum });
  }

  parse(value) {
    const result = this._enumList.find(item => item.value === value);
    return result || {};
  }

  getList() {
    return this._list;
  }
}
