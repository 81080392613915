import axios from './base'

export const getOrderList = ({ page, size, jdOrderId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate }) => {
  return axios.get('order/list', {
    params: { page, size, jdOrderId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate }
  })
}
export const getOffOrderList = ({ page, size, offShopId, orderSn, status, userId, mobile, userMobile, userCompany, startDate, endDate }) => {
    return axios.get('order/offOrderlist', {
        params: { page, size, offShopId, orderSn, status, userId, mobile, userMobile, userCompany, startDate, endDate }
    })
}

export const downloadOrderList = ({ jdOrderId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate }) => {
  return axios.post('order/download', {
    jdOrderId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate
  }, {
    responseType: 'blob'
  })
}
export const downloadOffOrderList = ({ offShopId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate }) => {
    return axios.post('order/offOrderDownload', {
        offShopId, orderSn, status, userId, mobile, userMobile, userCompany, orgId, startDate, endDate
    }, {
        responseType: 'blob'
    })
}

export const getOrderGoodsList = ({ page, size, orderId, orderSn } = {}) => {
  return axios.get('order/goodsList', {
    params: { page, size, orderId, orderSn }
  })
}

export const getJdOrderId = (orderSn) => {
  return axios.get('order/getJdOrderId', {
    params: {
      orderSn
    }
  })
}

export const getSubList = ({ page, size, jdOrderId, orderSn, status, userId, orgId }) => {
    return axios.get('order/subList', {
        params: { page, size, jdOrderId, orderSn, status, userId, orgId }
    })
}

export const getSubOrderGoodsList = ({ page, size, subOrderId } = {}) => {
    return axios.get('order/subGoodsList', {
        params: { page, size, subOrderId }
    })
}

export const order = ({ page, size, subOrderId } = {}) => {
  return axios.get('order/subGoodsList', {
    params: { page, size, subOrderId }
  })
}

export const saveExpress = ({expressType, expressId, orderId} ={}) =>{
  return axios.post('order/saveExpress', {
    expressType, expressId, orderId
  })
}
