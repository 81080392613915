import axios from './base'

export const getRefundList = ({ page, size, orderSn }) => {
  return axios.get('refund/list', {
    params: { page, size, orderSn }
  })
}

export const confirmRefund = ({ id }) => {
  return axios.post('refund/confirm', { id })
}

export const rejectRefund = ({ id }) => {
  return axios.post('refund/reject', { id })
}

export const getRefundGoodsList = ({ page, size, orderSn }) => {
  return axios.get('refund/goodList', {
    params: { page, size, orderSn }
  })
}

export const confirmGoodsRefund = ({ id }) => {
  return axios.post('refund/confirmByGoods', { id })
}
