<template>
  <el-dialog
          title="新建分类"
          :visible.sync="dialogVisible"
          width="40%">
    <div class="page-catalog__add">
      <el-form :model="formData" ref="form" label-width="150px">
        <el-form-item label="分类ID" prop="catId">
          {{ formData.catId }}（系统自动生成）
          <el-button type="primary" size="small" @click="generateId">生成 ID</el-button>
        </el-form-item>
        <el-form-item label="分类名称" prop="name"
                      :rules="[{
            required: true,
            message: '不能为空'
          }]">
          <el-input style="width: 200px;" v-model="formData.name" placeholder="请输入分类名称"/>
        </el-form-item>
        <el-form-item label="父分类" v-if="!formData.id">
          <el-cascader
                  :props="props"
                  v-model="formData.parentId"
                  @change="changeParentId"
                  placeholder="请选择"
                  clearable/>
        </el-form-item>
        <el-form-item label="分类等级" v-if="!formData.id">
          {{ catClassMap[formData.catClass] }}
        </el-form-item>

        <el-form-item label="状态" prop="state">
          <el-switch
                  :active-value="1"
                  :inactive-value="0"
                  v-model="formData.state"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="save">保存</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
import {getCatalogList, addCatalog} from '@/api/catalog'

export default {
  name: 'add',
  data() {
    return {
      dialogVisible: false,
      formData: {
        catId: Number(Math.random().toString().slice(2, 2 + 5)),
        name: '',
        parentId: [],
        catClass: 0,
        state: 1
      },
      catClassMap: {
        0: '一级',
        1: '二级',
        2: '三级',
      },
      props: {
        checkStrictly: true,
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          // 第 3 层时直接返回空数组
          if (level === 2) {
            return resolve([])
          }
          // 第 0 层时 parentId 为 0
          const parentId = level === 0 ? 0 : value
          const {data} = await getCatalogList({parentId, size: 100})
          const result = data.map(item => ({
            label: item.name,
            value: item.catId,
            leaf: level >= 1
          }))
          resolve(result)
        }
      },
    }
  },
  methods: {
    open(row) {
      if (row && row.id) {
        this.formData = {...row};
      }
      this.dialogVisible = true
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return
      }
      try {
        const {
          id,
          name,
          catId,
          parentId,
          catClass,
          state,
          sortOrder,
          image
        } = this.formData;
        if (id) {
          await addCatalog({
            id,
            name,
            catId,
            catClass,
            state,
            parentId,
            sortOrder,
            image
          })
        } else {
          await addCatalog({
            name,
            catId,
            catClass,
            state,
            parentId: parentId[parentId.length - 1],
            sortOrder: 50,
            image: ''
          })
        }
        this.$message.success(id ? '修改成功' : '新增成功');
        this.close()
        this.$emit('refresh')
      } catch (e) {
        this.$message.error('新增失败：' + e)
      }
    },
    close() {
      this.dialogVisible = false
      this.$refs.form.resetFields()
    },
    generateId() {
      this.formData.catId = Number(Math.random().toString().slice(2, 2 + 5))
    },
    changeParentId(v) {
      console.log(v);
      this.formData.catClass = v.length
    }
  }
}
</script>

<style lang="scss" scoped>
.page-catalog__add {

}
</style>
