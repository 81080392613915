<template>
  <div class="page-org__list">
    <h2>供应商列表</h2>
    <el-button @click="openAddEditDialog" type="primary">新建供应商</el-button>
    <op-table
      size="small"
      :data="table.data"
      :cols="table.cols"
      :pagination="table.pagination"
    />
    <add-edit-dialog ref="addEditDialog" @refresh="loadTableData" />

  </div>

</template>

<script>
  import { orgListByPage } from '@/api/org'
  import AddEditDialog from '../addEditDialog/addEditDialog'

  export default {
    name: "orgList",
    components:{
      AddEditDialog
    },
    data() {
      return {
        table: {
          data: [],
          cols: [
            {
              type: 'expand',
              render: ({row}) => {
                console.log(row);
                return(
                  <el-form labelPosition="left" inline class="table-expand">
                    <el-form-item label="公司名称">
                    <span>{row.org_name}</span>
                    </el-form-item>
                    <el-form-item label="纳税识别号">
                    <span>{row.tax_id}</span>
                    </el-form-item>
                    <el-form-item label="头像">
                    <span><img class="avatar" src={row.avatar} /></span>
                    </el-form-item>
                    <el-form-item label="简介">
                    <span>{row.introduction}</span>
                    </el-form-item>
                    <el-form-item label="地址">
                    <span>{row.address}</span>
                    </el-form-item>
                    <el-form-item label="开户行">
                    <span>{row.bank}</span>
                    </el-form-item>
                    <el-form-item label="对公账号">
                    <span>{row.public_account}</span>
                    </el-form-item>
                    <el-form-item label="电话">
                    <span>{row.phone}</span>
                    </el-form-item>
                    <el-form-item label="联系人">
                    <span>{row.contact}</span>
                    </el-form-item>
                    <el-form-item label="联系人电话">
                    <span>{row.contact_phone}</span>
                    </el-form-item>
                    <el-form-item label="联系人邮箱">
                    <span>{row.contact_mail}</span>
                    </el-form-item>
                    <el-form-item label="创建时间">
                    <span>{row.create_time}</span>
                    </el-form-item>
                    <el-form-item label="更新时间">
                    <span>{row.update_time}</span>
                    </el-form-item>
                  </el-form>
                )
              },
            },
            {
              label: 'ID',
              prop: 'id',
              width: 50
            },
            {
              prop: 'org_name',
              label: '供应商名称'
            },
            {
              label: '联系人',
              prop: 'contact'
            },
            {
              label: '联系人电话',
              prop: 'contact_phone'
            },
            {
              label: '联系人邮件',
              prop: 'contact_mail'
            },
            {
              label: '操作',
              width: 350,
              render: ({row}) => {
                return (
                  <div class="operation">
                  <el-button
                type="primary"
                size="small"
                onClick={() =>
                this.editOrg(row)
              }
              >
                编辑
                </el-button>
                </div>
              );
              }
            }
          ],
          pagination: {
            total: 0,
            pageSize: 20,
            currentPage: 1,
            pageSizes: [20, 40, 60, 80],
            layout: 'total, sizes, prev, pager, next, jumper',
            on: {
              'current-change': (currentPage) => {
                this.table.pagination.currentPage = currentPage
                this.loadTableData()
              },
              'size-change': (size) => {
                this.table.pagination.pageSize = size
                this.loadTableData()
              }
            }
          }
        }
      }
    },
     mounted() {
       this.loadTableData()
    },
    methods: {
      async loadTableData() {
        const {currentPage: page, pageSize: size} = this.table.pagination
        const res = await orgListByPage({page, size})
        console.log('res',res)
        this.table.data = res.data
        this.table.pagination.total = res.count
      },
      openAddEditDialog() {
        this.$refs.addEditDialog.open();
      },
      editOrg(row) {
        this.$refs.addEditDialog.open(row);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-org__list {
    .demo-table-expand { display: flex;}
    .avatar{ width: 50px; height: 50px;}
  }
</style>
