import axios from 'axios'
import app from '../main'
import store from '../store'
import ErrorCodeEnum from '../enums/error_code'

const instance = axios.create({
  baseURL: '/admin'
  // baseURL: '/admin'
})

instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  // 设置 axios 请求头，后端将根据这个请求头判断用户的登录态
  if (store.state.user.token) {
    config.headers.common['x-jd-token'] = store.state.user.token
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  // Do something with response data
  const { data } = response
  // 如果返回 data 为 Blob 实例，则说明是文件流，直接返回 data
  if (data instanceof Blob) {
    return data;
  }
  // eslint-disable-next-line no-console
  if (data.errno === 401) {
    store.commit('RESET_TOKEN')
    store.commit('RESET_USERINFO')
    app.$message.error({
      message: '请重新登录！'
    })
    app.$router.push({ name: 'login' })
    return Promise.reject('请登录')
  }
  if (data.errno === 1000) {
    // app.$message.error({
    //   message: data.errmsg
    // })
    return Promise.reject(data.errmsg)
  }
  const errorCode = ErrorCodeEnum.parse(data.errno)
  if (errorCode.msg) {
    app.$message.error({
      message: errorCode.msg
    })
    // return Promise.reject(data.errmsg)
  }
  return data.data || {}
}, function (error) {
  // Do something with response error
  return Promise.reject(error)
})


export default instance
